import React, { Component } from 'react';
import axios from 'axios';
import { APIURL } from '../../config/config';
import Switch from 'react-switch';
import { AlertError, AlertSuccess } from '../Alert/Alert';
import Swal from 'sweetalert2';
import IncomeExpenseItemForm from './IncomeExpenseItemForm'

class IncomeExpenseItemList extends Component {
  constructor (props) {
    super(props);
    this.state = {
      income_expense_items:[],
      isIncomeExpenseModal: false,
      actionType: 'บันทึก',
      incomeExpenseSelected: null
    }
    this.loadIncomeExpenseItemData = this.loadIncomeExpenseItemData.bind(this);
    this.onChangeSwitch = this.onChangeSwitch.bind(this);
    this.onChangeSwitchExpense = this.onChangeSwitchExpense.bind(this);
    this.onChangeSwitchIncome = this.onChangeSwitchIncome.bind(this);
    this.onDeleteItem = this.onDeleteItem.bind(this);
    this.onEditItem = this.onEditItem.bind(this);
  }

  componentDidMount () {
    this.loadIncomeExpenseItemData()
  }

  loadIncomeExpenseItemData() {
    axios.get(APIURL + '/income_expense_item/manage/all')
      .then(res => {
        this.setState({income_expense_items: res.data})
      }).catch(error => {
      this.setState({income_expense_items: []})
    })
  }

  onChangeSwitch(checked,item){

    let item_update = item
    let income_expense_item_name = item.income_expense_item_name
    let income_expense_items =  this.state.income_expense_items

    let income_expense_item_index = income_expense_items.findIndex(data => data.income_expense_item_id=== item.income_expense_item_id)

    if(checked === false){
      item_update.status_field = 'hidden'
      item_update.is_income = 0
      item_update.is_expense = 0
    }else {
      item_update.status_field = 'show'
      item_update.is_income = 1
      item_update.is_expense = 1
    }

    // TODx: update car_model_status
    axios.post(APIURL + '/income_expense_item/status', item_update)
      .then(res => {
        let affectedRows = res.data.affectedRows
        if (affectedRows === 1) {
          if(checked === false){
            AlertError('ปิดการใช้งาน '+income_expense_item_name+' เรียบร้อยแล้ว')
          }else {
            AlertSuccess('เปิดการใช้งาน '+income_expense_item_name+' เรียบร้อยแล้ว')
          }
        }
      }).catch(error => {
    })

    income_expense_items[income_expense_item_index] = item_update

    this.setState({
      income_expense_items: income_expense_items
    })
  }

  onChangeSwitchExpense(checked,item){

    let item_update = item
    // let income_expense_item_name = item.income_expense_item_name
    let income_expense_items =  this.state.income_expense_items

    let income_expense_item_index = income_expense_items.findIndex(data => data.income_expense_item_id=== item.income_expense_item_id)

    if(checked === false){
      item_update.is_expense = 0
    }else {
      item_update.is_expense = 1
    }

    // TODx: update car_model_status
    axios.post(APIURL + '/income_expense_item/is_expense', item_update)
      .then(res => {
        /*let affectedRows = res.data.affectedRows
        if (affectedRows === 1) {
          if(checked === false){
            // AlertError('ปิดการใช้งาน '+income_expense_item_name+' เรียบร้อยแล้ว')
          }else {
            AlertSuccess('เปิดการใช้งาน '+income_expense_item_name+' เรียบร้อยแล้ว')
          }
        }*/
      }).catch(error => {
    })

    income_expense_items[income_expense_item_index] = item_update

    this.setState({
      income_expense_items: income_expense_items
    })
  }


  onChangeSwitchIncome(checked,item){

    let item_update = item
    // let income_expense_item_name = item.income_expense_item_name
    let income_expense_items =  this.state.income_expense_items

    let income_expense_item_index = income_expense_items.findIndex(data => data.income_expense_item_id=== item.income_expense_item_id)

    if(checked === false){
      item_update.is_income = 0
    }else {
      item_update.is_income = 1
    }

    // TODx: update car_model_status
    axios.post(APIURL + '/income_expense_item/is_income', item_update)
      .then(res => {
        /*let affectedRows = res.data.affectedRows
        if (affectedRows === 1) {
          if(checked === false){
            // AlertError('ปิดการใช้งาน '+income_expense_item_name+' เรียบร้อยแล้ว')
          }else {
            AlertSuccess('เปิดการใช้งาน '+income_expense_item_name+' เรียบร้อยแล้ว')
          }
        }*/
      }).catch(error => {
    })

    income_expense_items[income_expense_item_index] = item_update

    this.setState({
      income_expense_items: income_expense_items
    })
  }

  onDeleteItem(item) {
    let income_expense_item_id = item.income_expense_item_id
    let income_expense_item_name = item.income_expense_item_name

    Swal.fire({
      title: 'ยืนยันการลบ '+income_expense_item_name + ' ',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        axios.delete(APIURL + '/income_expense_item/'+income_expense_item_id)
          .then(res => {
            let affectedRows = res.data.affectedRows
            if (affectedRows === 1) {
              AlertSuccess('ลบ '+income_expense_item_name+' ข้อมูลเรียบร้อยแล้ว')
              this.loadIncomeExpenseItemData()
            }
          }).catch(error => {
        })
      }
    })
  }

  onEditItem(item) {
    this.setState({
      isIncomeExpenseModal: true,
      incomeExpenseSelected: item,
      actionType: 'แก้ไข'
    })
  }

  onAddItem() {
    this.setState({
      isIncomeExpenseModal: true,
      incomeExpenseSelected: null,
      actionType: 'บันทึก'
    })
  }

  render () {

    const {
      income_expense_items
    } = this.state

    let emptyList = <tr>
      <td colSpan={6} className="text-center"><span className="text-danger">ไม่พบข้อมูล</span></td>
    </tr>

    let itemList = income_expense_items.map((item, index) => {

      return (
        <tr key={index}>
          <td>{ index+1 }</td>
          <td>{ item.income_expense_item_name }</td>
          <td className="text-center" >

            { item.is_income === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'}

            <br/>

            <Switch height = {20} width ={48}
                    onChange={(checked) => this.onChangeSwitchIncome(checked, item)}
                    checked={item.is_income === 1}
            />

          </td>
          <td className="text-center" >
            { item.is_expense === 1 ? 'ใช้งาน' : 'ไม่ใช้งาน'}

            <br/>

            <Switch height = {20} width ={48}
                    onChange={(checked) => this.onChangeSwitchExpense(checked, item)}
                    checked={item.is_expense === 1}
            />

          </td>
          <td className="text-center" >
            { item.status_field === 'show' ? 'ใช้งาน' : 'ไม่ใช้งาน' }

            <br/>

            <Switch height = {20} width ={48}
                    onChange={(checked) => this.onChangeSwitch(checked, item)}
                    checked={item.status_field === 'show'}
            />

          </td>
          <td className="text-center" style={{width: 100}}  >

            <button className="btn btn-sm btn-warning btn-md-width mr-2 mb-2"
                    style={{width: 100}}
                    onClick={() => this.onEditItem(item)}
            >
              <i className="fa fa-edit"/> แก้ไข
            </button>

            <button className="btn btn-sm btn-danger btn-md-width mr-2 mb-2"
                    style={{width: 100}}
                    onClick={() => this.onDeleteItem(item)}
            >
              <i className="fa fa-remove"/> ลบ
            </button>

          </td>
        </tr>
      )
    })

    let income_expense_item_list = income_expense_items.length === 0 ? emptyList : itemList

    return (
      <>
        <IncomeExpenseItemForm
          isOpenModal = {this.state.isIncomeExpenseModal}
          actionType={this.state.actionType}
          toggleModal={() => {
            this.setState({
              isIncomeExpenseModal: !this.state.isIncomeExpenseModal
            })
          }}
          incomeExpenseSelected={this.state.incomeExpenseSelected}
          onReload={() => {
            this.setState({
              actionType: 'บันทึก',
              incomeExpenseSelected: null
            })
            this.loadIncomeExpenseItemData()
          }}
        />
      <div className="card">
          <div className="card-header">
            <strong className="text-title">หมวดรายรับ/ค่าใช้จ่าย</strong>

            <button
              className="btn btn-head-bar float-right"
              style={styles.buttonHeight}
              type="button"
              onClick={() => {
                this.onAddItem()
              }}
            >
              <i className="icon-plus"/>&nbsp; เพิ่มรายการ
            </button>

          </div>
          <div className="card-block">
            <table className="table table-striped table-responsive">
              <thead>
              <tr>
                <th className="text-left">#</th>
                <th className="text-left text-nowrap">ชื่อรายการ</th>
                <th className="text-center text-nowrap">ใช้เป็นรายรับ</th>
                <th className="text-center text-nowrap">ใช้เป็นรายจ่าย</th>
                <th className="text-center" >สถานะ</th>
                <th className="text-center" style={{width: 100}}>จัดการ</th>
              </tr>
              </thead>
              <tbody>
              {
                income_expense_item_list
              }
              </tbody>
            </table>
          </div>

      </div>
      </>
    )
  }
}

const styles = {
  buttonHeight: {
    height: 36,
    paddingTop: 0,
    paddingBottom:0,
    borderRadius:5,
    display: 'inline',
    marginTop: 0,
    marginBottom: 0,
  },
}

export default IncomeExpenseItemList
