import React, { Component } from 'react';
import moment from 'moment';
//import BigCalendar from 'react-big-calendar';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import axios from 'axios';
import pdfMake from 'pdfmake/build/pdfmake';
import { APIURL} from '../../../config/config';
import {TabContent, TabPane, Nav, NavItem, NavLink, ModalBody, Modal} from 'reactstrap';
import classnames from 'classnames';
import Button from "reactstrap/es/Button";
import AppointmentForm from "../AppointmentForm/AppointmentForm";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import DatePicker from 'react-datepicker'

// const localizer = BigCalendar.momentLocalizer(moment)

/*BigCalendar.setLocalizer(
    BigCalendar.globalizeLocalizer(globalize)
);*/
const localizer = momentLocalizer(moment)
require ("react-big-calendar/lib/css/react-big-calendar.css");


class CalendarList extends Component{
    constructor (props){
        super(props);
        this.state ={
            data:[],
            calendarCustomer: [],
            birth:[],
            car: '',
            carTax: [],
            carInsurance: [],
            activeTab: '2',
            user: {},
            user_type: '',
            date_start: new Date(),
            date_end: new Date(),
            width: 0,
            height: 0
        }
        this.loadData = this.loadData.bind(this);
        this.loadCustomerCalendar = this.loadCustomerCalendar.bind(this);
        this.toggleAppointment = this.toggleAppointment.bind(this);
        this.onReload = this.onReload.bind(this);
        //
        this.printTax = this.printTax.bind(this);
        this.printInsurance = this.printInsurance.bind(this);
        this.loadCarTax = this.loadCarTax.bind(this);
        this.loadCarInsurance = this.loadCarInsurance.bind(this);
        this.handleDateStart = this.handleDateStart.bind(this);
        this.handleDateEnd   = this.handleDateEnd.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    componentDidMount() {
        this.loadData()
        this.loadCustomerCalendar()

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillMount () {
        let user = JSON.parse(this.props.user);
        this.setState({
            user: user ,
            user_type : user.type
        })
    }

    loadData () {
        axios.get(APIURL + '/calendar_car_license/forplan/')
            .then(res => {
                let data = res.data.filter(d => d.title !== null)
                this.setState({data: data})
            }).catch(error => {
            this.setState({data: []})
        })
    }

    loadCarTax () {

        if(this.state.date_start > this.state.date_end){
            alert('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
            return
        }
        let date_start =  moment(this.state.date_start).format('YYYY-MM-DD')
        let date_end   =  moment(this.state.date_end).format('YYYY-MM-DD')
        const data = {
            date_start,
            date_end
        }

        axios.post(APIURL + '/calendar_car_license/tax', data)
          .then(res => {
              if( res.data.length > 0) {
                  this.setState({carTax: res.data}, () => this.printTax())
              } else {
                  alert('ไม่พบข้อมูลการต่อภาษี ' + date_start + ' ถึง ' + date_end)
              }
          }).catch(error => {
            this.setState({carTax: []})
        })
    }

    loadCarInsurance () {

        if(this.state.date_start > this.state.date_end){
            alert('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
            return
        }

        let date_start =  moment(this.state.date_start).format('YYYY-MM-DD')
        let date_end   =  moment(this.state.date_end).format('YYYY-MM-DD')
        const data = {
            date_start,
            date_end
        }

        axios.post(APIURL + '/calendar_car_license/insurance', data)
          .then(res => {
              if( res.data.length > 0) {
                  this.setState({ carInsurance: res.data }, () => this.printInsurance())
              } else {
                  alert('ไม่พบข้อมูลการต่อประกัยภัยรถ ' + date_start + ' ถึง ' + date_end)
              }
          }).catch(error => {
            this.setState({carInsurance: []})
        })
    }

    handleDateStart(date){
        this.setState({
            date_start: date
        })
    }

    handleDateEnd(date){
        this.setState({
            date_end: date
        })
    }

    loadCustomerCalendar() {
        axios.post(APIURL + '/calendar_customer', {})
          .then(res => {
              this.setState({calendarCustomer:res.data})
          }).catch(error => {
            this.setState({data: []})
        })
    }

    onReload(){
        this.loadCustomerCalendar();
    }

    eventStyleGetter(event, start, end, isSelected) {
        let backgroundColor = '#' + event.hex_color;
        let style = {
            backgroundColor: backgroundColor,
            borderRadius: '5px',
            opacity: 0.8,
            color: 'white',
            border: '0px',
            display: 'block',
        };
        return {
            style: style
        };
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    toggleAppointment(){
        this.setState({
            toggle_appointment_add: !this.state.toggle_appointment_add
        })
        this.loadData()
    }

    printTax() {
        var carTaxRows = []
        var row = []
        var lenTax = this.state.carTax.length
        this.state.carTax.forEach((car, index) => {
            var carTitle = {
                text: (index +1) + " : " + car.title,
                style: 'bodyText'
            };

            if (index % 2 === 0) {
                // 0
                row = []
                row.push(carTitle)
                if(lenTax === (index + 1)) {
                    row.push({
                        text: '-',
                        style: 'bodyText'
                    })
                    carTaxRows.push(row)
                }
            } else {
                // 1
                row.push(carTitle)
                carTaxRows.push(row)
            }
        })

        /* const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
        const endOfMonth   = moment().clone().endOf('month').format('YYYY-MM-DD hh:mm');
        var futureMonth = moment(endOfMonth).add(2, 'M');
        var futureMonthEnd = moment(futureMonth).endOf('month').format('YYYY-MM-DD'); */

        let date_start =  moment(this.state.date_start).format('YYYY-MM-DD')
        let date_end   =  moment(this.state.date_end).format('YYYY-MM-DD')

        var headerText = 'แจ้งเตือนการต่อภาษีรถยนต์ 3 เดือน : ' + date_start + ' ถึง ' + date_end
        var widthTable = ['auto', 'auto']
        var docDefinition = {
            pageOrientation: 'portrait',
            pageSize: 'A4',
            pageMargins: [45, 30, 45, 30], // margin: [left, top, right, bottom]
            content: [
                {text: headerText, style: 'bodyHead', lineHeight: 1.3},
                {
                    table: {
                        widths: widthTable,
                        body: carTaxRows
                    }
                },
            ],
            styles: {
                bodyHead: {
                    fontSize: 16,
                    bold: true,
                    alignment: 'center'
                },
                bodyText: {
                    fontSize: 14,
                },
                bodyBold: {
                    fontSize: 14,
                    bold: true,
                    alignment: 'center'
                },
                textCenter: {
                    fontSize: 14,
                    alignment: 'center'
                },
            },
            defaultStyle: {
                font: 'THSarabunNew'
            }
        };

        pdfMake.createPdf(docDefinition).open()
    }

    printInsurance() {
        var carInsuranceRows = []
        var row = []
        var lenTax = this.state.carInsurance.length
        this.state.carInsurance.forEach((car, index) => {
            var carTitle = {
                text: (index +1) + " : " + car.title,
                style: 'bodyText'
            };

            if (index % 2 === 0) {
                // 0
                row = []
                row.push(carTitle)
                if(lenTax === (index + 1)) {
                    row.push({
                        text: '-',
                        style: 'bodyText'
                    })
                    carInsuranceRows.push(row)
                }
            } else {
                // 1
                row.push(carTitle)
                carInsuranceRows.push(row)
            }
        })

        /*const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
        const endOfMonth   = moment().clone().endOf('month').format('YYYY-MM-DD hh:mm');
        var futureMonth = moment(endOfMonth).add(2, 'M');
        var futureMonthEnd = moment(futureMonth).endOf('month').format('YYYY-MM-DD');*/

        let date_start =  moment(this.state.date_start).format('YYYY-MM-DD')
        let date_end   =  moment(this.state.date_end).format('YYYY-MM-DD')

        var headerText = 'แจ้งเตือนการต่อประกันภัยรถยนต์ จาก ' + date_start + ' ถึง ' + date_end
        var widthTable = ['auto', 'auto']
        var docDefinition = {
            pageOrientation: 'portrait',
            pageSize: 'A4',
            pageMargins: [45, 30, 45, 30], // margin: [left, top, right, bottom]
            content: [
                {text: headerText, style: 'bodyHead', lineHeight: 1.3},
                {
                    table: {
                        widths: widthTable,
                        body: carInsuranceRows
                    }
                },
            ],
            styles: {
                bodyHead: {
                    fontSize: 16,
                    bold: true,
                    alignment: 'center'
                },
                bodyText: {
                    fontSize: 14,
                },
                bodyBold: {
                    fontSize: 14,
                    bold: true,
                    alignment: 'center'
                },
                textCenter: {
                    fontSize: 14,
                    alignment: 'center'
                },
            },
            defaultStyle: {
                font: 'THSarabunNew'
            }
        };

        pdfMake.createPdf(docDefinition).open()
    }

    render(){

        let user = this.state.user.user

        let width = this.state.width

        return (
          <>
              <div className="mb-4">
                  <Nav tabs>

                      <NavItem>
                          <NavLink
                            className={classnames({ active: this.state.activeTab === '2' })}
                            onClick={() => { this.toggle('2'); }}
                          >
                              <i className="icon-calendar"></i> ปฏิทินลูกค้า & ลูกหนี้
                          </NavLink>
                      </NavItem>

                      <NavItem>
                          <NavLink
                            className={classnames({ active: this.state.activeTab === '1' })}
                            onClick={() => { this.toggle('1'); }}
                          >
                              <i className="icon-calendar"></i> ปฏิทินรถ ภาษี & ประกัน
                          </NavLink>
                      </NavItem>

                  </Nav>

                  <TabContent activeTab={this.state.activeTab}>
                      <TabPane tabId="1">
                          <div className="row">
                              <div className="col-lg-12">
                                  <div className="card">
                                      <div className="card-block">
                                          <div className="row">
                                              <div className="col-sm-12 col-md-12">
                                                  <div className="card card-accent-info">
                                                      <div className="card-header">
                                                          <div className="row">
                                                              <div className="col-12">
                                                                  <strong className="text-title"> ภาษี & ประกัน & ฟลอร์แพลน </strong>
                                                              </div>
                                                          </div>
                                                          <div className="row">

                                                              <div className="col-7 mt-4">
                                                                  <div className="row">
                                                                      <div className="ml-5 mt-1 text-title">
                                                                          จาก
                                                                      </div>
                                                                      <div className="col-10 text-right">
                                                                          <div className="row ">
                                                                              <div className="col-5">
                                                                                  <DatePicker
                                                                                    selected={this.state.date_start}
                                                                                    onChange={this.handleDateStart}
                                                                                    dateFormat="yyyy-MM-dd"
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder=""
                                                                                    style={{zIndex: 99999}}
                                                                                    popperProps={{
                                                                                        positionFixed: true
                                                                                    }}
                                                                                  />
                                                                              </div>

                                                                              <div className="col-5 text-right">
                                                                                  <DatePicker
                                                                                    selected={this.state.date_end}
                                                                                    onChange={this.handleDateEnd}
                                                                                    dateFormat="yyyy-MM-dd"
                                                                                    type="text"
                                                                                    className="form-control"
                                                                                    placeholder=""
                                                                                  />
                                                                              </div>
                                                                          </div>
                                                                      </div>
                                                                  </div>
                                                              </div>

                                                              <div className="col-5 mt-4">
                                                                  <button className="btn btn-success float-right"
                                                                          onClick={() => this.loadCarTax()}
                                                                  >
                                                                      <i className="icon-printer"/>&nbsp; พิมพ์ต่อภาษี
                                                                  </button>

                                                                  <button
                                                                    className="btn btn-info float-right mr-3"
                                                                    onClick={() => this.loadCarInsurance()}
                                                                  >
                                                                      <i className="icon-printer"/>&nbsp; พิมพ์ประกันภัยรถ
                                                                  </button>
                                                              </div>

                                                          </div>
                                                      </div>

                                                      <div className="card-block">
                                                          {/*<BigCalendar
                                                            localizer={localizer}
                                                            selectable
                                                            style={{height: '960px' , backgroundColor:'#ffffff'}}
                                                            events={this.state.data}
                                                            defaultView='month'
                                                            defaultDate={new Date()}
                                                            onSelectEvent={event => alert(event.title)}
                                                            eventPropGetter={(this.eventStyleGetter)}
                                                          />*/}
                                                          <Calendar
                                                              localizer={localizer}
                                                              selectable
                                                              style={{height: '960px' , backgroundColor:'#ffffff'}}
                                                              events={this.state.data}
                                                              defaultView='month'
                                                              defaultDate={new Date()}
                                                              onSelectEvent={event => alert(event.title)}
                                                              eventPropGetter={(this.eventStyleGetter)}
                                                          />

                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </TabPane>
                      <TabPane tabId="2">
                          <div className="row">
                              <div className="col-lg-12">
                                  <div className="card">
                                      <div className="card-block">
                                          <div className="row">
                                              <div className="col-sm-12 col-md-12">
                                                  <div className="card card-accent-success">
                                                      <div className="card-header card-customer">
                                                          <strong className="text-title"> ปฏิทินลูกค้า & ลูกหนี้ </strong>

                                                          <Link to="/calendar/manage" >
                                                              <button className="btn btn-danger float-right mt-1">
                                                                  จัดการปฏิทินนัดหมาย
                                                              </button>
                                                          </Link>

                                                          <Button className="btn btn-info float-right mt-1 mr-3" onClick={this.toggleAppointment}>
                                                              เพิ่มนัดหมายลูกค้า
                                                          </Button>

                                                      </div>

                                                      <Modal isOpen={this.state.toggle_appointment_add}
                                                             toggle={this.toggleAppointment}
                                                             className={'modal-lg'}
                                                             style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
                                                      >
                                                          <ModalBody>
                                                              <AppointmentForm
                                                                 onToggle={this.toggleAppointment}
                                                                 user = {user}
                                                                 onReload={this.onReload}
                                                              />
                                                          </ModalBody>
                                                      </Modal>


                                                      <div className="card-block">
                                                          {/*<BigCalendar
                                                            localizer={localizer}
                                                            selectable
                                                            style={{height: '960px' , backgroundColor:'#ffffff'}}
                                                            events={this.state.calendarCustomer}
                                                            defaultView='month'
                                                            defaultDate={new Date()}
                                                            onSelectEvent={event => alert(event.title)}
                                                            eventPropGetter={(this.eventStyleGetter)}
                                                      />*/}

                                                          <Calendar
                                                              localizer={localizer}
                                                              selectable
                                                              style={{height: '960px' , backgroundColor:'#ffffff'}}
                                                              events={this.state.calendarCustomer}
                                                              defaultView='month'
                                                              defaultDate={new Date()}
                                                              onSelectEvent={event => alert(event.title)}
                                                              eventPropGetter={(this.eventStyleGetter)}
                                                          />

                                                      </div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </TabPane>
                  </TabContent>

              </div>


          </>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CalendarList)
