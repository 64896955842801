import React, {Component} from 'react'
import NumberFormat from 'react-number-format';
import {connect} from 'react-redux' ;

const port = window.env.PORT;

class CarEditSellPrice extends Component{
  constructor(props) {
    super(props);

    this.state = {
      car_name: '',
      car_license_plate_new: '',
      car_sale_price: 0,
      car_cost_tax: 0,
      car_cost_act: 0,
      vat_buy: 0,
      save: false,
      withholding : 0
    }

    this.onCarEditPriceSubmit = this.onCarEditPriceSubmit.bind(this)

  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    });
  }

  componentDidMount(){
    let car = this.props.car ;
    let vat_buy = car.vat_buy === undefined ? 0 : (car.vat_buy === '' ? 0 : car.vat_buy)
    let withholding = car.withholding === undefined ? 0 : (car.withholding === '' ? 0 : car.withholding)
    this.setState({
      car_name: car.car_name,
      car_license_plate_new: car.car_license_plate_new,
      car_sale_price: car.car_sale_price,
      car_cost_tax: car.car_cost_tax,
      car_cost_act: car.car_cost_act,
      vat_buy: vat_buy,
      withholding: withholding,
    });
  }

  onCarEditPriceSubmit(event) {
    event.preventDefault()

    let car_id = this.props.car.car_id
    let car_sale_price = this.state.car_sale_price ;
    let car_cost_tax  = this.state.car_cost_tax ;
    let car_cost_act  = this.state.car_cost_act;
    let vat_buy  = this.state.vat_buy;

    let withholding  = this.state.withholding;

    let car_cost = this.props.car.car_cost;
    let car_cost_recipe_other = this.props.car.car_cost_recipe_other;
    let car_cost_repair = this.props.car.car_cost_repair;
    let car_cost_commissions = this.props.car.car_cost_commissions
    let car_cost_expenses_other = this.props.car.car_cost_expenses_other


    if(car_cost==='') {
      car_cost = 0
    }

    if(car_cost_repair==='')
      car_cost_repair = 0

    if(car_cost_tax==='')
      car_cost_tax = 0

    if(car_cost_act==='')
      car_cost_act = 0

    if(car_cost_commissions==='')
      car_cost_commissions = 0

    if(car_cost_expenses_other==='')
      car_cost_expenses_other = 0

    if(car_cost_recipe_other==='')
      car_cost_recipe_other = 0

    if(vat_buy === '')
      vat_buy = 0

    if(withholding === '')
      withholding = 0


    var car_net_profit = 0 ;
    if(car_sale_price !== '' && car_sale_price > 0)
      car_net_profit = Number(car_sale_price) -  Number(car_cost) - Number(car_cost_repair) - Number(car_cost_tax) - Number(car_cost_act) - Number(car_cost_commissions) - Number(car_cost_expenses_other) - Number(vat_buy) + Number(car_cost_recipe_other);


    let SellPrice = {
      car_id: car_id,
      car_sale_price: car_sale_price,
      car_cost_act: car_cost_act,
      car_cost_tax: car_cost_tax,
      vat_buy: vat_buy,
      car_net_profit: car_net_profit,
      username: this.state.user.user,
      //
      car_cost : car_cost,
      car_cost_repair : car_cost_repair,
      car_cost_commissions : car_cost_commissions,
      car_cost_expenses_other : car_cost_expenses_other,
      car_cost_recipe_other : car_cost_recipe_other,

      withholding : withholding
      //
    }
    // console.log(SellPrice); return ;
    this.setState({
      save: true
    })

    this.props.onSubmit(event, SellPrice)
  }

  handleOnChangeNoSet() {

  }


  render(){
    return(
      <form action="" method="post" onSubmit={this.onCarEditPriceSubmit}>
        <div className="row" >
          <div className="col-sm-12">
            <div className="card card-header-warning">
              <div className="card-header ">
                <strong>แก้ไขราคาขาย</strong>
              </div>

              <div className="card-block">
                <div className="row">
                  <div className="col-sm-12">
                    <div className="row">

                      <div className="form-group col-sm-12">
                        <h6> {this.state.car_name} : {this.state.car_license_plate_new} </h6>
                      </div>
                      <div className="form-group col-sm-6">
                        <label htmlFor="sale-price">ราคาขาย</label>
                        <input type="text"
                               className="form-control hidden"
                               name="car_sale_price"
                               placeholder=""
                               ref="car_sale_price"
                               value={this.state.car_sale_price}
                               onChange={this.handleOnChangeNoSet}
                        />
                        <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          prefix={'฿'}
                          placeholder="ราคาขาย"
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.car_sale_price}
                          onValueChange = {(values) => {
                            const {value} = values
                            this.setState({car_sale_price: value},()=>{
                              //this.handleOnChange()
                            })

                          }}
                        />
                      </div>


                      <div  className={port == '9005' ? "hidden" : "form-group col-sm-6"} >
                        <label htmlFor="cost-act">ค่าใช้จ่าย พ.ร.บ.</label>
                        <input
                          type="text"
                          className="form-control hidden"
                          name="car_cost_act"
                          placeholder=""
                          ref="car_cost_act"
                          value={this.state.car_cost_act}
                          onChange={this.handleOnChangeNoSet}
                        />
                        <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          prefix={'฿'}
                          placeholder="ค่าใช้จ่าย พ.ร.บ."
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.car_cost_act}
                          onValueChange = {(values) => {
                            const {value} = values;
                            this.setState({car_cost_act: value},()=>{
                              //this.handleOnChange()
                            })
                          }}
                        />
                      </div>


                      <div className={port == '9005' ? "hidden" : "form-group col-sm-6"} >
                        <label htmlFor="cost-tax">ค่าภาษีรถยนต์</label>
                        <input
                          type="text"
                          className="form-control hidden"
                          name="car_cost_tax"
                          placeholder=""
                          ref="car_cost_tax"
                          value={this.state.car_cost_tax}
                          onChange={this.handleOnChangeNoSet}
                        />
                        <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          prefix={'฿'}
                          placeholder="ค่าภาษีรถยนต์"
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.car_cost_tax}
                          onValueChange = {(values) => {
                            const {value} = values;
                            this.setState({car_cost_tax: value},()=>{
                              //this.handleOnChange()
                            })

                          }}
                        />
                      </div>


                      <div className={port === '9005' ? "hidden" : "form-group col-sm-6"}>
                        <label htmlFor="cost-tax">ภาษีซื้อ (Vat ซื้อ)</label>
                        <input
                          type="text"
                          className="form-control hidden"
                          name="vat_buy"
                          placeholder=""
                          ref="vat_buy"
                          value={this.state.vat_buy}
                          onChange={this.handleOnChangeNoSet}
                        />
                        <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          prefix={'฿'}
                          placeholder="ภาษีซื้อ(Vat ซื้อ)"
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          value={this.state.vat_buy}
                          onValueChange = {(values) => {
                            const {value} = values;
                            this.setState({vat_buy: value},()=>{
                              //this.handleOnChange()
                            })

                          }}
                        />
                      </div>

                      <div className={port === '9005' ? "hidden" : "form-group col-sm-6"}>
                        <label htmlFor="cost-tax">ภาษีหัก ณ ที่จ่าย (ซื้อเข้า) *ยอดจะไม่นำไปคำนวณ</label>
                        <input
                            type="text"
                            className="form-control hidden"
                            name="withholding"
                            placeholder=""
                            ref="withholding"
                            value={this.state.withholding}
                            onChange={this.handleOnChangeNoSet}
                        />
                        <NumberFormat
                            className="form-control"
                            thousandSeparator={true}
                            prefix={'฿'}
                            placeholder="ภาษีหัก ณ ที่จ่าย"
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            value={this.state.withholding}
                            onValueChange = {(values) => {
                              const {value} = values;
                              this.setState({withholding: value},()=>{
                                //this.handleOnChange()
                              })

                            }}
                        />
                      </div>


                    </div>
                  </div>
                </div>
              </div>

              <div className="card-footer text-right">

                <button type="reset"
                        className="btn btn-sm btn-danger"
                        onClick={this.props.onHandle.cancel}
                >
                  <i className="fa fa-refresh"/> ยกเลิก
                </button>
                &nbsp;
                <button
                  disabled={this.state.save}
                  type="submit"
                  className="btn btn-sm btn-warning">
                  <i className="fa fa-save"/>
                  {this.state.save ? ' กำลังบันทึก' : ' แก้ไข'}

                </button>
              </div>

            </div>
          </div>
        </div>
      </form>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user
})
export default connect(mapStateToProps)(CarEditSellPrice);