import React, { Component } from 'react'
import Loading from '../Loading'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import axios from 'axios'
import { APIURL } from '../../config/config'
import { connect } from 'react-redux'
import { save } from 'save-file';


class TradingReport extends Component {

  constructor (props) {
    super(props);
    this.state = {
      isLoading: false,
      date_start: new Date(),
      date_end: new Date(),

      sum_real_sell_price: 0,
      sum_car_cost: 0,
      sum_income_other: 0,
      sum_expense_total: 0,
      sum_gross_profit: 0,
      lists: [],
    }
    this.handleDateStart = this.handleDateStart.bind(this)
    this.handleDateEnd = this.handleDateEnd.bind(this)
    this.loadSellData = this.loadSellData.bind(this)
  }

  handleDateStart(date){
    this.setState({
      date_start: date
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

  loadSellData() {
    // const {date_start, date_end} = this.state

    if(this.state.date_start > this.state.date_end){
      alert('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    // const data = {
    //   start_date,
    //   end_date
    // } buy_car_from
    this.setState({
      isLoading: true
    })

    axios.get(APIURL + '/report/trading/'+start_date+'/'+end_date)
      .then(res => {

        let sum_real_sell_price = 0
        let sum_car_cost = 0
        let sum_income_other = 0
        let sum_expense_total = 0
        let sum_gross_profit = 0

        if(res.data.length > 0) {

          sum_real_sell_price = res.data.reduce((acc, next) => acc + next.real_sell_price, 0)
          sum_car_cost = res.data.reduce((acc, next) => acc + next.car_cost, 0)
          sum_income_other = res.data.reduce((acc, next) => acc + Number(next.vat_sell) + Number(next.finance_commission) + Number(next.vat_commission), 0)
          sum_expense_total = res.data.reduce((acc, next) => {
            // return acc + next.expense_total;
            let expense_total = Number(next.car_cost_commissions) + Number(next.car_cost_repair) + Number(next.car_cost_tax) + Number(next.car_cost_act) + Number(next.car_cost_expenses_other) + Number(next.car_external_commission) + Number(next.sales_commission) + Number(next.additional_commission) + Number(next.advertising_cost) + Number(next.delivery_fee) + Number(next.promotion_fee)+Number(next.finance_fee)+Number(next.transfer_fee)
            return acc + expense_total
          }, 0)
          sum_gross_profit = res.data.reduce((acc, car) => {
            // return acc + next.gross_profit
            const car_cost = car.car_cost // ต้นทุนซื้อเข้า
            const car_cost_commissions = car.car_cost_commissions // คอมฯซื้อเข้า
            const car_cost_repair = car.car_cost_repair
            const car_cost_other = car.car_cost_other
            const car_cost_expenses_other = car.car_cost_expenses_other+car_cost_other
            const car_cost_tax = car.car_cost_tax
            const car_cost_act = car.car_cost_act
            const profit_before_sell = car.real_sell_price - (car_cost + car_cost_commissions + car_cost_repair+car_cost_expenses_other+car_cost_tax+car_cost_act)
            const profit_after_sell = (car.vat_sell + car.finance_commission + car.vat_commission) - (car.car_external_commission + car.sales_commission + car.additional_commission + car.advertising_cost + car.delivery_fee + car.promotion_fee + car.finance_fee + car.transfer_fee)
            const gross_profit = profit_before_sell + profit_after_sell
            return acc + gross_profit
          }, 0)

        }

        this.setState({
          lists: res.data,
          sum_real_sell_price: sum_real_sell_price,
          sum_car_cost: sum_car_cost,
          sum_income_other: sum_income_other,
          sum_expense_total: sum_expense_total,
          sum_gross_profit: sum_gross_profit,
          isLoading: false
        })
      }).catch(error => {
        console.log('error:', error)
       // this.setState({lists: []})
    })
  }

  exportSellData() {

    if(this.state.date_start > this.state.date_end){
      alert('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let start_date =  moment(this.state.date_start).format('YYYY-MM-DD')
    let end_date   =  moment(this.state.date_end).format('YYYY-MM-DD')

    this.setState({
      isLoading: false
    })

    axios.get(APIURL + '/report/tradingexport/'+start_date+'/'+end_date)
      .then( async res => {
        const excelBuffer = res.data.excelBuffer
        const currentDatetime = res.data.currentDatetime
        const fileName = 'รายงานการซื้อขาย_export_'+currentDatetime+'.xlsx'
        this.setState({
          isLoading: false
        })
        await save(excelBuffer, fileName)

      }).catch(error => {
      console.log('error:', error)
      // this.setState({lists: []})
    })
  }

  render () {
    const {
      lists,
      sum_real_sell_price,
      sum_car_cost,
      sum_income_other,
      sum_expense_total,
      sum_gross_profit
    } = this.state

    const emptyBody = <tr><td colSpan={14} className="text-center text-danger" > ไม่พบข้อมูล </td></tr>

    const listRows = lists.map((car, index) => {
      const car_sell_date = moment(car.car_sell_date).locale('th').format('LL')
      const car_date_in = moment(car.car_date_in).locale('th').format('LL')
      const buy_car_from = car.buy_car_from
      const customer_fullname = car.customer_name + ' ' + car.customer_lastname
      const car_sell_type_name = car.car_sell_type_name
      const bank_name = car.bank_name
      const car_cost = car.car_cost // ต้นทุนซื้อเข้า
      const vat_sell = car.vat_sell // vat ยอดจัดได้คืน(2)
      const finance_commission = car.finance_commission // ค่าคอมมิชชั่นไฟแนนท์(3)
      const vat_commission = car.vat_commission // vat คอมมิชชั่น
      const income_other = Number(vat_sell) + Number(finance_commission) + Number(vat_commission)
      // รวมค่าใช้จ่าย/ค่าซ่อม
      let expense_total = Number(car.car_cost_commissions) + Number(car.car_cost_repair) + Number(car.car_cost_tax) + Number(car.car_cost_act) + Number(car.car_cost_expenses_other) + Number(car.car_external_commission) + Number(car.sales_commission) + Number(car.additional_commission) + Number(car.advertising_cost) + Number(car.delivery_fee) + Number(car.promotion_fee)+Number(car.finance_fee)+Number(car.transfer_fee)
      const real_sell_price = car.real_sell_price
      // customer_name customer_lastname
      // const car_cost = car.car_cost
      const car_cost_commissions = car.car_cost_commissions // คอมฯซื้อเข้า
      const car_cost_repair = car.car_cost_repair

      // ค่าภาษีรถยนต์
      const car_cost_tax = car.car_cost_tax
      // ค่าใช้จ่าย พ.ร.บ./ค่าภาษีรถยนต์
      const car_cost_act = car.car_cost_act

      const car_cost_other = car.car_cost_other
      const car_cost_expenses_other = car.car_cost_expenses_other+car_cost_other
      const profit_before_sell = car.real_sell_price - (car_cost + car_cost_commissions + car_cost_repair+car_cost_expenses_other+car_cost_tax+car_cost_act)
      const profit_after_sell = (car.vat_sell + car.finance_commission + car.vat_commission) - (car.car_external_commission + car.sales_commission + car.additional_commission + car.advertising_cost + car.delivery_fee + car.finance_fee + car.promotion_fee + car.transfer_fee)
      const gross_profit = profit_before_sell + profit_after_sell

      return (
        <tr key={index} style={index%2===0? { backgroundColor: ''} : { backgroundColor: '#e4e5e6'}}>
          <td className="text-center">{index+1}</td>
          <td style={{width: 220}}>{car.car_name}</td>
          <td style={{width: 120}} className="text-center">{car.car_license_plate_new}</td>
          <td style={{width: 120}} >{car_date_in}</td>
          <td style={{width: 120}} >{buy_car_from}</td>
          <td style={{width: 120}} >{car_sell_date}</td>
          <td style={{width: 120}} >{customer_fullname}</td>
          <td style={{width: 120}} >{car_sell_type_name}</td>
          <td style={{width: 120}} >{bank_name}</td>
          <td style={{width: 120}} align="right" >{real_sell_price.toLocaleString()}</td>
          <td style={{width: 120}} align="right" >{car_cost.toLocaleString()}</td>
          <td style={{width: 120}} align="right" >{income_other.toLocaleString()}</td>
          <td style={{width: 120}} align="right" >{expense_total.toLocaleString()}</td>
          <td style={{width: 120}} align="right" >{gross_profit.toLocaleString()}</td>
          <td style={{width: 120}} >{car.employee_name}</td>
          {/*<td style={{width: 60}} className="text-center">{car.employee_name}</td>*/}
          {/*<td className="text-right">{car.normal_sell_price === null ? '-' : car.normal_sell_price.toLocaleString()}</td>*/}
          {/*<td className="text-right">{car.normal_sell_price === null ? '-' : car.normal_sell_price.toLocaleString()}</td>*/}

        </tr>
      )
    })

    const tableRows = lists.length === 0 ? emptyBody : listRows

    const tableSum = <tr>
                  <td className="text-right font-weight-bold" colSpan={9}>รวม</td>
                  <td className="text-right font-weight-bold">{sum_real_sell_price.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_car_cost.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_income_other.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_expense_total.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{sum_gross_profit.toLocaleString()}</td>
                  <td className="text-right font-weight-bold">{''}</td>
                </tr>

    return (
      <div>
        <Loading isLoading={this.state.isLoading} />
        <div className="card-block" style={styles.overflowY}>
          <div className="row">
              <div className="card card-accent-primary">
                <div className="card-header">
                  <strong className="text-title">รายงานการซื้อขาย</strong>
                </div>
                <div className="card-block">

                  <div className="row mt-1">
                    <div className="col-1 mt-1 text-right">
                      จาก
                    </div>

                    <div className="col-2">
                      <DatePicker
                        selected={this.state.date_start}
                        onChange={this.handleDateStart}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                    </div>

                    <div className="mt-1 text-right">
                      ถึง
                    </div>

                    <div className="col-2 ml-3">
                      <DatePicker
                        selected={this.state.date_end}
                        onChange={this.handleDateEnd}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control d-block"
                        placeholder=""
                      />
                    </div>

                    <div className="d-flex mr-3">
                      <button type="button" className="btn btn-sm btn-primary" onClick={() => this.loadSellData()} > ตกลง </button>
                    </div>

                    <div className="d-flex">
                      <button type="button" className="btn btn-sm btn-success" onClick={() => this.exportSellData()} > Excel </button>
                    </div>

                    <div className="col-4 text-right pt-2">
                      {/*<h5>
                        รวมยอดขาย&nbsp;
                        <NumberFormat
                          displayType={'text'}
                          thousandSeparator={true}
                          value={this.state.sum_expense}/>
                        &nbsp;บาท
                      </h5>*/}
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-12">
                      <table className="" border={1} style={{border: 1, minHeight: 250}} >
                        <thead>
                          <tr>
                            <th  style={styles.headerTable} >ลำดับ</th>
                            <th style={{...styles.headerTable,...{width: 220}}}  >ชื่อรถ</th>
                            <th style={{...styles.headerTable,...{width: 120}}} >ทะเบียนรถ</th>
                            <th style={{...styles.headerTable,...{width: 120}}} >วันที่ซื้อรถเข้า</th>
                            <th style={{...styles.headerTable,...{width: 120}}} >ซื้อรถจาก</th>
                            <th style={{...styles.headerTable,...{width: 120}}} >วันที่ขาย</th>
                            <th style={{...styles.headerTable,...{width: 120}}} >ลูกค้าผู้ซื้อรถ</th>
                            <th style={{...styles.headerTable,...{width: 120}}} >ประเภทการขาย</th>
                            <th style={{...styles.headerTable,...{width: 120}}} >ไฟแนนซ์</th>
                            <th style={{...styles.headerTable,...{width: 120}}} >ราคาขายจริง</th>
                            <th style={{...styles.headerTable,...{width: 120}}} >ต้นทุนซื้อเข้า</th>
                            <th style={{...styles.headerTable,...{width: 120}}} >รวมรายรับอื่นๆ</th>
                            <th style={{...styles.headerTable,...{width: 120}}} >รวมค่าใช้จ่าย/ค่าซ่อม</th>
                            <th style={{...styles.headerTable,...{width: 120}}} >กำไรเบื้องต้น</th>
                            <th style={{...styles.headerTable,...{width: 120}}} >ขายโดย</th>
                          </tr>
                        </thead>
                        <tbody>
                         {tableRows}
                         {tableSum}
                        </tbody>
                      </table>
                    </div>
                  </div>

                </div>
              </div>

          </div>
        </div>

      </div>
    )
  }
}


const styles = {
  headerTable: {
    backgroundColor: '#eeede8',
    verticalAlign: 'middle',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowReceive: {
    backgroundColor: '#bfcad0',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowExpense: {
    backgroundColor: '#ede4e3',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  rowProfit: {
    backgroundColor: '#95E06C',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight:'semi-bold'
  },
  overflowY: {
    overflow: 'scroll',
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

// export default SellReport;
export default connect(mapStateToProps)(TradingReport);
