import React, {Component} from 'react';
// import {NavLink} from 'react-router-dom'
import {connect} from 'react-redux'
import SidebarRole from './SidebarRole'

class Sidebar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: {},
            user_type: '',
        };
    }

    handleClick(e) {
        e.preventDefault();
        e.target.parentElement.classList.toggle('open');
    }

    activeRoute(routeName) {
        return this.props.location.pathname.indexOf(routeName) > -1 ? 'nav-item nav-dropdown open' : 'nav-item nav-dropdown';
    }

    componentWillMount() {
        //const queryString = require('query-string');
      let user = JSON.parse(this.props.user);
      //console.log(user)
      if(user!==null){
        this.setState({
          user: user,
          user_type: user.type
        }, () => {
          //console.log(this.state.user_type)
        })
      }
    }

    componentDidMount(){

    }

    /*componentWillReceiveProps (prevProps, prevState, snapshot) {
      // console.log(prevProps.type, this.props.type)
      if (this.props.type !==undefined) {
        if (this.props.type !== this.state.user_type) {
          // console.log(this.props.type, this.state.user_type)
          this.setState({
            user_type: this.props.type
          })
        }
      }
    }*/

  // secondLevelActive(routeName) {
    //   return this.props.location.pathname.indexOf(routeName) > -1 ? "nav nav-second-level collapse in" : "nav nav-second-level collapse";
    // }

    render() {
        // console.log('this.props.type=', this.props.type)
        return (
            <div className="sidebar">
                <nav className="sidebar-nav">
                  <SidebarRole
                    user_type={this.props.type === undefined ? this.state.user_type : this.props.type}
                    user={this.state.user.user}
                  />
                </nav>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    type: state.auth.type,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(Sidebar)
