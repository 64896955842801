import React, { Component } from 'react';
import axios from 'axios'
import NumberFormat from 'react-number-format';
import { APIURL } from '../../../config/config'
import {connect} from 'react-redux' ;

class CarEditPrice extends Component {
  constructor(props) {
    super(props);

    this.state = {
      car_cost: 0,
      car_sale_price: 0,
      car_cost_repair: 0,
      car_cost_tax: 0,
      car_cost_act: 0,
      car_cost_commissions: 0,
      car_cost_expenses_other: 0,
      car_cost_recipe_other: 0,
      car_net_profit: 0,
    }

    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleOnChangeNoSet = this.handleOnChangeNoSet.bind(this)
    this.onCarEditPriceSubmit = this.onCarEditPriceSubmit.bind(this)
  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user)
    });
  }

  componentDidMount(){
    var car = this.props.car ;
    //console.log("=====================CarEditPrice")
    //console.log(car)
    //alert(car.car_sale_price)
    this.setState({
      car_sale_price: car.car_sale_price,
      car_cost: car.car_cost,
      car_cost_repair: car.car_cost_repair,
      car_cost_tax: car.car_cost_tax,
      car_cost_act: car.car_cost_act,
      car_cost_commissions: car.car_cost_commissions,
      car_cost_expenses_other: car.car_cost_expenses_other,
      car_cost_recipe_other: car.car_cost_recipe_other
    });
  }

  handleOnChange(){

    var car_sale_price = this.state.car_sale_price ;
    var car_cost  = this.state.car_cost ;
    var car_cost_repair  = this.state.car_cost_repair ;
    var car_cost_tax  = this.state.car_cost_tax ;
    var car_cost_act  = this.state.car_cost_act;
    var car_cost_commissions  = this.state.car_cost_commissions;
    var car_cost_expenses_other  = this.state.car_cost_expenses_other ;
    var car_cost_recipe_other  = this.state.car_cost_recipe_other;

    if(car_cost==='')
      car_cost = 0

    if(car_cost_repair==='')
      car_cost_repair = 0

    if(car_cost_tax==='')
      car_cost_tax = 0

    if(car_cost_act==='')
      car_cost_act = 0

    if(car_cost_commissions==='')
      car_cost_commissions = 0

    if(car_cost_expenses_other==='')
      car_cost_expenses_other = 0

    if(car_cost_recipe_other==='')
      car_cost_recipe_other = 0


    var profite = 0 ;
    if(car_sale_price !== '' && car_sale_price > 0)
      profite = Number(car_sale_price) -  Number(car_cost) - Number(car_cost_repair) - Number(car_cost_tax) - Number(car_cost_act) - Number(car_cost_commissions) - Number(car_cost_expenses_other) + Number(car_cost_recipe_other);


    this.setState({
      car_net_profit: profite
    });
  }

  handleOnChangeNoSet() {

  }

  onCarEditPriceSubmit(event){
    event.preventDefault()

    var car_id = this.props.car.car_id
    var car_sale_price = this.state.car_sale_price ;
    var car_cost  = this.state.car_cost ;
    var car_cost_repair  = this.state.car_cost_repair ;
    var car_cost_tax  = this.state.car_cost_tax ;
    var car_cost_act  = this.state.car_cost_act;
    var car_cost_commissions  = this.state.car_cost_commissions;
    var car_cost_expenses_other  = this.state.car_cost_expenses_other ;
    var car_cost_recipe_other  = this.state.car_cost_recipe_other;
    var car_net_profit = this.state.car_net_profit;
    //alert("car_id="+car_id)
    axios.put(APIURL + '/car/car_price/'+car_id, {
      car_sale_price : car_sale_price,
      car_cost_tax : car_cost_tax,
      car_cost_act : car_cost_act,
      //
      car_cost : car_cost,
      car_cost_repair : car_cost_repair,
      car_cost_commissions : car_cost_commissions,
      car_cost_expenses_other : car_cost_expenses_other,
      car_cost_recipe_other : car_cost_recipe_other,
      //
      username: this.state.user.user,
      car_net_profit: car_net_profit
    }).then(res=>{

      if(res.status === 200){
         this.props.onLoadCarById();
         this.props.onToggle();
      }

    })
  }

  render(){
    return(
      <form action="" method="post" onSubmit={this.onCarEditPriceSubmit}>
        <div className="row" >
      <div className="col-sm-12">
        <div className="card card-header-warning">
          <div className="card-header card-edit">
             <strong className="text-title">แก้ไขราคา/ค่าใช้จ่าย</strong>
          </div>

           <div className="card-block">
            <div className="row">
              <div className="col-sm-12">
                <div className="row">

                  <div className="form-group col-sm-6">
                    <label htmlFor="cost">ราคาซื้อ</label>
                    <input type="text"
                           className="form-control hidden "
                           name="car_cost"
                           placeholder=""
                           ref="car_cost"
                           value={this.state.car_cost}
                           onChange={this.handleOnChangeNoSet}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      placeholder="ราคาซื้อ"
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.car_cost}
                      onValueChange = {(values) => {
                        const {value} = values;
                        this.setState({car_cost: value},()=>{
                          this.handleOnChange();
                        })

                      }}
                    />
                  </div>

                  <div className="form-group col-sm-6">
                    <label htmlFor="sale-price">ราคาขาย</label>
                    <input type="text"
                           className="form-control hidden"
                           name="car_sale_price"
                           placeholder=""
                           ref="car_sale_price"
                           value={this.state.car_sale_price}
                           onChange={this.handleOnChangeNoSet}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      placeholder="ราคาขาย"
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.car_sale_price}
                      onValueChange = {(values) => {
                        const {value} = values
                        this.setState({car_sale_price: value},()=>{
                          this.handleOnChange()
                        })

                      }}
                    />
                  </div>

                  {/*<div className="form-group col-sm-6">
                    <label htmlFor="cost-repair">ค่าซ่อมบำรุง</label>
                    <input
                      type="text"
                      className="form-control hidden"
                      name="car_cost_repair"
                      placeholder=""
                      ref="car_cost_repair"
                      value={this.state.car_cost_repair}
                      onChange={this.handleOnChangeNoSet}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      placeholder="ค่าซ่อมบำรุง"
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.car_cost_repair}
                      onValueChange = {(values) => {
                        const {value} = values;
                        this.setState({car_cost_repair: value},()=>{
                          this.handleOnChange()
                        })
                      }}
                    />
                  </div>*/}


                  <div className="form-group col-sm-6">
                    <label htmlFor="cost-act">ค่าใช้จ่าย พ.ร.บ. </label>
                    <input
                      type="text"
                      className="form-control hidden"
                      name="car_cost_act"
                      placeholder=""
                      ref="car_cost_act"
                      value={this.state.car_cost_act}
                      onChange={this.handleOnChangeNoSet}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      placeholder="ค่าใช้จ่าย พ.ร.บ."
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.car_cost_act}
                      onValueChange = {(values) => {
                        const {value} = values;
                        this.setState({car_cost_act: value},()=>{
                          this.handleOnChange()
                        })
                      }}
                    />
                  </div>

                  <div className="form-group col-sm-6">
                    <label htmlFor="cost-tax">ค่าภาษีรถยนต์</label>
                    <input
                      type="text"
                      className="form-control hidden"
                      name="car_cost_tax"
                      placeholder=""
                      ref="car_cost_tax"
                      value={this.state.car_cost_tax}
                      onChange={this.handleOnChangeNoSet}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      placeholder="ค่าภาษีรถยนต์"
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.car_cost_tax}
                      onValueChange = {(values) => {
                        const {value} = values;
                        this.setState({car_cost_tax: value},()=>{
                          this.handleOnChange()
                        })

                      }}
                    />
                  </div>



                  <div className="form-group col-sm-6">
                    <label htmlFor="cost-commissions">ค่าคอมมิชชั่น/ค่าแนะนำซื้อรถเข้า</label>
                    <input
                      type="text"
                      className="form-control hidden"
                      name="car_cost_commissions"
                      placeholder=""
                      ref="car_cost_commissions"
                      value={this.state.car_cost_commissions}
                      onChange={this.handleOnChangeNoSet}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      placeholder="ค่าคอมมิชชั่น"
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.car_cost_commissions}
                      onValueChange = {(values) => {
                        const {value} = values;
                        this.setState({car_cost_commissions: value},()=>{
                          this.handleOnChange()
                        })
                      }}
                    />
                  </div>

                  {/*<div className="form-group col-sm-6">
                    <label htmlFor="cost-expenses-other">ค่าใช้จ่ายอื่นๆ</label>
                    <input
                      type="text"
                      className="form-control hidden"
                      name="car_cost_expenses_other"
                      placeholder=""
                      ref="car_cost_expenses_other"
                      value={this.state.car_cost_expenses_other}
                      onChange={this.handleOnChangeNoSet}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      placeholder="ค่าใช้จ่ายอื่นๆ"
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.car_cost_expenses_other}
                      onValueChange = {(values) => {
                        const {value} = values;
                        this.setState({car_cost_expenses_other: value},()=>{
                          this.handleOnChange()
                        })
                      }}
                    />
                  </div>*/}

                 {/* <div className="form-group col-sm-6">
                    <label htmlFor="cost-recipe-other">รายรับอื่นๆจากการขาย</label>
                    <input
                      type="text"
                      className="form-control hidden"
                      name="car_cost_recipe_other"
                      placeholder=""
                      ref="car_cost_recipe_other"
                      value={this.state.car_cost_recipe_other}
                      onChange={this.handleOnChangeNoSet}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      placeholder="รายรับอื่นๆจากการขาย"
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.car_cost_recipe_other}
                      onValueChange = {(values) => {
                        const {value} = values;
                        this.setState({car_cost_recipe_other: value},()=>{
                          this.handleOnChange()
                        })
                      }}
                    />
                  </div>*/}

                  <div className="form-group has-warning col-sm-12">
                    <label htmlFor="net-profit">กำไรสุทธิ</label>
                    <input type="text"
                           className="form-control hidden"
                           name="car_net_profit"
                           placeholder="กำไรสุทธิ"
                           ref="car_net_profit"
                           value={this.state.car_net_profit}
                           onChange={this.handleOnChangeNoSet}
                    />
                    <NumberFormat
                      className="form-control"
                      thousandSeparator={true}
                      prefix={'฿'}
                      placeholder="กำไรสุทธิ"
                      min={0}
                      max={1000000000}
                      step={1000}
                      size={10}
                      allowNegative={false}
                      value={this.state.car_net_profit}
                      displayType="text"
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>


          <div className="card-footer text-right">
            <button type="reset"
                    className="btn btn-sm btn-danger mr-2"
                    onClick={this.props.onToggle}
            >
              <i className="icon-close"/> ยกเลิก
            </button>
            <button type="submit"
                    className="btn btn-sm btn-primary">
              <i className="fa fa-save"/> บันทึก
            </button>
          </div>

        </div>
      </div>
        </div>
      </form>
    )
  }


}

const mapStateToProps = (state) => ({
  user: state.auth.user
})
export default connect(mapStateToProps)(CarEditPrice);
