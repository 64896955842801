import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import 'moment/locale/th';
import MaskedInput from "react-maskedinput";
moment.locale('th');

export  default class SalesClaimAdd extends Component {
  constructor (props) {
    super(props);
    //this.handleOnSubmitCustomer.bind(this);
    this.state = {
      dateClaim: new Date(),
      user: JSON.parse(this.props.user),
      car_sell: this.props.car_sell,
      //
      claim1_list1: '1.\n2.\n3.',
      claim1_list2: '4.\n5.\n6.',
      claim2: '',
      claim3: '',
      claim_name: new Date(),
      claim_mobile: '',
    };
    this.handleDateClaim = this.handleDateClaim.bind(this);
    this.handleDateClaimName = this.handleDateClaimName.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if(this.props.form_status === 'edit') {
      this.setEditToForm();
    }
  }

  setEditToForm() {
    // console.log(this.props.claim_edit)
    this.setState({
      claim1_list1: this.props.claim_edit.claim1_list1,
      claim1_list2: this.props.claim_edit.claim1_list2,
      claim2: this.props.claim_edit.claim2,
      claim3: this.props.claim_edit.claim3,
      claim_name: new Date(this.props.claim_edit.claim_name),
      dateClaim: new Date(this.props.claim_edit.claim_date),
      claim_mobile: this.props.claim_edit.claim_mobile
    });
  }

  handleDateClaim(date) {
    this.setState({
      dateClaim: date
    });
  }

  handleDateClaimName(date) {
    this.setState({
      claim_name: date
    });
  }

  handleChange(event) {
    var targetName = event.target.name;
    var value = event.target.value;
    var line = '';
    // console.log(targetName);
    // console.log(value);
    if(targetName === 'claim1_list1'){
      line = value.split('\n')
      // console.log('line='+line.length)
      if(line.length>3){
        this.setState({
          line_error: true
        });
        return;
      }
      this.setState({
        claim1_list1: value,
        line_error: false
      });
    }else if(targetName === 'claim1_list2'){
      line = value.split('\n')
      if(line.length>3){
        this.setState({
          line_error: true,
        });
        return;
      }
      this.setState({
        claim1_list2: value,
        line_error: false
      });
    }else if(targetName === 'claim2'){
      this.setState({
        claim2: value
      });
    }else if(targetName === 'claim3'){
      this.setState({
        claim3: value
      });
    }
    /*else if(targetName === 'claim_name'){
      this.setState({
        claim_name: value
      });
    }*/
    else if(targetName === 'claim_mobile'){
      this.setState({
        claim_mobile: value
      });
    }

  }

  render(){
    return (
      <div className="card card-accent-primary">
        <form action="" method="post" onSubmit={this.props.onSubmit}>
          <div className="card-header">
            <strong className="text-title">
              {this.props.form_status==='add'? 'บันทึก ปล่อยรถ/การเคลม' : 'แก้ไข ปล่อยรถ/การเคลม'}
              </strong>
            <div className="float-right text-title">พนักงานขาย: {this.state.user.fullname}</div>
          </div>
          <div className="card-block">
            <div className="row">
              <div className="form-group col-sm-6">
                <label htmlFor="date_claim">วันที่ออกใบงาน</label>
                <DatePicker
                  selected={this.state.dateClaim}
                  onChange={this.handleDateClaim}
                  dateFormat="yyyy-MM-dd"
                  type="text"
                  className="form-control"
                  id="claim_date"
                  name="claim_date"
                  placeholder="วันที่ออกใบงาน"
                />
              </div>

              <div className="form-group col-sm-6">
                <label htmlFor="claim_name">วันที่ลูกค้ารับรถ</label>
                {/*<input
                  className="form-control"
                  id="claim_name"
                  name="claim_name"
                  placeholder="ลูกค้ารับรถ"
                  value={this.state.claim_name}
                  onChange={this.handleChange}
                />*/}
                <DatePicker
                  selected={this.state.claim_name}
                  onChange={this.handleDateClaimName}
                  dateFormat="yyyy-MM-dd"
                  type="text"
                  className="form-control"
                  id="claim_name"
                  name="claim_name"
                  placeholder="วันที่ลูกค้ารับรถ"
                />
              </div>

              <div className="form-group col-6">
                <label htmlFor="claim_mobile">เบอร์โทรศัพท์</label>

                <input
                  className="form-control hidden"
                  id="claim_mobile_old-bk"
                  name="claim_mobile_old-bk"
                  placeholder="เบอร์โทร"
                  // value={this.state.claim_mobile}
                  // onChange={this.handleChange}
                />

                <MaskedInput
                    mask="111-111-1111"
                    className="form-control"
                    id="claim_mobile"
                    name="claim_mobile"
                    placeholder="เบอร์โทร"
                    value={this.state.claim_mobile}
                    onChange={this.handleChange}
                />
              </div>
            </div>

            <div className="row">
              <div className="form-group col-sm-6">
                <label htmlFor="car_model_name">
                  รถยี่ห้อ: {this.state.car_sell.car_brand_name}</label>
              </div>
              <div className="form-group col-sm-6">
                <label htmlFor="car_model_name">
                  รุ่น: {this.state.car_sell.car_model_name}
                </label>
              </div>
              <div className="form-group col-sm-6">
                <label htmlFor="car_model_name">
                  สี: {this.state.car_sell.car_color_name}
                </label>
              </div>
              <div className="form-group col-sm-6">
                <label htmlFor="car_model_name">ทะเบียน: {this.state.car_sell.car_license_plate_new}</label>
              </div>
            </div>

            <div className="row">
              <div className="form-group col-sm-6">
                <label htmlFor="claim_mobile">รายการ</label>
                <textarea
                  id="claim1_list1"
                  name="claim1_list1"
                  className="form-control"
                  value={this.state.claim1_list1}
                  onChange={this.handleChange}
                  rows="8"
                  />
              </div>
              <div className="form-group col-sm-6">
                <label htmlFor="claim_mobile">&nbsp;</label>
                <textarea
                  id="claim1_list2"
                  name="claim1_list2"
                  className="form-control"
                  value={this.state.claim1_list2}
                  onChange={this.handleChange}
                  rows="8"
                  />
              </div>
            </div>

            <div className="row hidden">
              <div className="form-group col-sm-12">
                <label htmlFor="claim_mobile">เคลมที่ 2</label>
                <textarea
                  id="claim2"
                  name="claim2"
                  className="form-control hidden"
                  value={this.state.claim2}
                  onChange={this.handleChange}
                  />
              </div>
            </div>

            <div className="row hidden">
              <div className="form-group col-sm-12">
                <label htmlFor="claim_mobile">เคลมที่ 3</label>
                <textarea
                  id="claim3"
                  name="claim3"
                  className="form-control hidden"
                  value={this.state.claim3}
                  onChange={this.handleChange}
                  />
              </div>
            </div>

          </div>

          <div className={this.state.line_error? "row" : "hidden"}>
            <div className="form-group col-sm-12">
              <label className="text-danger">**พิมพ์ได้ไม่เกิน 3 บรรทัดต่อช่อง </label>
            </div>
          </div>

          <div className="card-footer text-right">
            <button type="reset"
                    onClick={this.props.onToggle}
                    className="btn btn-sm btn-danger mr-2">
              <i className="fa fa-refresh"/> ยกเลิก
            </button>
            <button type="submit"
                    className={this.props.form_status==='add'? "btn btn-sm btn-primary" :  "btn btn-sm btn-warning" }>
              <i className="fa fa-save"/>&nbsp;
              {this.props.form_status==='add'? 'บันทึก' : 'แก้ไข'}
            </button>
            <input
              type="hidden"
              id="status"
              name="status"
              value={this.props.claim_status}
            />
          </div>

        </form>
      </div>
    );
  }
}