import React, {Component} from 'react';
import classnames from 'classnames';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from 'reactstrap';
import SalesClaimAdd from '../SalesClaimAdd/SalesClaimAdd';
import { connect } from 'react-redux';
import axios from 'axios/index';
import * as moment from 'moment';
import {APIURL, IMAGE_FULL_URL, IMAGE_THUMB_URL} from '../../../config/config';
// import { pdfImageCarCheck } from '../../../config/pdfImage';
// import ThaiBaht from 'thai-baht-text'
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.fonts = {
  THSarabunNew: {
    normal: 'THSarabunNew-Regular.ttf',
    bold: 'THSarabunNew-Bold.ttf',
    italics: 'THSarabunNew-Italic.ttf',
    bolditalics: 'THSarabunNew-BoldItalic.ttf'
  },
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  }
}

class SalesClaim extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
      car_sell_id: '',
      form_status: '',
      claim_edit: {},
      car_claim: {},
      car_sell: {},
      car_claim_list: [],
      modal: false,
      modalDelete: false,
      user: JSON.parse(this.props.user),
      claim_status: '',
      width: 0,
      height: 0,
      carBase64Img: '',
    };

    this.toggleModal= this.toggleModal.bind(this);
    this.salesClaimAdd= this.salesClaimAdd.bind(this);
    this.openClaimAdd= this.openClaimAdd.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.loadCarImageBase64 = this.loadCarImageBase64.bind(this);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentDidMount () {
    const queryString = require('query-string');
    let car_claim = queryString.parse(this.props.location.search);
    // console.log(this.props.user);
    this.setState({
      car_claim: car_claim,
      car_sell_id: car_claim.car_sell_id
    }, () => {
      this.loadCarSellById(); // for addForm, pdf
      this.loadCarClaim();
    });

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  toggle (tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      })
    }
  }

  toggleModal() {
    this.setState({
      modal: !this.state.modal
    });
  }

  openClaimAdd() {
    /* start : ออกใบเคลม
    process : กำลังดำเินการซ่อม
    success : ซ่อมเสร็จรอลูกค้ามารับ
    complete : ลูกค้ามารับเรียบร้อย */
    this.setState({
      claim_status: 'start',
      form_status: 'add'
    },()=>{
      this.toggleModal();
    });
  }

  openClaimEdit(claim) {
    /* start : ออกใบเคลม
    process : กำลังดำเินการซ่อม
    success : ซ่อมเสร็จรอลูกค้ามารับ
    complete : ลูกค้ามารับเรียบร้อย */
    this.setState({
      claim_status: 'start',
      form_status: 'edit',
      claim_edit: claim
    },()=>{
      this.toggleModal();
    });
  }

  openClaimDelete(claim) {
    this.setState({
      form_status: 'delete',
      claim_edit: claim
    },()=>{
      this.toggleModalDelete();
    });
  }

  toggleModalDelete() {
    this.setState({
      modalDelete: !this.state.modalDelete
    });
  }

  convertClaimStatusName(statusValue) {
    var statusList = [
      {status: 'start', name: 'ออกใบเคลม'},
      {status: 'process', name: 'กำลังดำเินการซ่อม'},
      {status: 'success', name: 'ซ่อมเสร็จรอลูกค้ามารับ'},
      {status: 'complete', name: 'ปิดเคลม'},
    ];
    var result = statusList.find(status => {
      return status.status === statusValue
    })
    return result.name;
  }

  loadCarSellById () {
    // alert (this.state.car_sell_id)
    axios.get(APIURL + '/car_sell/' + this.state.car_sell_id)
      .then(res => {
        //console.log('================loadCarSellById')
        console.log(res)
        this.setState({car_sell: res.data[0]})
      }).catch(error => {
      //alert('error' + error)
      console.log (error)
      this.setState({car_sell: {}})
    })
  }

  salesClaimAdd (event) {
    event.preventDefault();
    // var that = this;
    var claim_date = event.target.claim_date.value;
    var claim_name = event.target.claim_name.value;
    var claim_mobile = event.target.claim_mobile.value;
    var claim1_list1 = event.target.claim1_list1.value;
    var claim1_list2 = event.target.claim1_list2.value;
    var claim2 = event.target.claim2.value;
    var claim3 = event.target.claim3.value;
    var car_id = this.state.car_sell.car_id;
    var user = this.state.user.user;

    const data = {
      claim_date: claim_date,
      claim_name: claim_name,
      claim_mobile: claim_mobile,
      claim1_list1: claim1_list1,
      claim1_list2: claim1_list2,
      claim2: claim2,
      claim3: claim3,
      car_id: car_id,
      user: user
    };

    if(this.state.form_status === 'add') {
      axios.post(APIURL + '/car_claim', data)
        .then((res) => {
          // console.log(res);
          if (res.status === 200) {
            this.toggleModal();
            this.loadCarClaim();
          }
        })
        .catch((err) => {
          console.log(err);
      });
    }else if(this.state.form_status === 'edit'){
      var car_claim_id = this.state.claim_edit.car_claim_id
      axios.put(APIURL + '/car_claim/'+car_claim_id, data)
        .then((res) => {
          // console.log(res)
          if (res.status === 200) {
            this.setState({
              form_status: 'add',
            }, () => {
              this.toggleModal();
              this.loadCarClaim();
            });
          }

        }).catch((err) => {
        console.log(err)
      })
    }
  }

  carClaimDelete(claim_id) {
    // console.log('claim_id='+claim_id);
    if(this.state.form_status === 'delete'){
      axios.delete(APIURL + '/car_claim/' + claim_id).then(res => {
        if (res.status === 200) {
          this.loadCarClaim()
          this.setState({
            form_status: 'add',
            modalDelete: !this.state.modalDelete
          });
        }
      })
    }
  }

  loadCarClaim() {
    axios.get(APIURL + '/car_claim/car/' + this.state.car_claim.car_id)
      .then(res => {
        // console.log('================loadCarClaim')
        // console.log(res.data)
        this.setState({car_claim_list: res.data})
      }).catch(error => {
      //alert('error' + error)
      console.log (error)
      this.setState({car_claim_list: []})
    })
  }

  convertImgToBase64URL(url, callback, outputFormat){
    var img = new Image();
    img.crossOrigin = 'Anonymous';
    img.onload = function(){
      var canvas = document.createElement('CANVAS'),
        ctx = canvas.getContext('2d'), dataURL;
      canvas.height = img.height;
      canvas.width = img.width;
      ctx.drawImage(img, 0, 0);
      dataURL = canvas.toDataURL(outputFormat);
      callback(dataURL);
      canvas = null;
    };
    img.src = url;
  }

  async loadCarImageBase64(car_upload_id) {
    let uri =  APIURL + '/car/image/' + car_upload_id;
    await axios.get(uri)
        .then(res => {
          let body = res.data.body
          // console.log(res.data)
          this.setState({ carBase64Img: body })
        }).catch(error => {
          console.log('error:', error)
        })
  }


  printClaim(claimData, carData) {
    // var img_car_claim_id = 'img_'+claimData.car_claim_id;
    var claim_date = moment(claimData.claim_date).locale('th').format('LL');
    var claim_name = moment(claimData.claim_name).locale('th').format('LL'); //claimData.claim_name;
    var claim_mobile = claimData.claim_mobile;
    var claim1_list1 = claimData.claim1_list1;
    var claim1_list2 = claimData.claim1_list2;
    var car_brand_name = carData.car_brand_name
    var car_model_name = carData.car_model_name
    var car_color_name = carData.car_color_name
    var car_license_plate_new = carData.car_license_plate_new
    var car_upload_filename = carData.car_upload_filename
    var car_url = IMAGE_THUMB_URL+car_upload_filename

    let base64Img = this.state.carBase64Img

    //this.convertImgToBase64URL(car_url, function(base64Img){
      // Base64DataURL
    var docDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [ 56, 30, 56, 30 ], // margin: [left, top, right, bottom]
      content: [
        { text: 'ออกใบเคลม', style: 'bodyhead', lineHeight:1.3},
        {
          columns: [
            { width: '20%',
              text: '', style: 'bodycompany'
            },
            { width: '40%', style: 'bodycompany',
              text: '',
            },
            { width: '20%',
              text: '', style: 'bodycompany'
            },
            { width: '20%', style: 'body',
              text: 'Sale........................',
            },

          ],
          columnGap: 20,
          lineHeight:1
        },
        {
          columns: [
            {
              width: 150,
              image: base64Img
            },
            { width: '80%',
              text: 'วันที่ออกใบงาน...'+ claim_date+ '  \nลูกค้ารับรถ...'+claim_name+'   \nเบอร์โทร...' + claim_mobile+'...', style: 'body'
            },
          ],
          columnGap: 20,
          lineHeight:1.3,
          margin: [ 0, 8, 0, 0 ], // margin: [left, top, right, bottom]
        },
        {
          columns: [
            { width: '100%',
              text: 'รถยี่ห้อ...'+ car_brand_name+'  รุ่น...'+car_model_name+ '   สี...'+ car_color_name +'  ทะเบียน...'+car_license_plate_new, style: 'textcenter'
            },
          ],
          columnGap: 20,
          lineHeight: 1.3,
          margin: [ 0, 8, 0, 0 ],
        },
        {
          columns: [
            { width: '100%', text: 'รายการ:', style: 'body' },
          ],
          columnGap: 20,
          margin: [ 0, 8, 0, 0 ], // margin: [left, top, right, bottom]
          lineHeight: 1.5
        },
        {
          columns: [
            { width: '50%', text: claim1_list1 , style: 'body'},
            { width: '50%', text: claim1_list2 , style: 'body'},
          ],
          columnGap: 20,
          margin: [ 8, 0, 0, 0 ], // margin: [left, top, right, bottom]
          lineHeight: 1.3,
        },
        {
          columns: [
            { width: '100%', text: '', style: 'body' },
          ],
          columnGap: 20,
          lineHeight: 1.3
        },
        {
          columns: [
            {
              width: '100%',
              text: 'อนุมัติ....................................   ช่าง:ตรวจ....................................   Sale:ตรวจ....................................   ',
              style: 'textcenter'
            },
          ],
          columnGap: 20,
          lineHeight: 1.5,
          margin: [ 0, 12, 0, 0 ],
        },
        //repeat
        [
          { text: 'ออกใบเคลม', style: 'bodyhead', lineHeight:1.3},
          {
            columns: [
              { width: '20%',
                text: '', style: 'bodycompany'
              },
              { width: '40%', style: 'bodycompany',
                text: '',
              },
              { width: '20%',
                text: '', style: 'bodycompany'
              },
              { width: '20%', style: 'body',
                text: 'Sale........................',
              },

            ],
            columnGap: 20,
            lineHeight:1
          },
          {
            columns: [
              {
                width: 150,
                image: base64Img
              },
              { width: '80%',
                text: 'วันที่ออกใบงาน...'+ claim_date+ '  \nลูกค้ารับรถ...'+claim_name+'   \nเบอร์โทร...' + claim_mobile+'...', style: 'body'
              },
            ],
            columnGap: 20,
            lineHeight:1.3,
            margin: [ 0, 8, 0, 0 ], // margin: [left, top, right, bottom]
          },
          {
            columns: [
              { width: '100%',
                text: 'รถยี่ห้อ...'+ car_brand_name+'  รุ่น...'+car_model_name+ '   สี...'+ car_color_name +'  ทะเบียน...'+car_license_plate_new, style: 'textcenter'
              },
            ],
            columnGap: 20,
            lineHeight: 1.3,
            margin: [ 0, 8, 0, 0 ],
          },
          {
            columns: [
              { width: '100%', text: 'รายการ:', style: 'body' },
            ],
            columnGap: 20,
            margin: [ 0, 8, 0, 0 ], // margin: [left, top, right, bottom]
            lineHeight: 1.5
          },
          {
            columns: [
              { width: '50%', text: claim1_list1 , style: 'body'},
              { width: '50%', text: claim1_list2 , style: 'body'},
            ],
            columnGap: 20,
            margin: [ 8, 0, 0, 0 ], // margin: [left, top, right, bottom]
            lineHeight: 1.3,
          },
          {
            columns: [
              { width: '100%', text: '', style: 'body' },
            ],
            columnGap: 20,
            lineHeight: 1.3
          },
          {
            columns: [
              {
                width: '100%',
                text: 'อนุมัติ....................................   ช่าง:ตรวจ....................................   Sale:ตรวจ....................................   ',
                style: 'textcenter'
              },
            ],
            columnGap: 20,
            lineHeight: 1.5,
            margin: [ 0, 12, 0, 0 ],
          },
        ]
      ],
      // images: {
      //  carCheck:
      // },
      styles: {
        bodyhead: {
          fontSize: 18,
          bold: true,
          alignment: 'center'
        },
        body: {
          fontSize: 16,
        },
        bodycompany: {
          fontSize: 16,
          bold: true,
        },
        textcenter: {
          fontSize: 16,
          alignment: 'center'
        },
      },
      defaultStyle: {
        font: 'THSarabunNew'
      }
    };
    //

    pdfMake.createPdf(docDefinition).open()

    //});
  }

  render() {

    let width = this.state.width

    let tbodyList = this.state.car_claim_list.map((carClaim, index) => {

      let image_url = '';

      if (carClaim.car_upload_filename.includes('https')) {
        image_url = carClaim.car_upload_filename
      } else {
        image_url =  IMAGE_FULL_URL+carClaim.car_upload_filename
      }
      // console.log(image_url)

      return (
        <tr key={index}>
          <td className="text-center" width="10%">{carClaim.car_claim_id}</td>
          <td>
            วันที่ออกใบเคลม : {carClaim.claim_date} <br/>
            ลูกค้ารับรถ : {carClaim.claim_name} <br/>
            เบอร์โทร : {carClaim.claim_mobile} <br/>
            <img id={'img_'+carClaim.car_claim_id} src={image_url} className="img-cars" alt="car"/>
          </td>
          <td>
            รายการ : <br/>
            {carClaim.claim1_list1} <br/>
            {carClaim.claim1_list2} <br/>
            <div className={carClaim.claim2===''? 'hidden': ''}>
            <hr/>
            เคลมที่ 2 : <br/>
            {carClaim.claim2} <br/>
            </div>
            <div className={carClaim.claim3===''? 'hidden': ''}>
              <hr/>
            เคลมที่ 3 : <br/>
            {carClaim.claim3} <br/>

            </div>
          </td>
          <td width="10%">
            {this.convertClaimStatusName(carClaim.status)}
          </td>
          <td className="align-top text-center" width="10%" >
            <button onClick={async () => {
              await this.loadCarImageBase64(carClaim.car_upload_id)
              this.printClaim(carClaim, this.state.car_sell)
            }}
              type="button" className="btn btn-sm btn-primary btn-md-width mb-2">
              <i className="icon-printer"> พิมพ์</i>
            </button>

            <button
              className={carClaim.status==='complete'? 'hidden': 'btn btn-sm btn-warning btn-md-width mb-2'}
              onClick={() => {
                this.openClaimEdit(carClaim)
              }}
            >
              <i className="fa fa-edit"> แก้ไข</i>
            </button>

            <button
              className={carClaim.status==='complete'? 'hidden': 'btn btn-sm btn-danger btn-md-width'}
              onClick={() => {
                this.openClaimDelete(carClaim)
              }}
            >
              <i className="fa fa-remove"> ลบ</i>
            </button>

          </td>
        </tr>
      );
    });


    let tbodyEmpty = ()=>{
      return (
        <tr className="text-center">
          <td colSpan={5}><span className="text-danger">ไม่พบข้อมูล</span></td>
        </tr>
      )};

    return (
      <div className="col-md-12 mb-4">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({active: this.state.activeTab === '1'})}
              onClick={() => { this.toggle('1') }}
            >
              <i className="icon-options-vertical"/> ข้อมูลการปล่อยรถ/การเคลม
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <strong className="text-title">
                      รายการใบเคลม ทะเบียน&nbsp;
                      {this.state.car_claim.car_license_plate_new}
                      &nbsp;:&nbsp;
                      {this.state.car_claim.car_name}
                      &nbsp;:&nbsp;
                      {this.state.car_claim.date_promise}
                    </strong>
                    <button
                      type="button"
                      onClick={this.openClaimAdd}
                      className="btn btn-info float-right"
                    >
                      <i className="icon-plus"/>&nbsp; เพิ่ม ปล่อยรถ/เคลม
                    </button>
                    <Modal isOpen={this.state.modal}
                           toggle={this.toggleModal}
                           className={'none modal-dialog' + this.props.className}
                           //style={width<=980 ?{maxWidth:800}:{maxWidth:1200}}
                    >
                      <ModalBody>
                        <SalesClaimAdd
                          user={this.props.user}
                          car_sell={this.state.car_sell}
                          claim_edit={this.state.claim_edit}
                          form_status = {this.state.form_status}
                          claim_status = {this.state.claim_status}
                          onToggle={this.toggleModal}
                          onSubmit={this.salesClaimAdd}
                        />
                      </ModalBody>
                    </Modal>
                  </div>
                  <div className="card-block">
                    <div className="row">
                      <div className="col-lg-12">
                        <table className="table table-striped">
                          <thead>
                          <tr>
                            <th className="text-center">#</th>
                            <th className="text-center" width="20%">วันออกใบงาน</th>
                            <th>รายการ</th>
                            <th>สถานะ</th>
                            <th className="text-center">จัดการ</th>
                          </tr>
                          </thead>
                          <tbody>
                          {(tbodyList.length>0)? tbodyList: tbodyEmpty()}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </TabContent>

        <Modal isOpen={this.state.modalDelete} toggle={this.toggleModalDelete.bind(this)}
               className={'modal-danger'}>
          <ModalHeader toggle={this.toggleModalDelete.bind(this)}>ลบข้อมูล</ModalHeader>
          <ModalBody>
            <strong> คุณต้องการลบข้อมูล id={this.state.claim_edit.car_claim_id} ใช่หรือไม่ ?? </strong>
            <br />(ข้อมูลจะไม่สามารถกู้คืนได้)
          </ModalBody>
          <ModalFooter>
            <Button color="primary"
                    onClick={this.carClaimDelete.bind(this, this.state.claim_edit.car_claim_id)}>ตกลง</Button>{' '}
            <Button color="secondary" onClick={this.toggleModalDelete.bind(this)}>ยกเลิก</Button>

          </ModalFooter>
        </Modal>

      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(SalesClaim);