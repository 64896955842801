import React, { Component } from 'react'
import axios from 'axios'
import { APIURL, IMAGE_THUMB_URL } from '../../../config/config'
import { LineNotify } from '../../../config/lineNotify'
import { NumberUtil } from '../../../utils/number-util'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import 'react-datepicker/dist/react-datepicker.css'
import { connect } from 'react-redux'
import MaskedInput  from 'react-maskedinput'
import CarLeasingView from '../CarLeasingView'
import OccupationModal from '../../Customer/OccupationModal'
import ReactQuill from 'react-quill'

class CarBookForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      user: {},
      lists: [],
      load_data: true,
      car: this.props.car,
      car_id: '',
      customer_id: '',
      customer: {},
      customer_select: false,
      customer_list: [],
      car_sell_pay: [],
      car_sell_id: '',
      bank: [],
      car_status: [],
      car_sell_list_pay: [],
      sales_type: [],
      car_sell_pay_count: 0,
      person_transaction: '',
      startDatePay:  new Date() ,//moment(),
      carbookprimary: false,
      customer_mobile: '',
      customer_line: '',
      customer_facebook: '',
      car_book_list_pay_selected_id: 1,
      startDateCustomerNew1: moment().format('YYYY-MM-DD'),
      // profile
      profile_id: '',
      profile_from: '',
      profile_address: '',
      profile_fn1: '',
      profile_fn2: '',
      profile_fn3: '',
      profile_name: '',
      profile_ncb1: '',
      profile_guarantor1: '',
      profile_guarantor_ncb1: '',
      profile_guarantor2: '',
      profile_guarantor_ncb2: '',
      //
      carLeasingList: [],
      // การจอง
      cost_down: 0,
      cost_finance: 0,
      cost_check: 0,
      cost_insurance: 0,
      cost_car_tax: 0,
      cost_car_out: 0,
      cost_pledge: 0, // เงินมัดจำ
      cost_car_out_remain: 0,
      //
      cost_car_book: 0,
      cost_car_finance: 0,
      //
      cost_insurance_type: '',
      insurance_type_list: [],
      carBookEdit: {},
      province: [],
      occupation_id: 0,
      occupation_name: '',
      customer_income: 0,
      isOccupationModal: false,
      occupation: null,
      book_comment: '',
      booking_by: ''
    }

    this.handleDatePay = this.handleDatePay.bind(this)
    this.handleChangeSellListPay = this.handleChangeSellListPay.bind(this)
    this.handleDateCustomerNew1 = this.handleDateCustomerNew1.bind(this)
    this.onSubmitCustomer = this.onSubmitCustomer.bind(this)
    this.convertBBtoCC = this.convertBBtoCC.bind(this)
    this.onMaskedDateCustomerNew1 = this.onMaskedDateCustomerNew1.bind(this)
    this.onResetCustomer = this.onResetCustomer.bind(this)
    this.onSubmitProfile = this.onSubmitProfile.bind(this)
    this.handleChangeProfile = this.handleChangeProfile.bind(this)
    this.calculateCarOut = this.calculateCarOut.bind(this)
    // this.handleChangeCarReserve = this.handleChangeCarReserve.bind(this)
    this.addCarSellBookPay = this.addCarSellBookPay.bind(this)
    this.handleOnSubmitCarBook = this.handleOnSubmitCarBook.bind(this)
    this.loadProvinceData = this.loadProvinceData.bind(this)
    this.onSelectOccupation = this.onSelectOccupation.bind(this)
    this.getDataCustomerSearch = this.getDataCustomerSearch.bind(this)
  }

  componentWillMount () {
    // const queryString = require('query-string');
    this.setState({
      user: JSON.parse(this.props.user),
    })
  }

  handleDatePay(date) {
    // lock วันที่
    this.setState({
      startDatePay: date
    })
  }

  loadCarLeasingList(car_id) {
    var uri =  APIURL + '/car_leasing/'+car_id;
    axios.get(uri)
      .then(res => {
        this.setState({carLeasingList: res.data})
      }).catch(error => {
      this.setState({carLeasingList: []})
    })
  }

  handleChangeSellListPay(e){
    this.setState({car_book_list_pay_selected_id: e.target.value});
  }

  formatDate (date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day

    return [year, month, day].join('-')
  }

  addCarSellBookPay (car_book_id) {
    // let that = this
    let car_id = this.props.car_id
    let customer_id = this.state.customer_id
    // let car_status_id = this.refs.car_status_id.value
    let date_pay = moment(this.state.startDatePay).format('YYYY-MM-DD')  //this.formatDate(this.state.startDatePay)
    let car_sell_list_pay_id = this.refs.car_sell_list_pay_id.value
    let car_sell_pay_count = this.refs.car_sell_pay_count.value
    let sales_type_id = this.refs.sales_type_id.value
    // let car_sell_id = event.target.car_sell_id.value
    let bank_id = this.refs.bank_id.value
    let person_transaction = this.state.user.user

    let data =  {
      car_id: car_id,
      customer_id: customer_id,
      //car_status_id : car_status_id,
      date_pay: date_pay,
      car_sell_list_pay_id: car_sell_list_pay_id,
      car_sell_pay_count: car_sell_pay_count,
      sales_type_id: sales_type_id,
      //car_sell_id : car_sell_id,
      bank_id: bank_id,
      person_transaction: person_transaction,
      user_created: this.state.user.user,
      user_updated: this.state.user.user,
      car_book_id: car_book_id
    }


    if(this.props.book_status === 'add') {

      axios.post(APIURL + '/car_sell_pay/', data)
        .then(res => {
          if(res.status === 200) {
            this.props.onToggle()
            this.props.onLoadCarBook()
          }
        })
        .catch( error =>  {
          console.log(error)
        })
    }else if(this.props.book_status === 'edit'){
      var car_sell_pay_id = this.props.car_sell_pay_selected.car_sell_pay_id
      axios.put(APIURL + '/car_sell_pay/book/'+car_sell_pay_id, data)
        .then(res => {
          if(res.status === 200) {
            this.props.onToggle()
            this.props.onLoadCarBook()
          }
        })
        .catch(error => {
          console.log(error)
          //alert('error' + error)
        })
    }
  }

  handleOnSubmitCarBook (event) {
    event.preventDefault()

    let car_sell_pay_count = Number(event.target.car_sell_pay_count.value) // this.refs.car_sell_pay_count.value
    let sales_type_id = event.target.sales_type_id.value
    let bank_id = event.target.bank_id.value
    let customer_id = this.state.customer_id
    // ราคา
    let cost_down = event.target.cost_down.value
    let cost_finance = event.target.cost_finance.value
    let cost_check =  0 ; //event.target.cost_check.value รวมกับ cost_finance
    let cost_insurance = event.target.cost_insurance.value
    let cost_car_tax = event.target.cost_car_tax.value
    let cost_car_out = event.target.cost_car_out.value
    let cost_pledge = event.target.cost_pledge.value
    let cost_car_out_remain = event.target.cost_car_out_remain.value
    //
    let insurance_type_name = event.target.insurance_type_name.value
    //
    let cost_car_book = event.target.cost_car_book.value
    let cost_car_finance = event.target.cost_car_finance.value
    let book_comment = this.state.book_comment
    let booking_by = this.state.booking_by

    if (customer_id === '') {
      alert('กรุณาเลือกลูกค้าที่ต้องการจอง')
      return
    }

    if(this.state.profile_id === '') {
      alert('กรุณาเลือกบันทึกโปรไฟล์ลูกค้า')
      return;
    }

    // if (car_sell_pay_count === 0) {
    //   alert('กรุณากรอกข้อมูลการชำระเงิน')
    //   event.target.car_sell_pay_count.focus();
    //   return;
    // }

    if(sales_type_id === ''){
      alert('กรุณาเลือกประเภทการรับชำระ');
      return;
    }

    if(bank_id === ''){
      alert('กรุณาเลือกธนาคาร');
      return;
    }

    // let that = this
    let car_id = this.props.car_id
    let car_book_status_id = 1

    //lineNotify
    let index = event.nativeEvent.target.car_sell_list_pay_id.selectedIndex;
    let car_sell_list_pay = event.nativeEvent.target.car_sell_list_pay_id[index].text
    let car_name = this.props.car.car_name
    let car_color_name = this.props.car.car_color_name
    // let plate_red = this.props.car.plate_red
    let car_license_plate_new = this.props.car.car_license_plate_new
    let profile_from = this.state.profile_from
    let customer_name = this.state.customer_name
    let customer_mobile = this.state.customer_mobile

    let newMobile = NumberUtil.mobileFormat(customer_mobile)
    let pay = NumberUtil.addCommas(car_sell_pay_count)

    let fullname = this.state.user.fullname

    let message = car_sell_list_pay+" "+car_name+
        "\nสีรถ : "+car_color_name+" "+
        // "\nป้ายแดง : "+plate_red+" "+
        "\nทะเบียน : "+car_license_plate_new+" "+
        "\nลูกค้าจาก : "+profile_from+" "+
        "\nลูกค้า: "+customer_name+"\nจอง "+pay+" บาท" +
        "\nโทร: "+newMobile +
        "\nผู้ทำรายการ: "+ fullname


    let data = {
      car_id: car_id,
      customer_id: customer_id,
      car_book_status_id: car_book_status_id,
      user_created: this.state.user.user,
      user_updated: this.state.user.user,
      //ราคา
      cost_down: cost_down,
      cost_finance: cost_finance,
      cost_check: cost_check, //ยกเลิก
      cost_insurance: cost_insurance,
      cost_car_tax: cost_car_tax,
      cost_car_out: cost_car_out,
      cost_pledge: cost_pledge, // เงินมัดจำ
      cost_car_out_remain: cost_car_out_remain,
      //
      insurance_type_name: insurance_type_name,
      //
      cost_car_book: cost_car_book,
      cost_car_finance: cost_car_finance,
      book_comment: book_comment,
      booking_by : booking_by
    }

    if(this.props.book_status==='add'){
      axios.post(APIURL + '/car_book/', data )
        .then(response => {
          let car_book_id = response.data.insertId
          // console.log('car_book_id=', car_book_id)
          this.addCarSellBookPay(car_book_id)
          // TODx: LineNotify
          LineNotify.sendNotify(message)
        })
        .catch(error => {
          console.log('error: ', error)
        })
    } else if(this.props.book_status==='edit'){

      let car_book_id = this.props.car_sell_pay_selected.car_book_id

      axios.put(APIURL + '/car_book/'+car_book_id, data )
        .then(res => {
          // var car_book_id = response.data.insertId
          this.addCarSellBookPay(car_book_id)
          LineNotify.sendNotify(message)
          //that.lineNotify(message)
        })
        .catch(error => {
          console.log(error)
          //alert("error"+error)
        })
    }
  }

  loadBankData () {
    axios.get(APIURL + '/bank/')
      .then(res => {
        this.setState({bank: res.data})
      }).catch(error => {
      this.setState({bank: []})
    })
  }

  loadSalesTypeData () {
    axios.get(APIURL + '/sales_type/')
      .then(res => {
        this.setState({sales_type: res.data})
        //alert (res.data)
      }).catch(error => {
      this.setState({sales_type: []})
    })
  }

  loadCarSellListPayData () {
    axios.get(APIURL + '/car_sell_list_pay/')
      .then(res => {
        var data = res.data.filter(item=>{
          return item.car_sell_list_pay_id === 1
        });
        // this.setState({car_sell_list_pay: res.data})
        this.setState({car_sell_list_pay: data})
        //alert (res.data)
      }).catch(error => {
      this.setState({car_sell_list_pay: []})
    })
  }

  loadData () {
    axios.get(APIURL + '/car_book/')
      .then(res => {
        //alert("res"+res);
        this.setState({lists: res.data,})
      }).catch(error => {
      //alert("error"+error.message);
      this.setState({lists: []})
    })
  }

  componentDidMount () {
    var car_id = this.props.car_id
    //alert('car_id='+car_id)
    this.setState({car_id: car_id}, () => {
      //this.loadCarById()
      //this.loadDataCarSellPay ()
    })
    //alert(car.car_id)
    this.loadBankData()
    this.loadSalesTypeData()
    this.loadCarSellListPayData()
    this.loadCarLeasingList(car_id)
    this.loadInsuranceTypeData()
    this.loadProvinceData()

    if(this.props.book_status === 'edit'){
      var customer_id = this.props.car_sell_pay_selected.customer_id
      var car_book_id = this.props.car_sell_pay_selected.car_book_id
      var car_sell_pay_id = this.props.car_sell_pay_selected.car_sell_pay_id

      this.loadCustomer(customer_id);
      this.loadProfile(customer_id);
      //load book id
      this.loadCarBook(car_book_id);
      this.loadCarSellPay(car_sell_pay_id)
    }

  }

  loadProvinceData () {
    axios.get(APIURL + '/province/')
      .then(res => {
        this.setState({province: res.data})
      }).catch(error => {
      this.setState({province: []})
    })
  }

  salesTypeChange (obj) {
    //alert ("salesTypeChange="+obj.target.text)
    var value = obj.target.value ;
    if (value === '1') {
      this.refs.bank_id.value = '1'
    } else {
      this.refs.bank_id.value = ''
    }
  }

  bankChange(event){
    var value = event.target.value;
    var sales_type_id = this.refs.sales_type_id.value;
    if(sales_type_id==='1'){
      this.refs.bank_id.value = '1';
    }else{ // no เงินสด
      if(value==='1'){ // เงินสด
        this.refs.bank_id.value = '';
      }
    }
  }

  searchCustomer () {
    let search_text = this.refs.search_customer_name.value
    //alert(search_text);
    if (search_text === '') {
      return
    }

    if (search_text.length < 3) {
      return
    }

    this.getDataCustomerSearch(search_text)
  }

  getDataCustomerSearch (search_text) {
    axios.get(APIURL + '/customer/search/' + search_text)
      .then(res => {
        this.setState({customer_list: res.data})
        //alert (res.data)
      }).catch(error => {
      this.setState({customer_list: []})
    })
  }

  onCustomerSelect (customer, event) {
    this.setState({
      customer_select: true,
      customer: customer,
      customer_id: customer.customer_id,
      customer_name: customer.customer_name,
      customer_mobile: customer.customer_mobile
    }, () => {
      this.loadProfile(customer.customer_id)
    })
  }

  loadProfile(customer_id) {

    axios.get(APIURL + '/profile/customer/'+customer_id)
      .then(res => {

        if(res.data.length > 0) {
          var profile = res.data[0];
          this.setState({
            profile_from: profile.profile_from,
            profile_address: profile.profile_address,
            profile_fn1: profile.profile_fn1,
            profile_fn2: profile.profile_fn2,
            profile_fn3: profile.profile_fn3,
            profile_name: profile.profile_name,
            profile_ncb1: profile.profile_ncb1,
            profile_guarantor1: profile.profile_guarantor1,
            profile_guarantor_ncb1: profile.profile_guarantor_ncb1,
            profile_guarantor2: profile.profile_guarantor2,
            profile_guarantor_ncb2: profile.profile_guarantor_ncb2,
            profile_id: profile.profile_id
          })
        }
      }).catch(error => {
       // this.setState({profile_lists: []})
    })


  }

  loadCustomer(customer_id) {
    axios.get(APIURL + '/customer/'+customer_id)
      .then(res => {
        if(res.status === 200){
          var customer = res.data[0]
          this.setState({
            customer_select: true,
            customer: customer,
            customer_id: customer.customer_id,
            customer_name: customer.customer_name,
            customer_mobile: customer.customer_mobile
          })
        }
      })
      .catch(err => console.log(err))
  }

  loadCarBook(car_book_id) {
    axios.get(APIURL + '/car_book/book/'+car_book_id)
      .then(res => {
        var carBookEdit = res.data[0];
        this.setState({
          carBookEdit:  carBookEdit, // เก็บค่าเดิมก่อนแก้ไข
          cost_down:    carBookEdit.cost_down ,
          cost_finance: carBookEdit.cost_finance,
          cost_check:   carBookEdit.cost_check,
          cost_insurance: carBookEdit.cost_insurance,
          cost_car_tax: carBookEdit.cost_car_tax,
          cost_car_out: carBookEdit.cost_car_out,
          cost_pledge:  carBookEdit.cost_pledge, // เงินมัดจำ
          cost_car_out_remain: carBookEdit.cost_car_out_remain,
          insurance_type_name: carBookEdit.insurance_type_name,
          cost_car_book: carBookEdit.cost_car_book,
          cost_car_finance: carBookEdit.cost_car_finance,
          book_comment: carBookEdit.book_comment,
          booking_by: carBookEdit.booking_by,

        },()=>{
          this.refs.insurance_type_name.value = this.state.insurance_type_name
        })

      }).catch(error => {
      //alert("error"+error.message);
      this.setState({carBookEdit: {}})
    })
  }

  loadCarSellPay(car_sell_pay_id) {
     //car_sell_pay
    axios.get(APIURL + '/car_sell_pay/'+car_sell_pay_id)
      .then(res => {
        if(res.status === 200){
          var carSellPay = res.data[0];
          this.setState({
            // startDatePay: moment(carSellPay.date_pay).format('YYYY-MM-DD'),
            startDatePay: new Date(carSellPay.date_pay),
            car_book_list_pay_selected_id: carSellPay.car_sell_list_pay_id,
            cost_pledge: carSellPay.car_sell_pay_count,
          },()=>{
            this.refs.sales_type_id.value = carSellPay.sales_type_id;
            this.refs.bank_id.value = carSellPay.bank_id;
          });

        }
      }).catch(error => {
         // alert("error"+error.message);
         // this.setState({carBookEdit: {}})
      })

  }

  onMaskedDateCustomerNew1 (e){
    let buddhist_date = e.target.value ;
    //alert('date='+date);
    if(buddhist_date.length===10){
      let cc_date = this.convertBBtoCC(buddhist_date)
      this.handleDateCustomerNew1(cc_date)
    }
  }

  handleDateCustomerNew1(date) {
    this.setState({
      startDateCustomerNew1: moment(date).format('YYYY-MM-DD')
    });
  }

  convertBBtoCC(buddhist_date){ //dd-mm-yyyy to yyyy-mm-dd
    let date_arr=buddhist_date.split('-');
    let dd = date_arr[0];
    let mm = date_arr[1];
    let yyyy = date_arr[2]-543;
    let cc_date= yyyy+'-'+mm+'-'+dd ;
    if(moment(cc_date, 'YYYY-MM-DD', true).isValid()){
      //alert(cc_date);
      return cc_date ;
    }
    return moment() ;
  }

  onSubmitCustomer(event) {
    event.preventDefault()
    let customer_name = event.target.customer_name.value
    let customer_lastname = event.target.customer_lastname.value
    let customer_card_id = event.target.customer_card_id.value
    let customer_birth = this.state.startDateCustomerNew1
    let customer_age = event.target.customer_age.value
    let customer_mobile = event.target.customer_mobile.value
    let customer_address = event.target.customer_address.value
    let customer_line = event.target.customer_line.value
    let customer_facebook = event.target.customer_facebook.value
    //
    let occupation_id = event.target.occupation_id.value
    let customer_income = event.target.customer_income.value
    let province_id = event.target.province_id.value
    //
    let guarantor_name = event.target.guarantor_name.value
    let guarantor_address = event.target.guarantor_address.value
    let guarantor_mobile = event.target.guarantor_mobile.value

    if(customer_name===''){
      alert('กรุณากรอกข้อมูลลูกค้า');
      return ;
    }

    let data = {
      customer_name: customer_name,
      customer_lastname: customer_lastname,
      customer_card_id : customer_card_id,
      customer_birth: customer_birth,
      customer_age : customer_age,
      customer_mobile: customer_mobile,
      customer_address : customer_address,
      customer_line : customer_line,
      customer_facebook : customer_facebook,
      username: this.state.user.user,
      guarantor_name: guarantor_name,
      guarantor_address: guarantor_address,
      guarantor_mobile: guarantor_mobile,
      occupation_id: occupation_id,
      customer_income: customer_income,
      province_id: province_id
    }

    axios.post(APIURL + '/customer/', data)
      .then(res => {
        let customer = res.data;
        this.setState({
          customer_select: true,
          customer: customer,
          customer_id: customer.customer_id,
          customer_name: customer.customer_name,
          customer_mobile: customer.customer_mobile,
          customer_line: customer.customer_line,
          customer_facebook: customer.customer_facebook,
          guarantor_name: customer.guarantor_name,
          guarantor_mobile: customer.guarantor_mobile,
          guarantor_address: customer.guarantor_address,
          customer_income: customer.customer_income,
          province_id: customer.province_id,
          province_name: customer.province_name,
          occupation_id: customer.occupation_id,
          occupation_name: customer.occupation_name,
        });
      })
      .catch(function (error) {
        //alert("error:"+error)
        console.log(error)
      })

  }

  onResetCustomer() {
    this.setState({
      customer_select: false,
      customer: {},
      customer_id: '',
      customer_name: '',
      customer_mobile: '',
      customer_line: '',
      customer_facebook: '',
      guarantor_name: '',
      guarantor_mobile: '',
      guarantor_address: '',
      occupation_id: 0,
      customer_income: 0,
      province_id: 0
    });
  }

  onSubmitProfile(event) {
    event.preventDefault()

    var profile_from = event.target.profile_from.value;
    var profile_address = event.target.profile_address.value;
    var profile_fn1 = event.target.profile_fn1.value;
    var profile_fn2 = event.target.profile_fn2.value;
    var profile_fn3 = event.target.profile_fn3.value;
    var profile_name = event.target.profile_name.value;
    var profile_ncb1 = event.target.profile_ncb1.value;
    var profile_guarantor1 = event.target.profile_guarantor1.value;
    var profile_guarantor_ncb1 = event.target.profile_guarantor_ncb1.value;
    var profile_guarantor2 = event.target.profile_guarantor2.value;
    var profile_guarantor_ncb2 = event.target.profile_guarantor_ncb2.value;
    var user = this.state.user.user;
    var customer_id = event.target.customer_id.value;

    const profileData = {
      profile_from: profile_from,
      profile_address: profile_address,
      profile_fn1: profile_fn1,
      profile_fn2: profile_fn2,
      profile_fn3: profile_fn3,
      profile_name: profile_name,
      profile_ncb1: profile_ncb1,
      profile_guarantor1: profile_guarantor1,
      profile_guarantor_ncb1: profile_guarantor_ncb1,
      profile_guarantor2: profile_guarantor2,
      profile_guarantor_ncb2: profile_guarantor_ncb2,
      user: user,
      customer_id: customer_id,
    }

    //add
    if(this.state.profile_id === '') {
      axios.post(APIURL + '/profile', profileData)
        .then((res) => {
          // get profile id for edit
          if (res.status === 200) {
            var profile = res.data
            this.setState({
              profile_id: profile.profile_id
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }else{
      //update profile
      var profile_id = this.state.profile_id;
      axios.put(APIURL + '/profile/'+profile_id, profileData)
        .then((res) => {
          if (res.status === 200) {
            // this.toggleProfileForm();
            alert('แก้ไขโปรไฟล์ลูกค้าเรียบร้อยแล้ว');
          }
        })
        .catch((err) => {
          console.log(err);
        });

    }

  }

  handleChangeProfile(event) {
    var targetName = event.target.name;
    var value = event.target.value;

    if(targetName === 'profile_from'){
      this.setState({ profile_from: value });
    } else if(targetName === 'profile_address'){
      this.setState({ profile_address: value });
    } else if(targetName === 'profile_fn1'){
      this.setState({ profile_fn1: value });
    } else if(targetName === 'profile_fn2'){
      this.setState({ profile_fn2: value });
    } else if(targetName === 'profile_fn3'){
      this.setState({ profile_fn3: value });
    } else if(targetName === 'profile_name'){
      this.setState({ profile_name: value });
    } else if(targetName === 'profile_ncb1'){
      this.setState({ profile_ncb1: value });
    } else if(targetName === 'profile_guarantor1'){
      this.setState({ profile_guarantor1: value });
    } else if(targetName === 'profile_guarantor_ncb1'){
      this.setState({ profile_guarantor_ncb1: value });
    } else if(targetName === 'profile_guarantor2'){
      this.setState({ profile_guarantor2: value });
    } else if(targetName === 'profile_guarantor_ncb2'){
      this.setState({ profile_guarantor_ncb2: value });
    }

  }

  /*handleChangeCarReserve(event) {
    var targetName = event.target.name;
    var value = event.target.value;
    if(targetName === 'cost_insurance_type') {
      this.setState({ cost_insurance_type: value });
    }
  }*/

  calculateCarOut() {

    let cost_car_book = Number(this.state.cost_car_book); // ราคาขาย
    let cost_down = Number(this.state.cost_down);
    let cost_finance = Number(this.state.cost_finance); // ค่าโอน+ค่าจัด
    let cost_check = Number(0); // Number(this.state.cost_check);
    let cost_insurance = Number(this.state.cost_insurance);
    let cost_car_tax = Number(this.state.cost_car_tax);
    let cost_car_finance = Number(this.state.cost_car_finance) // ราคาไฟแนนท์

    if (cost_car_finance > 0) {
      // ราคาเงินสดผ่อน
      let cost_car_out = cost_down + cost_finance + cost_check + cost_insurance + cost_car_tax

      let cost_pledge = Number(this.state.cost_pledge);// เงินมัดจำ
      let cost_car_out_remain = Number(cost_car_out) - Number(cost_pledge);

      this.setState({
        cost_car_out: cost_car_out,
        cost_car_out_remain: cost_car_out_remain
      });
    } else {
      // ราคาเงินสด
      let car_remain = cost_car_book
      // รวมเงินจ่าย
      let cost_car_out = cost_down + cost_finance + cost_check + cost_insurance + cost_car_tax
      let cost_pledge = Number(this.state.cost_pledge);// เงินมัดจำ

      let cost_car_out_remain = car_remain + Number(cost_car_out) - Number(cost_pledge);
      this.setState({
        cost_car_out: cost_car_out,
        cost_car_out_remain: cost_car_out_remain
      });

    }

  }

  loadInsuranceTypeData () {
    axios.get(APIURL + '/insurance_type/')
      .then(res => {
        this.setState({insurance_type_list: res.data})
      }).catch(error => {
      this.setState({insurance_type_list: []})
    })
  }


  onSelectOccupation(occupation) {
    this.setState({
      occupation: occupation,
      occupation_id: occupation.occupation_id,
      occupation_name: occupation.occupation_name,
      isOccupationModal: false
    })
  }

  render () {
    // let car = this.state.car ;
    // let fullname = this.state.user.fullname

    let search_customer_list = this.state.customer_list.map((customer_list, index) => {
      return (
        <tr key={index}>
          <td><strong>ชื่อ</strong> &nbsp;&nbsp; {customer_list.customer_name}</td>
          <td><strong>นามสกุล</strong> &nbsp;&nbsp; {customer_list.customer_lastname}</td>
          <td><strong>เลขประจำตัวประชาชน</strong> &nbsp;&nbsp; {customer_list.customer_card_id}</td>
          <td><strong>เบอร์โทรศัพท์</strong> &nbsp;&nbsp; {customer_list.customer_mobile}</td>
          <td>
            <button onClick={() => this.onCustomerSelect(customer_list, this)} type="button"
                    className="btn btn-primary">
              <i className="fa fa-hand-pointer-o"></i>&nbsp;&nbsp; เลือก
            </button>
          </td>
        </tr>
      )
    })

    let car_book_list_pay_list = this.state.car_sell_list_pay.map((car_sell_list_pay, index) => {
        return (
          <option key={index}
                  value={car_sell_list_pay.car_sell_list_pay_id}
          >
            {car_sell_list_pay.car_sell_list_pay_name}
          </option>
        );
    })

    let sales_type_list = this.state.sales_type.map((sales_type, index) => {
      return (
        <option key={index} value={sales_type.sales_type_id}>{sales_type.sales_type_name}</option>
      )
    })

    let bank_pay_list = this.state.bank.map((bank, index) => {
      return (
        <option key={index} value={bank.bank_id}>{bank.bank_name}</option>
      )
    })

    let insurance_type_list = this.state.insurance_type_list.map((insurance_type, index) => {
      return (
        <option key={index}
                value={insurance_type.insurance_type_name}>{insurance_type.insurance_type_name}</option>
      )
    })

    let image_url = ''
    if (this.state.car.car_upload_filename.includes('https')) {
      image_url = this.state.car.car_upload_filename
    } else {
      image_url = IMAGE_THUMB_URL + this.state.car.car_upload_filename
    }

    let province_list = this.state.province.map((province, index) => {
      return (
        <option key={index+1} value={province.province_id}>{province.province_name}</option>
      )
    })

    return (
      <div>

        <OccupationModal
          isModal={this.state.isOccupationModal}
          onSelectOccupation={this.onSelectOccupation}
          closeModal={() => {
            this.setState({
              isOccupationModal: false,
              occupation: null
            })
          }}
        />

        <div className="row">
          <div className="col-lg-12">
            <form action="" method="post" onSubmit={this.onSubmitCustomer}>
              <div className="card">
                <div className="card-header">
                  <strong className="text-title">ข้อมูลลูกค้า</strong> &nbsp;&nbsp;
                   <button
                     type="button"
                     className="btn btn-sm btn-head-bar ml-2"
                     style={styles.buttonHeight}
                     onClick={this.onResetCustomer}
                   >
                     <i className="icon-refresh"/>
                   </button>
                </div>
                <div className="card-block">
                  <div className="row">
                    <div className="col-lg-12">

                      {/* ค้นหาลูกค้า */}

                      <div className={!this.state.customer_select ? 'row ' : ' hidden'}>
                        <div className="controls col-md-12">
                          <div className="input-group ">
                            <input onChange={this.searchCustomer.bind(this)}
                                   ref="search_customer_name"
                                   placeholder="ค้นหาชื่อลูกค้าที่เคยลงทะเบียนไว้ ถ้าเป็นลูกค้าใหม่ให้กรอกข้อมูลด้านล่าง ค้น 3 ตัวอักษรขึ้นไป"
                                   className="form-control"
                                   size="16"
                                   type="text"/>

                            <span className="input-group-btn ">
                                 <button className="btn btn-success"
                                         type="button"
                                         onClick={this.searchCustomer.bind(this)}>ค้นหา
                                 </button>
                            </span>
                          </div>
                          <div id="show_customer" className="row">
                            <div className="controls col-md-12">
                              <table className="table table-striped">
                                <tbody>
                                {search_customer_list}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className={this.state.customer_select ? 'row bg-faded' : ' hidden'}>

                        <div className="card-header controls col-md-12 hidden bg-faded">
                          <strong className="text-title">ข้อมูลลูกค้า</strong>
                        </div>

                        <div className="card-block">

                          <div className="row">
                            <div className="col-lg-12">
                              <div className=" form-group row" style={styles.bottomLine}>
                                <label className="col-md-2"><strong> ชื่อลูกค้า </strong></label>
                                <div className="col-md-4">
                                  <p className="text-justify text-primary">
                                    <strong>{this.state.customer.customer_name}</strong></p>
                                </div>

                                <label className="col-md-2"><strong> นามสกุล </strong></label>
                                <div className="col-md-4">
                                  <p className="text-justify text-primary">
                                    <strong> {this.state.customer.customer_lastname} </strong></p>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className=" form-group row" style={styles.bottomLine}>
                                <label className="col-md-2"><strong> เลขบัตรประชาชน </strong></label>
                                <div className="col-md-4">
                                  <p className="text-justify text-primary">
                                    <strong>{this.state.customer.customer_card_id}</strong></p>
                                </div>

                                <label className="col-md-2"><strong> เบอร์โทรศัพท์ </strong></label>
                                <div className="col-md-4">
                                  <p className="text-justify text-primary">
                                    <strong> {this.state.customer.customer_mobile} </strong></p>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className=" form-group row" style={styles.bottomLine}>
                                <label className="col-md-2"><strong> ที่อยู่ปัจจุบัน </strong></label>
                                <div className="col-md-10">
                                  <p className="text-justify text-primary">
                                    <strong>{this.state.customer.customer_address}</strong></p>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className=" form-group row" style={styles.bottomLine}>
                                <label className="col-md-2"><strong> Line </strong></label>
                                <div className="col-md-4">
                                  <p className="text-justify text-primary">
                                    <strong>{this.state.customer.customer_line}</strong></p>
                                </div>

                                <label className="col-md-2"><strong> Facebook </strong></label>
                                <div className="col-md-4">
                                  <p className="text-justify text-primary">
                                    <strong> {this.state.customer.customer_facebook} </strong></p>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className=" form-group row" style={styles.bottomLine}>
                                <label className="col-md-2"><strong> อาชีพ </strong></label>
                                <div className="col-md-4">
                                  <p className="text-justify text-primary">
                                    <strong>{this.state.customer.occupation_name}</strong></p>
                                </div>

                                <label className="col-md-2"><strong> รายได้ </strong></label>
                                <div className="col-md-4">
                                  <p className="text-justify text-primary">
                                    <strong> {this.state.customer.customer_income} </strong></p>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className=" form-group row" style={styles.bottomLine}>
                                <label className="col-md-2"><strong> จังหวัด </strong></label>
                                <div className="col-md-4">
                                  <p className="text-justify text-primary">
                                    <strong>{this.state.customer.province_name}</strong></p>
                                </div>

                              </div>
                            </div>

                          </div>

                          <div className="row">
                            <div className="col-lg-12">
                              <strong>ข้อมูลผู้ค้ำประกัน</strong>
                            </div>
                            <div className="col-lg-12" style={{marginTop: 20}}>
                              <div className=" form-group row" style={styles.bottomLine}>
                                <label className="col-md-2"><strong> ชื่อผู้ค้ำ </strong></label>
                                <div className="col-md-4">
                                  <p className="text-justify text-primary">
                                    <strong>{this.state.customer.guarantor_name}</strong></p>
                                </div>

                                <label className="col-md-2"><strong> เบอร์โทรผู้ค้ำ </strong></label>
                                <div className="col-md-4">
                                  <p className="text-justify text-primary">
                                    <strong> {this.state.customer.guarantor_mobile} </strong></p>
                                </div>
                              </div>
                            </div>

                            <div className="col-lg-12">
                              <div className=" form-group row" style={styles.bottomLine}>
                                <label className="col-md-2"><strong> ที่อยู่ผู้ค้ำ </strong></label>
                                <div className="col-md-10">
                                  <p className="text-justify text-primary">
                                    <strong>{this.state.customer.guarantor_address}</strong></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* form เพิ่มลูกค้า */}

                      <div className={!this.state.customer_select ? 'row ' : ' hidden'}>
                          <div className="form-group col-sm-3">
                            <label htmlFor="customer_name">ชื่อ</label>
                            <input type="text"
                                   className="form-control"
                                   id="customer_name"
                                   placeholder=""
                            />
                          </div>
                          <div className="form-group col-sm-3">
                            <label htmlFor="customer_lastname">นามสกุล</label>
                            <input type="text"
                                   className="form-control"
                                   id="customer_lastname"
                                   placeholder=""
                            />
                          </div>
                          <div className="form-group col-sm-3">
                            <label htmlFor="customer_card_id">เลขบัตรประชาชน</label>
                            <input type="text"
                                   className="form-control hidden"
                                   id="customer_card_id_old-bk"
                                   maxLength="13"
                                   placeholder=""
                            />

                            <MaskedInput
                                mask="1-1111-11111-11-1"
                                className="form-control"
                                id="customer_card_id"
                                maxLength="20"
                                placeholder=""
                            />
                          </div>
                          <div className="form-group col-sm-3">
                            <label htmlFor="customer_birth_text">วันเดือนปีเกิด(พ.ศ.)</label>
                            <MaskedInput
                              mask="11-11-1111"
                              name="customer_birth_text"
                              placeholder="dd-mm-yyyy"
                              onChange={this.onMaskedDateCustomerNew1}
                              className="form-control"
                            />
                          </div>
                          <div className="form-group col-sm-3">
                            <label htmlFor="customer_age">อายุ</label>
                            <input type="text"
                                   className="form-control"
                                   id="customer_age"
                                   placeholder=""
                            />
                          </div>
                          <div className="form-group col-sm-3">
                            <label htmlFor="customer_mobile">เบอร์โทรศัพท์</label>
                            <input type="text"
                                   className="form-control hidden"
                                   id="customer_mobile_old-bk"
                                   placeholder=""
                            />

                            <MaskedInput
                                mask="111-111-1111"
                                className="form-control"
                                id="customer_mobile"
                                placeholder=""
                            />
                          </div>
                        <div className="form-group col-sm-6">
                          <label htmlFor="customer_address">ที่อยู่ปัจจุบัน</label>
                          <input type="text"
                                 className="form-control"
                                 id="customer_address"
                                 placeholder=""
                          />
                        </div>

                        <div className="form-group col-sm-3">
                          <label htmlFor="customer_line">Line</label>
                          <input type="text"
                                 className="form-control"
                                 id="customer_line"
                                 placeholder=""
                          />
                        </div>

                        <div className="form-group col-sm-3">
                          <label htmlFor="customer_facebook">Facebook</label>
                          <input type="text"
                                 className="form-control"
                                 id="customer_facebook"
                                 placeholder=""
                          />
                        </div>

                        <div className="form-group col-sm-3">
                          <label htmlFor="receipt_date">อาชีพ</label>
                          <input type="text"
                                 className="form-control hidden"
                                 id="occupation_id"
                                 name="occupation_id"
                                 placeholder=""
                                 value={this.state.occupation_id}
                                 disabled={false}
                                 onChange={() => {}}
                          />
                          <div className="form-group row">
                            <div className="col-md-12">
                              <div className="input-group">
                                <input type="text"
                                       className="form-control"
                                       placeholder=""
                                       value={this.state.occupation_id !== 0 ? this.state.occupation_name : '' }
                                       disabled={true}
                                />
                                <span className="input-group-btn">
                                            <button className="btn btn-secondary"
                                                    name="agent_find"
                                                    type="button"
                                                    style={styles.buttonHeight}
                                                    onClick={() => {
                                                      this.setState({
                                                        isOccupationModal: true
                                                      })
                                                    }}
                                            >
                                                <i className="icon-magnifier"/>
                                            </button>

                                        </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="form-group col-sm-3">
                          <label htmlFor="cost">รายได้</label>
                          <input type="text"
                                 className="form-control hidden "
                                 name="customer_income"
                                 placeholder=""
                                 ref="customer_income"
                                 value={this.state.customer_income}
                                 onChange={() => {}}
                          />
                          <NumberFormat
                            className="form-control"
                            thousandSeparator={true}
                            prefix={'฿'}
                            placeholder="รายได้"
                            min={0}
                            max={1000000000}
                            step={1000}
                            size={10}
                            allowNegative={false}
                            onValueChange={(values) => {
                              const {value} = values
                              this.setState({customer_income: value})
                            }}
                          />
                        </div>

                        <div className="form-group col-sm-3">
                          <label htmlFor="province">จังหวัด</label>
                          <select className="form-control" name="province_id">
                            <option key={0} value={0}>ไม่ระบุ</option>
                            {province_list}
                          </select>
                        </div>


                        <div className="col-sm-12"><hr/></div>

                        <div className="row col-sm-12">
                          <div className="col-sm-12">
                             <strong>ข้อมูลผู้ค้ำประกัน</strong>
                          </div>
                          <div className="row col-sm-12" style={{marginTop: 20}}>

                            <div className="form-group col-sm-3" >
                              <label htmlFor="guarantor_name">ชื่อ-นามสกุลผู้ค้ำ</label>
                              <input type="text"
                                     className="form-control"
                                     id="guarantor_name"
                                     placeholder=""
                              />
                            </div>
                            <div className="form-group col-sm-3">
                              <label htmlFor="guarantor_mobile">เบอร์โทรผู้ค้ำ</label>
                              <input type="text"
                                     className="form-control hidden"
                                     id="guarantor_mobile_old-bk"
                                     placeholder=""
                              />

                              <MaskedInput
                                  mask="111-111-1111"
                                  className="form-control"
                                  id="guarantor_mobile"
                                  placeholder=""
                              />
                            </div>
                            <div className="form-group col-sm-6">
                              <label htmlFor="guarantor_address">ที่อยู่ผู้ค้ำ</label>
                              <input type="text"
                                     className="form-control"
                                     id="guarantor_address"
                                     placeholder=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div  className={!this.state.customer_select ? 'card-footer text-right ' : ' hidden'}>
                  <button type="submit"
                          className="btn btn-sm btn-primary"
                          disabled={this.state.customer_id !=='' ? true : false}
                  >
                    <i className="fa fa-save"/> &nbsp;บันทึกข้อมูลลูกค้า
                  </button>
                </div>

              </div>
          </form>
          </div>
        </div>

        {/* profile ลูกค้า */}
        <form action="" method="post" onSubmit={this.onSubmitProfile}>
        <div className="row">

            <div className="col-lg-12">
              <div className="card">
                <div className="card-header card-customer">
                  <strong className="text-title">โปรไฟล์ลูกค้า</strong>
                </div>

                 <div className="card-block">
                  <div className="row">
                    <div
                      className="btn-group colors col-lg-12"
                      style={{height: 40, padding: 10}}
                    >
                      <input type="radio"
                             name="profile_from"
                             value="เพจ"
                             className="btn btn-primary"
                             checked={this.state.profile_from === 'เพจ'}
                             onChange={this.handleChangeProfile}
                      /> &nbsp;<span style={{marginTop: 6}}>เพจ</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input type="radio"
                             name="profile_from"
                             value="หน้าร้าน"
                             className="btn btn-primary"
                             checked={this.state.profile_from === 'หน้าร้าน'}
                             onChange={this.handleChangeProfile}
                      /> &nbsp;<span style={{marginTop: 6}}>หน้าร้าน</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input type="radio"
                             name="profile_from"
                             value="Line OA"
                             className="btn btn-primary"
                             checked={this.state.profile_from === 'Line OA'}
                             onChange={this.handleChangeProfile}
                      /> &nbsp;<span style={{marginTop: 6}}>Line OA</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input type="radio"
                             name="profile_from"
                             value="นายหน้า"
                             className="btn btn-primary"
                             checked={this.state.profile_from === 'นายหน้า'}
                             onChange={this.handleChangeProfile}
                      /> &nbsp;<span style={{marginTop: 6}}>นายหน้า</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input type="radio"
                             name="profile_from"
                             value="MarketPlace"
                             className="btn btn-primary"
                             checked={this.state.profile_from === 'MarketPlace'}
                             onChange={this.handleChangeProfile}
                      /> &nbsp;<span style={{marginTop: 6}}>MarketPlace</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input type="radio"
                             name="profile_from"
                             value="Freelance"
                             className="btn btn-primary"
                             checked={this.state.profile_from === 'Freelance'}
                             onChange={this.handleChangeProfile}
                      /> &nbsp;<span style={{marginTop: 6}}>Freelance</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input type="radio"
                             name="profile_from"
                             value="One2Car"
                             className="btn btn-primary"
                             checked={this.state.profile_from === 'One2Car'}
                             onChange={this.handleChangeProfile}
                      /> &nbsp;<span style={{marginTop: 6}}>One2Car</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input type="radio"
                             name="profile_from"
                             value="ส่วนตัว"
                             className="btn btn-primary"
                             checked={this.state.profile_from === 'ส่วนตัว'}
                             onChange={this.handleChangeProfile}
                      /> &nbsp;<span style={{marginTop: 6}}>ส่วนตัว</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;

                      <input type="radio"
                             name="profile_from"
                             value="Web"
                             className="btn btn-primary"
                             checked={this.state.profile_from === 'Web'}
                             onChange={this.handleChangeProfile}
                      /> &nbsp;<span style={{marginTop: 6}}>Web</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input type="radio"
                             name="profile_from"
                             value="Google"
                             className="btn btn-primary"
                             checked={this.state.profile_from === 'Google'}
                             onChange={this.handleChangeProfile}
                      /> &nbsp;<span style={{marginTop: 6}}>Google</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <input type="radio"
                             name="profile_from"
                             value="แนะนำ"
                             className="btn btn-primary"
                             checked={this.state.profile_from === 'แนะนำ'}
                             onChange={this.handleChangeProfile}
                      /> &nbsp;<span style={{marginTop: 6}}>แนะนำ</span>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                    </div>
                  </div>

                  <div className="row mt-3">
                    <div className="form-group form-inline col-sm-12">
                      <label htmlFor="profile_address form-inline"> ลูกค้ามาจาก(ที่อยู่) :&nbsp;&nbsp;</label>
                      <input type="text"
                             className="form-control form-inline form-check"
                             style={{width: '80%'}}
                             name="profile_address"
                             maxLength={150}
                             placeholder="เช่น วัดเพลง ราชบุรี"
                             value={this.state.profile_address}
                             onChange={this.handleChangeProfile}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group form-inline col-sm-4">
                      <label htmlFor="profile_address form-inline"> F/N :&nbsp;&nbsp;</label>
                      <input type="text"
                             className="form-control form-inline form-check"
                             name="profile_fn1"
                             maxLength={100}
                             placeholder=""
                             value={this.state.profile_fn1}
                             onChange={this.handleChangeProfile}
                      />
                    </div>
                    <div className="form-group form-inline col-sm-4">
                      <label htmlFor="profile_address form-inline"> F/N :&nbsp;&nbsp;</label>
                      <input type="text"
                             className="form-control form-inline form-check"
                             name="profile_fn2"
                             maxLength={100}
                             placeholder=""
                             value={this.state.profile_fn2}
                             onChange={this.handleChangeProfile}
                      />
                    </div>
                    <div className="form-group form-inline col-sm-4">
                      <label htmlFor="profile_address form-inline"> F/N :&nbsp;&nbsp;</label>
                      <input type="text"
                             className="form-control form-inline form-check"
                             name="profile_fn3"
                             maxLength={100}
                             placeholder=""
                             value={this.state.profile_fn3}
                             onChange={this.handleChangeProfile}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-sm-12">
                      <label htmlFor="profile_name">โปรไฟล์ลูกค้า</label>
                      <textarea
                        name="profile_name"
                        className="form-control"
                        value={this.state.profile_name}
                        onChange={this.handleChangeProfile}
                        />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-sm-12">
                      <label htmlFor="profile_ncb1">NCB</label>
                      <input type="text"
                             className="form-control"
                             name="profile_ncb1"
                             placeholder=""
                             maxLength={150}
                             value={this.state.profile_ncb1}
                             onChange={this.handleChangeProfile}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-sm-12">
                      <label htmlFor="profile_guarantor1">ค้ำ 1</label>
                      <input type="text"
                             className="form-control"
                             name="profile_guarantor1"
                             placeholder=""
                             maxLength={150}
                             value={this.state.profile_guarantor1}
                             onChange={this.handleChangeProfile}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-sm-12">
                      <label htmlFor="profile_guarantor_ncb1">ค้ำ 1 NCB</label>
                      <input type="text"
                             className="form-control"
                             name="profile_guarantor_ncb1"
                             placeholder=""
                             maxLength={150}
                             value={this.state.profile_guarantor_ncb1}
                             onChange={this.handleChangeProfile}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-12">
                      <label htmlFor="profile_guarantor2">ค้ำ 2</label>
                      <input type="text"
                             className="form-control"
                             name="profile_guarantor2"
                             placeholder=""
                             maxLength={150}
                             value={this.state.profile_guarantor2}
                             onChange={this.handleChangeProfile}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-sm-12">
                      <label htmlFor="profile_guarantor_ncb2">ค้ำ 2 NCB</label>
                      <input type="text"
                             className="form-control"
                             name="profile_guarantor_ncb2"
                             placeholder=""
                             maxLength={150}
                             value={this.state.profile_guarantor_ncb2}
                             onChange={this.handleChangeProfile}
                      />
                    </div>
                  </div>
                </div>


                 <div className="card-footer text-right">
                    <button type="submit"
                           className={this.state.customer_id===''? 'btn btn-sm btn-danger mr-2': (this.state.profile_id===''? 'btn btn-sm btn-primary mr-2' : 'btn btn-sm btn-warning') }
                           disabled={this.state.customer_id ===''? true : false}
                    >
                     <i className="fa fa-save"/>
                       &nbsp;&nbsp;  {this.state.customer_id===''? 'บันทึกหรือค้นหาลูกค้าก่อน!!!': (this.state.profile_id===''? 'บันทึกโปรไฟล์' : 'แก้ไขโปรไฟล์') }
                    </button>

                   <input
                     type="hidden"
                     name="customer_id"
                     value={this.state.customer_id}
                   />
                 </div>
              </div>
            </div>
        </div>
        </form>

      <form action="" method="post" onSubmit={this.handleOnSubmitCarBook}>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <strong className="text-title">เพิ่มข้อมูลการจองรถ</strong>
              </div>

              <div className="card-block">

                <table>
                  <tbody>

                  <tr>
                    <td style={{width: '50%', verticalAlign: 'top'}} className="text-left">
                      <strong> {this.state.car.car_name} </strong> <br/>
                      <strong> ทะเบียนรถ : {this.state.car.car_license_plate_new}</strong> <br/>
                      <img src={image_url} width="200"  alt="car"/>
                      <CarLeasingView
                        car={this.state.car}
                        carLeasingList={this.state.carLeasingList}
                      />

                      <div className="row mt-2">
                        <div className="col-lg-12">
                          <strong>หมายเหตุการจอง</strong>
                        </div>

                        <br/>
                        <div className="col-lg-12">
                          <ReactQuill theme="snow"
                                      value={this.state.book_comment}
                                      onChange={(val) => {
                                        this.setState({
                                          book_comment: val
                                        })
                                      }}
                          />
                        </div>
                      </div>

                    </td>
                    <td style={{width: '50%', verticalAlign: 'top'}}>
                      <div className="row">
                        <div className="col-lg-12">
                          <strong>รายละเอียดการจอง</strong>
                        </div>

                        <div className="form-group row col-lg-12">
                          <label htmlFor="cost_down" className="col-sm-4 col-form-label text-right">ราคาขาย : </label>
                          <div className="col-sm-8">
                            <input
                              id="cost_car_book"
                              name="cost_car_book"
                              className="form-control hidden"
                              placeholder="ราคาขาย"
                              type="text"
                              value={this.state.cost_car_book}
                              onChange={() => {}}
                            />
                            <NumberFormat
                              className="form-control"
                              thousandSeparator={true}
                              prefix={'฿'}
                              placeholder="ราคาขาย"
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              value={this.state.cost_car_book}
                              allowNegative={false}
                              onValueChange={(event) => {
                                const {value} = event
                                this.setState({cost_car_book: value}, () => this.calculateCarOut())

                              }}
                            />
                          </div>
                        </div>

                        <div className="form-group row col-lg-12">
                          <label htmlFor="cost_down" className="col-sm-4 col-form-label text-right">ราคาไฟแนนท์ : </label>
                          <div className="col-sm-8">
                            <input
                              id="cost_car_finance"
                              name="cost_car_finance"
                              className="form-control hidden"
                              placeholder="ราคาไฟแนนท์"
                              type="text"
                              value={this.state.cost_car_finance}
                              onChange={() => {}}
                            />
                            <NumberFormat
                              className="form-control"
                              thousandSeparator={true}
                              prefix={'฿'}
                              placeholder="ราคาไฟแนนท์"
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              value={this.state.cost_car_finance}
                              allowNegative={false}
                              onValueChange={(event) => {
                                const {value} = event
                                this.setState({cost_car_finance: value}, () => this.calculateCarOut())

                              }}
                            />
                          </div>
                        </div>

                        <div className="form-group row col-lg-12">
                          <label htmlFor="cost_down" className="col-sm-4 col-form-label text-right">เงินดาวน์รถ : </label>
                          <div className="col-sm-8">
                            <input
                              id="cost_down"
                              name="cost_down"
                              className="form-control hidden"
                              placeholder="เงินดาวน์รถ"
                              type="text"
                              value={this.state.cost_down}
                              onChange={() => {}}
                            />
                            <NumberFormat
                              className="form-control"
                              thousandSeparator={true}
                              prefix={'฿'}
                              placeholder="เงินดาวน์รถ"
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              value={this.state.cost_down}
                              allowNegative={false}
                              onValueChange={(event) => {
                                const {value} = event
                                this.setState({cost_down: value}, () => this.calculateCarOut())

                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group row col-lg-12">
                          <label htmlFor="cost_finance" className="col-sm-4 col-form-label text-right">ค่าโอน+ค่าอากร : </label>
                          <div className="col-sm-8">
                            <input
                              id="cost_finance"
                              name="cost_finance"
                              className="form-control hidden"
                              placeholder="ค่าโอน+ค่าจัด"
                              type="text"
                              value={this.state.cost_finance}
                              onChange={() => {}}
                            />
                            <NumberFormat
                              className="form-control"
                              thousandSeparator={true}
                              prefix={'฿'}
                              placeholder="ค่าโอน+ค่าอากร"
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              value={this.state.cost_finance}
                              allowNegative={false}
                              onValueChange={(values) => {
                                const {value} = values
                                this.setState({cost_finance: value}, () => this.calculateCarOut())

                              }}
                            />
                          </div>
                        </div>
                        {/* ยกเลิก เอาไปรวมกับ cost_finance
                        <div className="form-group row col-lg-12 hidden">
                          <label htmlFor="cost_check" className="col-sm-4 col-form-label text-right">ค่าตรวจ+ค่าจัด(F) : </label>
                          <div className="col-sm-8">
                            <input
                              id="cost_check"
                              name="cost_check"
                              className="form-control hidden"
                              placeholder="ค่าตรวจ+ค่าจัด(F)"
                              type="text"
                              value={this.state.cost_check}
                            />
                            <NumberFormat
                              className="form-control"
                              thousandSeparator={true}
                              prefix={'฿'}
                              placeholder="ค่าตรวจ+ค่าจัด(F)"
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              allowNegative={false}
                              onValueChange={(values) => {
                                const {value} = values
                                this.setState({cost_check: value}, () => this.calculateCarOut())
                              }}
                            />

                          </div>
                        </div> */}

                        <div className="form-group row col-lg-12">
                          <label htmlFor="cost_insurance" className="col-sm-4 col-form-label text-right">
                            ค่าประกันภัยชั้น :
                          </label>
                          <div className="col-sm-8">
                            {/* <input
                              id="cost_insurance_type"
                              name="cost_insurance_type"
                              className="form-control col-sm-2"
                              placeholder="ชนิดประกันภัย"
                              type="text"
                              onChange={this.handleChangeCarReserve}
                              value={this.state.cost_insurance_type}
                            /> */}
                            <select
                              className="form-control mb-1"
                              id="insurance_type_name"
                              name="insurance_type_name"
                              ref="insurance_type_name"
                              onChange={() => {}}
                            >
                              <option value=""> เลือกประเภทประกัน</option>
                              <option value="ประกันแถม"> ประกันแถม</option>
                              {insurance_type_list}
                            </select>
                            <input
                              id="cost_insurance"
                              name="cost_insurance"
                              className="form-control hidden"
                              placeholder="ค่าประกันภัยชั้น"
                              type="text"
                              value={this.state.cost_insurance}
                              onChange={() => {}}
                            />
                            <NumberFormat
                              className="form-control col-sm-10"
                              thousandSeparator={true}
                              prefix={'฿'}
                              placeholder="ค่าประกันภัยชั้น"
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              value={this.state.cost_insurance}
                              allowNegative={false}
                              onValueChange={(values) => {
                                const {value} = values
                                this.setState({cost_insurance: value}, () => this.calculateCarOut())

                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group row col-lg-12">
                          <label htmlFor="cost_car_tax" className="col-sm-4 col-form-label text-right">ค่า พ.ร.บ./ภาษีรถ : </label>
                          <div className="col-sm-8">
                            <input
                              id="cost_car_tax"
                              name="cost_car_tax"
                              className="form-control hidden"
                              placeholder="ค่า พ.ร.บ./ภาษีรถ"
                              type="text"
                              value={this.state.cost_car_tax}
                              onChange={() => {}}
                            />
                            <NumberFormat
                              className="form-control"
                              thousandSeparator={true}
                              prefix={'฿'}
                              placeholder="ค่า พ.ร.บ./ภาษีรถ"
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              value={this.state.cost_car_tax}
                              allowNegative={false}
                              onValueChange={(values) => {
                                const {value} = values
                                this.setState({cost_car_tax: value}, () => this.calculateCarOut())

                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group row col-lg-12">
                          <label htmlFor="cost_car_out" className="col-sm-4 col-form-label text-right">รวมเงินทั้งสิ้น: </label>
                          <div className="col-sm-8">
                            <input
                              id="cost_car_out"
                              name="cost_car_out"
                              className="form-control hidden"
                              placeholder="รวมเงินทั้งสิ้น"
                              type="text"
                              value={this.state.cost_car_out}
                              onChange={() => {}}
                            />
                            <NumberFormat
                              className="form-control text-primary"
                              value={this.state.cost_car_out}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'฿'}/>
                          </div>
                        </div>
                        <div className="form-group row col-lg-12">
                          <label htmlFor="cost_pledge" className="col-sm-4 col-form-label text-right">หัก: เงินมัดจำ: </label>
                          <div className="col-sm-8">
                            <input
                              id="cost_pledge"
                              name="cost_pledge"
                              className="form-control hidden"
                              placeholder="หัก: เงินมัดจำ"
                              type="text"
                              value={this.state.cost_pledge}
                              onChange={() => {}}
                            />
                            <NumberFormat
                              className="form-control"
                              thousandSeparator={true}
                              prefix={'฿'}
                              placeholder="หัก: เงินมัดจำ"
                              min={0}
                              max={1000000000}
                              step={1000}
                              size={10}
                              value={this.state.cost_pledge}
                              allowNegative={false}
                              onValueChange={(values) => {
                                const {value} = values
                                this.setState({
                                  cost_pledge: value,
                                  car_sell_pay_count: value
                                }, () => {
                                  //this.refs.bill_cost_pledge.value = value
                                  this.calculateCarOut()
                                })

                              }}
                            />
                          </div>
                        </div>

                        <div className="form-group row col-lg-12">
                          <label htmlFor="cost_pledge" className="col-sm-4 col-form-label text-right">ยอดที่ต้องชำระ ณ วันออกรถ: </label>
                          <div className="col-sm-8">
                            <input
                              id="cost_car_out_remain"
                              name="cost_car_out_remain"
                              className="form-control hidden"
                              placeholder="ยอดที่ต้องชำระ ณ วันออกรถ"
                              type="text"
                              value={this.state.cost_car_out_remain}
                              onChange={() => {}}
                            />
                            <p className="text-justify text-primary">
                            <NumberFormat
                              className="form-control text-success"
                              value={this.state.cost_car_out_remain}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'฿'}/>
                            </p>
                          </div>
                        </div>

                        <div className="form-group row col-lg-12">
                          <label htmlFor="cost_pledge" className="col-sm-4 col-form-label text-right">ผู้รับจอง : </label>
                          <div className="col-sm-8 has-success">
                            <input type="text"
                                   className="form-control form-inline form-check"
                                   name="booking_by"
                                   maxLength={100}
                                   placeholder="กรอกชื่อเซลล์"
                                   value={this.state.booking_by}
                                   onChange={(e) => {
                                     this.setState({
                                       booking_by : e.target.value
                                     })
                                   }}
                            />
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>

                <div className="card mt-2">
                  <div className="card-header card-sparepart">
                    <strong className="text-title">การชำระเงิน</strong>
                  </div>
                  <table className="table table-bordered table-striped table-md">
                    <thead>
                    <tr>
                      <th className="text-center" width="16%">วันที่</th>
                      <th className="text-center" width="18%">รายการ</th>
                      <th className="text-center" width="15%">จำนวนเงินที่ชำระ</th>
                      <th className="text-center" width="19%">ประเภทการรับชำระ</th>
                      <th className="text-center" width="18%">ธนาคาร</th>
                      <th className="text-center" width="15%">ผู้บันทึกรายการ</th>

                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                      <td className="text-center">
                        <DatePicker
                          selected={this.state.startDatePay}
                          onChange={this.handleDatePay}
                          dateFormat="yyyy-MM-dd"
                          type="text"
                          className="form-control"
                          id="date_pay"
                          name="date_pay"
                          placeholder=""
                        />
                      </td>

                      <td className="text-center">
                        {/*<option>เลือกรายการ</option>*/}
                        <select
                          className="form-control"
                          id="car_sell_list_pay_id"
                          name="car_sell_list_pay_id"
                          ref="car_sell_list_pay_id"
                          value={this.state.car_book_list_pay_selected_id}
                          onChange={this.handleChangeSellListPay}
                        >
                          {car_book_list_pay_list}
                        </select>
                      </td>

                      <td className="text-center">
                        <input type="text"
                               className="form-control hidden"
                               id="car_sell_pay_count"
                               ref="car_sell_pay_count"
                               name="car_sell_pay_count"
                               placeholder=""
                               value={this.state.cost_pledge}
                               onChange={()=>{}}
                        />
                        <NumberFormat
                          className="form-control"
                          thousandSeparator={true}
                          prefix={'฿'}
                          //placeholder="กรอกค่าโอน"
                          value={this.state.cost_pledge}
                          min={0}
                          max={1000000000}
                          step={1000}
                          size={10}
                          allowNegative={false}
                          onValueChange={(values) => {
                             const {value} = values
                             this.setState({cost_pledge: value}, () => {
                               this.calculateCarOut();
                             })
                          }}
                        />
                      </td>

                      <td className="text-center">
                        <select
                          className="form-control"
                          id="sales_type_id"
                          name="sales_type_id"
                          ref="sales_type_id"
                          onChange={this.salesTypeChange.bind(this)}
                        >
                          <option value="">เลือกประเภทการรับชำระ</option>
                          {sales_type_list}
                        </select>
                      </td>

                      <td className="text-center">
                        <select
                          className="form-control"
                          id="bank_id"
                          name="bank_id"
                          ref="bank_id"
                          onChange={this.bankChange.bind(this)}
                        >
                          <option value="">เลือกธนาคาร</option>
                          {bank_pay_list}
                        </select>
                      </td>

                      <td className="text-center">
                        {this.state.user.user}
                      </td>

                      {/*<td >
                       <button type="button"
                       className="btn btn-primary btn-sm btn-block"
                       onClick={this.handleOnClickCarSellBookPay.bind(this)}
                       >
                       <i className="icon-plus"></i></button>
                       </td>*/}
                    </tr>


                    </tbody>
                  </table>

                  <div className="card-footer text-right">
                    <button type="reset"
                            className="btn btn-sm btn-danger mr-2"
                            style={{width: 120}}
                            onClick={this.props.onToggle}
                    >
                      <i className="fa fa-refresh"/> ยกเลิก
                    </button>
                    <button type="submit"
                            className={this.props.book_status==='add'? "btn btn-sm btn-primary": "btn btn-sm btn-warning"}
                            style={{width: 120}}
                    >
                      <i className="fa fa-save"/>
                      &nbsp;
                      {this.props.book_status==='add'? 'บันทึกการจอง' : 'แก้ไขการจอง'}
                    </button>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      </div>
    )
  }
}

const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
  },
  buttonHeight: {
    height: 36,
    paddingTop:0,
    paddingBottom:0,
    display: 'inline',
    marginTop: '-0.35rem'
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarBookForm)
