import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import { APIURL } from '../../config/config';
import axios from 'axios';
import moment from 'moment';
import { AlertSuccess } from '../Alert/Alert';


class SparePartStockOut extends Component {

  constructor (props){
    super(props);
    this.state = {
      actionType: 'บันทึก',
      out_date: new Date(),
      requestor: '',
      use_for: 'ใช้กับรถยนต์',
      sparePartSearchResult: [],
      isDeleteKey: false,
      stockOutItem: [],
      carUseIn: {
        car_id: '',
        car_name: '',
        car_license_plate_new: ''
      },
      carSearchResult: [],
      company_setting: {},
      center: {},
    }

    this.handleOutDate = this.handleOutDate.bind(this);
    this.onChangeUseFor = this.onChangeUseFor.bind(this);
    this.onChangeSearchCar = this.onChangeSearchCar.bind(this);
    this.addStockOutItem = this.addStockOutItem.bind(this);
    this.onChangeSearchSparePart = this.onChangeSearchSparePart.bind(this);
    this.onKeyDownSearchSparePart = this.onKeyDownSearchSparePart.bind(this);
    this.updateStockItem = this.updateStockItem.bind(this);
    this.selectCarUseIn = this.selectCarUseIn.bind(this);
    this.removeStockItem = this.removeStockItem.bind(this);
    this.onSubmitStockOut = this.onSubmitStockOut.bind(this);
    this.loadCompanySetting = this.loadCompanySetting.bind(this);
    this.loadCenter = this.loadCenter.bind(this);
  }

  componentDidMount () {
    this.loadCompanySetting();
    this.loadCenter();
  }

  handleOutDate(date) {
    this.setState({
      out_date: date,
    })
  }

  onChangeUseFor(e) {
    this.setState({
      use_for: e.currentTarget.value,
    })
  }

  onChangeSearchCar(e){
    let search = e.currentTarget.value

    if(search==='' || search.length <2) {
      if (search === '') {
        this.setState({
          carSearchResult: []
        });
      }
      return
    }

    axios.get(APIURL+'/stock_out/search/car/'+search)
      .then(res => {
        if(res.status===200){
          this.setState({
            carSearchResult: res.data
          });
        }
      })
  }

  onChangeSearchSparePart(e) {
    var search_text = e.currentTarget.value

    if (this.state.isDeleteKey) {
      return;
    }

    if(this.state.sparePartSearchResult.length === 1){
      return;
    }

    axios.get(APIURL+'/spare_part/search/'+search_text).then(res => {
      if(res.status === 200) {
        this.setState({
          sparePartSearchResult: res.data
        });
      }
    })
  }

  onKeyDownSearchSparePart(e) {
    if (e.keyCode === 8) {
      if(e.currentTarget.value.length <=3){
        this.setState({
          isDeleteKey: true,
          sparePartSearchResult: []
        });
      }else {
        this.setState({
          isDeleteKey: true
        });
      }
    }else{
      this.setState({
        isDeleteKey: false
      });
    }
  }

  addStockOutItem(sparePartItem) {
    let stockInItem = this.state.stockOutItem;

    // price , total
    let quantity = 1;
    let newItem = {
      id: sparePartItem.id,
      name: sparePartItem.name,
      price: sparePartItem.price,
      quantity: quantity,
      unit: sparePartItem.unit,
      stock_in: sparePartItem.quantity_in,
      total: quantity * parseFloat(sparePartItem.price)
    }

    const existItem = this.state.stockOutItem.filter(item => item.id === sparePartItem.id)

    if (existItem.length===0) {
      stockInItem.push(newItem)
      this.setState({
        stockOutItem: stockInItem,
        sparePartSearchResult: [],
      },() => {
        this.refs.search_spare_part_out.value=''
      });
    } else {
      let stockInItem = this.state.stockOutItem.map(item => item.id === sparePartItem.id ? {...item, quantity: item.quantity + 1} : item)
      this.setState({
        stockOutItem: stockInItem,
        sparePartSearchResult: [],
      },() => {
        this.refs.search_spare_part_out.value=''
      });
    }
  }

  updateStockItem(event,itemStock){
    let quantity = isNaN(parseInt(event.currentTarget.value, 10)) ? '' : parseInt(event.currentTarget.value, 10)

    let stockOutItem = this.state.stockOutItem.map(item => {
      // item.id === itemStock.id ? {...item, quantity: quantity} : item
      if(item.id === itemStock.id) {
         if(quantity > itemStock.stock_in){
           alert('สต็อกไม่เพียงพอ กรุณาตรวจสอบ');
           return item;
         }else{
           let quantityTemp = quantity === '' ? 0 : quantity
           let total = quantityTemp * parseFloat(itemStock.price)
           return {...item, quantity: quantity, total: total}
         }
      }else{
        return item;
      }
    })

    this.setState({
      stockOutItem: stockOutItem,
    });
  }

  onSubmitStockOut(e) {
    e.preventDefault();

    if(this.state.requestor === ''){
      alert('กรุณาระบุผู้ขอเบิก');
      return
    }

    if(this.state.use_for === 'ใช้กับรถยนต์'){
      if(this.state.carUseIn.car_id === ''){
        alert('ใช้กับรถยนต์ กรุณาระบุรถที่นำไปใช้งาน')
        return
      }
    }

    if(this.state.stockOutItem.length === 0){
      alert('กรุณาเพิ่มรายการอะไหล่อย่างน้อย 1 รายการ');
      return
    }

    let out_date = this.state.out_date ;
    let user = this.props.user.user;
    let stockOutItem = this.state.stockOutItem;
    let requestor = this.state.requestor;
    let use_for = this.state.use_for;
    let car_id = this.state.carUseIn.car_id;
    let car_name = this.state.carUseIn.car_name;
    let is_save_carrepair = this.state.company_setting.is_save_carrepair
    let center_id = this.state.center.center_id

    let data = {
      out_date: out_date,
      requestor:requestor,
      use_for: use_for,
      car_id: car_id,
      car_name: car_name,
      user: user,
      stockOutItem: stockOutItem,
      is_save_carrepair: is_save_carrepair,
      center_id: center_id
    }

    axios.post(APIURL+'/stock_out', data)
      .then(res => {
        if(res.status === 200) {
          // alert('เบิกอะไหล่เรียบร้อยแล้ว');
          AlertSuccess('เบิกอะไหล่เรียบร้อยแล้ว');
          this.setState({
            stockOutItem: []
          },()=> {
            this.props.onReloadSummary()
            this.props.onToggleTab('1')
          });
        }
      })
  }

  selectCarUseIn(car) {

    let car_sell = car.car_sell_id === null ? '' : ' - ขายแล้ว'

    this.setState({
      carUseIn: {
        car_id: car.car_id,
        car_name: car.car_name,
        car_license_plate_new: car.car_license_plate_new === '' ? car.car_license_plate_old+'(เดิม)'+car_sell : car.car_license_plate_new+car_sell
      },
      carSearchResult: []
    },()=>{
      this.refs.car_search.value = ''
    });
  }

  removeStockItem(itemStock) {
    let stockOutItem = this.state.stockOutItem;
    let indexRemove = stockOutItem.indexOf(itemStock);
    if(indexRemove !== -1) {
      stockOutItem.splice(indexRemove, 1);
      this.setState({
        stockOutItem: stockOutItem,
      });
    }
  }

  // company_setting.is_save_carrepair
  loadCompanySetting() {
    axios.get(APIURL + '/company_setting/1').then(res => {
      let data = res.data
      let company_setting = data.company_setting
      this.setState({
        company_setting: company_setting
      })
    })
  }

  loadCenter() {
    axios.get(APIURL + '/stock_out/center').then(res => {
      let data = res.data[0]
      this.setState({
        center: data
      })
    })
  }

  render() {

    let car_name = this.state.carUseIn.car_name;
    let out_date = this.state.out_date;
    let car_license_plate_new = this.state.carUseIn.car_license_plate_new

    let carSearchResult = this.state.carSearchResult.map((car, index) => {
      let car_license = car.car_license_plate_new === '' ? 'เดิม: '+car.car_license_plate_old : car.car_license_plate_new
      let car_sell = car.car_sell_id === null ? '' : ' - ขายแล้ว'
      return (
        <div className="row col-12"
             key={index}
             onClick={()=>this.selectCarUseIn(car)}
             style={{cursor: 'pointer', width: '300px'}}
        >
          <div className="col-9 text-right">
            {car_license} {car_sell}
          </div>
          <div className="col-3 text-right text-success">
             เลือก
          </div>
        </div>
      )
    });

    let sparePartSearchResult = this.state.sparePartSearchResult.map((sparePartSearch,index) =>{

      if(sparePartSearch.quantity_in===0)
        return null;

      return (
        <div
          className="col-12"
          style={{cursor: 'pointer', height: '30px'}}
          key={index}
          onClick={()=>this.addStockOutItem(sparePartSearch)}
        >{sparePartSearch.name} (สต๊อก : {sparePartSearch.quantity_in})</div>
      )
    });

    let stockOutItemList = this.state.stockOutItem.map((item,index) =>{
      return (
        <tr key={index}>
          <td>{index+1}</td>
          <td>{item.name}</td>
          <td className="text-center">{item.price}</td>
          <td className="text-center" style={{width:'20%'}}>
            <input type="text"
                   className="text-center form-control"
                   value={item.quantity}
                   onChange={(event)=>this.updateStockItem(event,item)}
                   onKeyDown={(e) => {
                     if(e.keyCode === 13) {
                       e.preventDefault();
                     }
                   }}
            />
          </td>
          <td className="text-center" >{item.unit}</td>
          <td className="text-center" >
            <button className="btn btn-sm btn-danger btn-md-width"
                    onClick={()=>this.removeStockItem(item)}
            >
              <i className="fa fa-remove"/> ลบ
            </button>
          </td>
        </tr>
      )
    });

    return (
      <form onSubmit={this.onSubmitStockOut}>
        <div className="row">
          <div className="col-lg-12">
            <div className="card card-accent-warning">
              <div className="card-header card-sparepart">

                <strong className="text-title">เบิกอะไหล่</strong>

                {/*<strong
                  className="text-success float-right"
                  style={{cursor: 'pointer'}}
                >ประวัติการเบิก</strong>*/}

              </div>

              <div className="card-block">
                <div className="row">
                  <div className="form-group ml-3">
                    <label className="pt-2">วันที่เบิก</label>
                  </div>
                  <div className="form-group col-sm-2">
                    <DatePicker
                      name="out_date"
                      selected={this.state.out_date}
                      onChange={this.handleOutDate}
                      dateFormat="yyyy-MM-dd"
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                  </div>

                  <div className="form-group">
                    <label className="pt-2">ผู้ขอเบิก </label>
                  </div>
                  <div className="form-group col-sm-2">
                    <input name="requestor"
                           type="text"
                           className="form-control"
                           placeholder="ผู้ขอเบิก"
                           value={this.state.requestor}
                           onChange={(event)=>this.setState({requestor: event.currentTarget.value})}
                    />
                  </div>
                  <div className="form-group col-sm-4 pt-2">
                    <input type="radio"
                           name="use_for"
                           value="ใช้ในเต็นท์รถ"
                           className="btn btn-primary"
                           defaultChecked={this.state.use_for === 'ใช้ในเต็นท์รถ'}
                           onChange={this.onChangeUseFor}
                    /> &nbsp;<span style={{marginTop: -4}}>ใช้ในเต็นท์รถ</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <input type="radio"
                           name="use_for"
                           value="ใช้กับรถยนต์"
                           className="btn btn-primary"
                           defaultChecked={this.state.use_for === 'ใช้กับรถยนต์'}
                           onChange={this.onChangeUseFor}
                    /> &nbsp;<span style={{marginTop: -4}}>ใช้กับรถยนต์</span>
                  </div>

                  <div className="form-group col-2 text-right">
                    <input
                      ref="car_search"
                      onChange={this.onChangeSearchCar}
                      onKeyDown={this.onChangeSearchCar}
                      placeholder="ค้นทะเบียนรถ"
                      className="form-control"
                      size="16"
                      type="text"
                      disabled={this.state.use_for !== 'ใช้กับรถยนต์'}
                    />
                    <div className="row mt-2 float-right" style={{zIndex: 9999}}>
                      {carSearchResult}
                    </div>
                  </div>
                </div>

                <div className="row pt-3" style={{backgroundColor: '#eceff1'}}>
                  <div className="form-group col-4">
                   วันที่เบิก: {moment(out_date).locale('th').format('LL') }
                  </div>
                  <div className="form-group col-4">
                   ชื่อรถ: {car_name}
                  </div>
                  <div className="form-group col-4">
                    ทะเบียนรถ: {car_license_plate_new}
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="form-group col-sm-1 text-right">
                    <label  >ค้นหาอะไหล่ </label>
                  </div>
                  <div className="form-group col-sm-11">
                    <input
                      ref="search_spare_part_out"
                      onChange={this.onChangeSearchSparePart}
                      onKeyDown={this.onKeyDownSearchSparePart}
                      placeholder="ชื่ออะไหล่"
                      className="form-control"
                      size="16"
                      type="text"/>
                    <div className="row mt-2">
                      {sparePartSearchResult}
                    </div>
                  </div>
                </div>

                {
                  this.state.company_setting.is_save_carrepair === 1 &&
                  this.state.use_for === 'ใช้กับรถยนต์' ? (
                    <>
                       <div className="row">
                         <div className="form-group col-sm-12 text-danger">
                          *** บันทึกการเบิกอะไหล่เป็นค่าใช้จ่ายในการซ่อม
                         </div>
                       </div>
                    </>
                  ) : null
                }

                <div className="row">
                  <div className="col-12">
                    <table className="table table-striped">
                      <thead>
                      <tr>
                        <th>#</th>
                        <th>ชื่ออะไหล่รถ</th>
                        <th className="text-center">ราคาต่อหน่วย</th>
                        <th className="text-center">จำนวน</th>
                        <th className="text-center">หน่วย</th>
                        <th className="text-center">จัดการ</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr className={this.state.stockOutItem.length===0? '': 'hidden'} >
                        <td colSpan={6} className="text-center text-danger">
                          ไม่พบรายการอะไหล่
                        </td>
                      </tr>
                      {stockOutItemList}
                      </tbody>
                    </table>
                  </div>
                </div>


              </div>

              <div className="card-footer text-right">
                <button type="reset"
                        className="btn btn-sm btn-danger mr-2"
                >
                  <i className="fa fa-refresh"/> ยกเลิก
                </button>
                <button type="submit"
                        className={this.state.actionType==="แก้ไข"? "btn btn-sm btn-warning" :"btn btn-sm btn-primary"}
                >
                  <i className={this.state.actionType === "แก้ไข" ? "fa fa-edit" : "fa fa-save"}/>
                  &nbsp;{this.state.actionType}
                </button>
              </div>

            </div>
          </div>
        </div>
      </form>
    )
  }
}

export default SparePartStockOut;