import React, {Component} from 'react'
import {Button} from 'reactstrap'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import NumberFormat from 'react-number-format'
import { APIURL } from '../../config/config'
import pdfMake from 'pdfmake/build/pdfmake'
import Swal from 'sweetalert2'

const SLASH_DMYHMS = 'DD MMM YYYY HH:mm:ss'

class HistoryStockOut extends Component {
  constructor (props){
    super(props)
    this.state = {
      date_start: new Date(),
      date_end: new Date(),
      stockOut: []
    }

    this.handleDateStart = this.handleDateStart.bind(this)
    this.handleDateEnd   = this.handleDateEnd.bind(this)
    this.loadHistoryStockOut   = this.loadHistoryStockOut.bind(this)
    this.onDeleteSparePart   = this.onDeleteSparePart.bind(this)
  }

  componentDidMount() {

    //if(this.props.viewType==='daily') {
      this.loadHistoryStockOut();
    //}

  }
  //
  componentWillReceiveProps(nextProps){
    if(nextProps.reloadSummaryStockOut !== this.props.reloadSummaryStockOut){
      if(nextProps.reloadSummaryStockOut){
        this.loadHistoryStockOut();
      }
    }
  }


  handleDateStart(date){
    this.setState({
      date_start: date
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

  loadHistoryStockOut() {

    if(this.state.date_start > this.state.date_end){
      alert('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let date_start =  moment(this.state.date_start).format('YYYY-MM-DD')
    let date_end   =  moment(this.state.date_end).format('YYYY-MM-DD')
    let url = APIURL+'/stock_out/history/'+date_start+'/'+date_end

    axios.get(url).then(res => {
      if(res.status === 200){
        this.setState({
          stockOut: res.data
        },()=>{
          if(this.props.reloadSummaryStockOut){
            this.props.onReloadSummaryStockOutFinish();
          }
        });
      }
    })

  }

  printHistoryStockOut(stockOut, date_start, date_end) {
    var dateHeader = ''
    if(date_start === date_end){
      dateHeader = moment(this.state.date_start).locale('th').format('LL')
    }else{
      dateHeader = moment(date_start).locale('th').format('LL') + ' ถึง '+ moment(date_end).locale('th').format('LL')
    }

    var headerDoc = 'ประวัติการเบิกอะไหล่วันที่ '+dateHeader

    var tableStockOut = [];
    var tableHeader = [
        { text: '#',                 style: 'textBold' },
        { text: 'วันที่',               style: 'textBold' },
        { text: 'ชื่ออะไหล่รถ [ราคา]',  style: 'textBold' },
        { text: 'จำนวนเบิก',          style: 'textBold' },
        { text: 'เบิกใช้กับ',           style: 'textBold' },
        { text: 'ผู้เบิก',              style: 'textBold' },
    ]

    tableStockOut.push(tableHeader)

    for (let i = 0; i < stockOut.length; i++) {
      let sparePart = stockOut[i]
      let index = i + 1
      let date = moment(sparePart.stock_out_created).locale('th').format(SLASH_DMYHMS)
      let namePrice = sparePart.name+ '['+sparePart.price.toLocaleString()+' บ.]'
      let qtyUnit = sparePart.quantity + ' '+ sparePart.unit
      let useFor = sparePart.use_for+': '+sparePart.car_name+' '+sparePart.car_license
      let requestor = sparePart.requestor

      var tableRow = [
        { text: index,      style: 'textNormal' },
        { text: date,       style: 'textNormal' },
        { text: namePrice,  style: 'textNormal' },
        { text: qtyUnit,    style: 'textNormalCenter' },
        { text: useFor,     style: 'textNormal' },
        { text: requestor,  style: 'textNormal' },
      ]
      tableStockOut.push(tableRow)
    }

    //portrait  landscape
    var docDefinition = {
      pageOrientation: 'portrait',
      pageSize: 'A4',
      pageMargins: [ 56, 30, 56, 30 ],
      content: [
        { text: headerDoc, style: 'textHeader', lineHeight:1.3},
        {
          table: {
            headerRows: 1,
            body: tableStockOut
          }
        }
      ],
      styles: {
        textHeader: {
          fontSize: 18,
          bold: true,
          alignment: 'center'
        },
        textNormal: {
          fontSize: 14
        },
        textNormalCenter: {
          fontSize: 14,
          alignment: 'center'
        },
        textBold: {
          fontSize: 14,
          bold: true,
          alignment: 'center'
        },
      },
      defaultStyle: {
        font: 'THSarabunNew'
      }
    }

    pdfMake.createPdf(docDefinition).open()

  }


  onDeleteSparePart(sparePart) {
    // name: "ล้อแม็ก 20 นิ้ว"
    let name = sparePart.name
    let stock_out_id = sparePart.stock_out_id
    let car_id = sparePart.car_id
    let quantity = sparePart.quantity
    let unit = sparePart.unit
    let car_name = sparePart.car_name
    let spare_part_id = sparePart.spare_part_id
    let stock_out_detail_id = sparePart.stock_out_detail_id

    let title = 'ต้องการลบ '+name+' จำนวน '+quantity +' '+ unit +' ใช่หรือไม่? '
    if (car_id !== null) {
      title += '\n(ระบบจะลบรายการซ่อมพร้อมคืนสต็อกออกจากรถ'+car_name+')'
    }

    Swal.fire({
      title: title,
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {
        let data = {
          stock_out_id: stock_out_id,
          car_id: car_id,
          quantity: quantity,
          spare_part_id: spare_part_id,
          stock_out_detail_id: stock_out_detail_id
        }

        let url = APIURL+'/stock_out/delete/'
        axios.post(url, data).then(res => {
          if(res.status === 200){
            this.loadHistoryStockOut();
          }
        })

      }
    })
  }

  render() {


    let stockOutList = this.state.stockOut.map((sparePart, index) => {

      //if(sparePart.quantity_out === 0) return null

      return (
        <tr key={index+1}>
          <td className="text-center">{index+1}</td>
          <td className="text-center">{moment(sparePart.stock_out_created).locale('th').format(SLASH_DMYHMS)}</td>
          <td className="text-center">
            {sparePart.name}
            &nbsp;
            [
            <NumberFormat
              displayType={'text'}
              thousandSeparator={true}
              value={sparePart.price}/>
            &nbsp;บ.
            ]
            </td>
          <td className="text-center">{sparePart.quantity} &nbsp; {sparePart.unit} </td>
          <td className="text-center">
            {sparePart.use_for} : &nbsp;
            {sparePart.car_name} &nbsp; {sparePart.car_license}
          </td>
          <td className="text-center">{sparePart.requestor}</td>
          <td className="text-center">

            {/*<button className="btn btn-warning btn-sm btn-block mt-2"
                    style={{width: 90}}
                    onClick={() => {

                    }}
            >
              <i className="fa fa-pencil"></i> แก้ไข
            </button>*/}

            <button className="btn btn-danger btn-sm btn-block mt-2"
                    style={{width: 90}}
                    onClick={() => this.onDeleteSparePart(sparePart)}
            >
              <i className="fa fa-remove"></i> ลบ
            </button>
          </td>
        </tr>
      )

    })

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-accent-warning">
            <div className="card-header card-sparepart">
              <strong className="text-title">ประวัติการเบิกอะไหล่</strong>
            </div>
            <div className="card-block">
              <div className={this.props.viewType==='daily' ? 'row  mb-2' : 'hidden'}>
                <div className="col-12">
                  <h4> ประวัติการเบิกอะไหล่วันที่ {moment().locale('th').format('LL')} </h4>
                </div>
              </div>
              <div className={this.props.viewType==='range' ? 'row  mb-2' : 'hidden'}>
                  <div className="col-1 text-right pt-1">
                     วันที่เริ่ม
                  </div>
                  <div className="col-2">
                    <DatePicker
                      selected={this.state.date_start}
                      onChange={this.handleDateStart}
                      dateFormat="yyyy-MM-dd"
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                  </div>

                  <div className="col-2 text-right pt-1">
                    <span className="" > วันที่สิ้นสุด </span>
                  </div>
                  <div className="col-2">
                      <DatePicker
                        selected={this.state.date_end}
                        onChange={this.handleDateEnd}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                  </div>
                <div className="col-1 mb-4">
                  <Button color="success" size="sm" onClick={()=>this.loadHistoryStockOut()}>
                    <i className="icon-magnifier"/>&nbsp; ตกลง
                  </Button>
                </div>

                <div className={this.state.stockOut.length===0? "hidden" : "col-1"} >

                  <Button color="info" size="sm"
                          onClick={()=>{
                            let stockOut = this.state.stockOut;
                            let date_start = this.state.date_start;
                            let date_end = this.state.date_end;
                            this.printHistoryStockOut(stockOut,date_start,date_end);
                          }}>
                    <i className="icon-docs"/>&nbsp; พิมพ์
                  </Button>

                  {/*<button type="button"
                          className="btn btn-sm btn-info btn-block"
                          onClick={() => {
                            // console.log(car_sell)
                            //this.printInvoice(car_sell);
                          }}
                  >
                    <i className="icon-docs"></i>&nbsp; พิมพ์
                  </button>*/}

                </div>
              </div>

              <div className={this.props.viewType==='range' ? 'row  mb-2' : 'hidden'}>
                <div className={moment(this.state.date_start).isSame(moment(this.state.date_end), 'date')? "hidden": 'col-12' }>
                  <h4> ประวัติการเบิกอะไหล่วันที่ {moment(this.state.date_start).locale('th').format('LL')}  ถึง {moment(this.state.date_end).locale('th').format('LL')}</h4>
                </div>
                <div className={moment(this.state.date_start).isSame(moment(this.state.date_end), 'date')? "col-12": 'hidden' }>
                  <h4> ประวัติการเบิกอะไหล่วันที่ {moment(this.state.date_start).locale('th').format('LL')} </h4>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <table className="table table-striped">
                    <thead>
                    <tr>
                      <td className="text-center">#</td>
                      <td className="text-center">วันที่</td>
                      <td className="text-center">ชื่ออะไหล่รถ [ราคา]</td>
                      <td className="text-center">จำนวนเบิก</td>
                      <td className="text-center">เบิกใช้กับ</td>
                      <td className="text-center">ผู้เบิก</td>
                      <td className="text-center" style={{width: 90}}>จัดการ</td>
                    </tr>
                    </thead>
                    <tbody>
                    {stockOutList}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default HistoryStockOut;