import React,{Component} from  'react'
import Autosuggest from 'react-autosuggest';
import NumberFormat from 'react-number-format';
import DatePicker from 'react-datepicker'
// import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios'
import { APIURL } from '../../../config/config'
import {connect} from 'react-redux' ;

const suggestionHighlightedDivStyle = { backgroundColor: '#5bc0de' };
const suggestionDivStyle = { padding: '10px'};

const car_repair_name = [
  { id: 'ทำสี', name: 'ทำสี' },
  { id: 'ทำเบาะ', name: 'ทำเบาะ' },
  { id: 'ระบบไฟ', name: 'ระบบไฟ' },
  { id: 'ระบบแอร์', name: 'ระบบแอร์' },
  { id: 'ถอดแก๊ส', name: 'ถอดแก๊ส' },
  { id: 'อุปกรณ์ตกแต่ง', name: 'อุปกรณ์ตกแต่ง' },
  { id: 'อุปกรณ์ตกแต่ง', name: 'อุปกรณ์ตกแต่ง' },
  { id: 'ฟิล์ม', name: 'ฟิล์ม' },
  { id: 'ช่วงล่าง', name: 'ช่วงล่าง' },
  { id: 'ล้อแม๊กซ์', name: 'ล้อแม๊กซ์' },
  { id: 'ยาง', name: 'ยาง' },
];

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = value => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : car_repair_name.filter(lang =>
     lang.name.toLowerCase().slice(0, inputLength) === inputValue
  );
};

// When suggestion is clicked, Autosuggest needs to populate the input
const getSuggestionValue = suggestion => suggestion.name;

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <span>
    {suggestion.name}
  </span>
);

class CarRepairNewForm extends Component{
  constructor (props){
     super(props)
     this.state = {
       date: new Date().toISOString(),
       previousDate: null,
       minDate: null,
       maxDate: null,
       focused: false,
       invalid: false,
       value: '',
       suggestions: [],
       cost: 0,
       // dateCarSend: moment(),
       // dateCarComplete: moment(),
       dateCarSend: new Date(),
       dateCarComplete: new Date(),
       center_list: [],
       user: {},
       car: {},
       car_sell_id: 0,
       w_car_repair_name: false,
       w_car_repair_cost: false,
       w_car_repair_file: false,
       //w_car_repair_note: false,
      // car_repair_name : null
     }

     this.handleDateCarSend = this.handleDateCarSend.bind(this);
     this.handleDateCarComplete = this.handleDateCarComplete.bind(this);
     this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleDateCarSend(date) {
    this.setState({
      dateCarSend: date
    });
  }

  handleDateCarComplete(date) {
    this.setState({
      dateCarComplete: date
    });
  }


  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value)
    });
  };
  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  //load data to select center
  loadCenterData(){
    axios.get(APIURL + '/center')
      .then(res => {
          this.setState({center_list: res.data })
      }).catch(error => {
       // alert("error"+error)
      this.setState({center_list: []})
    })
  }

  componentWillMount () {
    this.setState({
      user: JSON.parse(this.props.user),
      car: this.props.car
    });
  }

  componentDidMount () {
    this.loadCenterData()
    this.loadCarSellByCarId()
  }

  loadCarSellByCarId() {
    axios.get(APIURL + '/car_sell/car/'+this.state.car.car_id)
      .then(res => {
        if(res.data.length > 0) {
          this.setState({
            car_sell_id: res.data[0].car_sell_id
          })
        }else{
          this.setState({car_sell_id: 0 })
        }
      }).catch(error => {
      // alert("error"+error)
      this.setState({car_sell_id: 0})
    })
  }

  handleSubmit = (event) => {
     event.preventDefault();

    let center_id = event.target.center_id.value
    let car_repair_name = event.target.car_repair_name.value
    let car_repair_cost = event.target.car_repair_cost.value
    let car_repair_note = event.target.car_repair_note.value

    if(center_id===''){
      alert('ไม่พบข้อมูลศูนย์ซ่อม เพิ่มข้อมูลที่เมนู "ข้อมูลศูนย์ซ่อมบำรุงรถ"')
      return
    }

    if(car_repair_name===''){
       this.setState({
         w_car_repair_name: true
       });
       return;
    }
    if(car_repair_cost===0){
      this.setState({
        w_car_repair_cost: true
      });
      return;
    }

    //if(!(/\.(gif|jpg|jpeg|tiff|png)$/i).test(filename)){
      //alert(event.target.car_repair_file.files[0].type);
     /* this.setState({
        w_car_repair_file: true
      });
      return; */
    if(event.target.car_repair_file.files.length === 0){
      this.props.onCarRepairSubmit.addNoFile(event)
    }else{
      let filename = event.target.car_repair_file.files[0].name ;
      if(!(/\.(gif|jpg|jpeg|tiff|png)$/i).test(filename)){
        this.setState({
          w_car_repair_file: true
        });
        return;
      }

      this.props.onCarRepairSubmit.add(event)
    }



  }


  render(){

    //const { value, suggestions } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'ชื่อรายการซ่อม',
      value: this.state.value ,
      onChange: this.onChange ,
      type: 'search',
    };

    let center_list = this.state.center_list.map((center,index)=>{
      return( <option key={index} value={center.center_id}>{center.center_name}</option> )
    })

    return (
      <div className="card card-accent-primary ">
        <div className="card-header">
          <strong className="text-title">เพิ่มข้อมูลการซ่อม</strong>
        </div>
        <form
          action="" method="post"
          onSubmit={ this.handleSubmit }
          encType="multipart/form-data"
        >
        <div className="card-block">

          <div className="row">
            <div className="form-group col-md-12">
                <label htmlFor="center_id">ศูนย์ซ่อมบำรุง</label>
                <select
                     className="form-control"
                     id="center_id"
                     name="center_id">
                  {center_list}
                </select>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-12">
              <label htmlFor="center_name">ชื่อรายการซ่อม</label>
              <input type="text"
                     className="form-control hidden"
                     value={this.state.value}
                     name="car_repair_name"
                     id="car_repair_name"
                     placeholder="hidden"
                     onChange={()=>{}}
              />
              <Autosuggest
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                theme={{
                  input: 'form-control',
                  suggestion: suggestionDivStyle,
                  suggestionsList: 'list-unstyled ',
                  suggestionHighlighted: suggestionHighlightedDivStyle
                }}

              />
              {this.state.w_car_repair_name && this.state.value ==='' ? <span  className="help-block text-danger">กรุณากรอกชื่อรายการซ่อม</span>: null }

            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-12">
              <label htmlFor="center_name"> ค่าใช้จ่าย </label>
              <input
                     className="form-control hidden"
                     id="car_repair_cost"
                     name="car_repair_cost"
                     placeholder="hidden"
                     value={this.state.cost}
                     onChange={()=>{}}
              />
              <NumberFormat
                className="form-control"
                thousandSeparator={true}
                prefix={'฿'}
                placeholder="ค่าใช้จ่าย"
                min={0}
                max={1000000000}
                step={1000}
                size={10}
                allowNegative={false}
                onValueChange = {(values) => {
                  const {value} = values;
                  this.setState({cost: value})
                }}
              />
              {this.state.w_car_repair_cost && this.state.cost === 0 ? <span  className="help-block text-danger">กรุณากรอกค่าใช้จ่าย</span>: null }

            </div>
          </div>


          <div className="row">
            <div className="form-group col-md-6">
                <label htmlFor="car_repair_send">วันที่ส่งซ่อม </label><br/>
                <DatePicker
                  selected={this.state.dateCarSend}
                  onChange={this.handleDateCarSend}
                  dateFormat="yyyy-MM-dd"
                  type="text"
                  className="form-control"
                  id="car_repair_send"
                  name="car_repair_send"
                  placeholder="วันที่ส่งซ่อม"
                />
            </div>
            <div className="form-group col-md-6">
                <label htmlFor="car_repair_complete">วันที่แล้วเสร็จ </label><br/>
                <DatePicker
                  selected={this.state.dateCarComplete}
                  onChange={this.handleDateCarComplete}
                  dateFormat="yyyy-MM-dd"
                  type="text"
                  className="form-control"
                  id="car_repair_complete"
                  name="car_repair_complete"
                  placeholder="วันที่แล้วเสร็จ"
                />
              </div>
          </div>

          <div className="row">
            <div className="form-group col-md-12">
              <label htmlFor="car_repair_file">เอกสารแนบ </label>
              <input type="file"
                     id="car_repair_file"
                     name="car_repair_file"
                     accept="image/*"
                     className="form-control"
                    onChange={(e)=>{
                       const { target } = e
                        if(target.value.length > 0){
                          this.setState({
                            w_car_repair_file: false
                          });
                        } else {
                          //target.reset();
                          this.setState({
                            w_car_repair_file: true
                          });
                        }

                    }}
              />
              { this.state.w_car_repair_file ? <span  className="help-block text-danger">กรุณาเลือกเอกสารแนบ</span>: null }

            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-12">
              <label htmlFor="car_repair_note">หมายเหตุ</label>
              <input type="text"
                     className="form-control"
                     //value={this.state.car_repair_note}
                     name="car_repair_note"
                     id="car_repair_note"
                     placeholder="ข้อมูลการซ่อมเพิ่มเติม"/>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-12">
              <label htmlFor="car_repair_type">สถานะ:  {this.state.car_sell_id === 0 ? 'ซ่อมปรับแต่ง' : 'ซ่อมเคลม' }</label>

              <input type="text"
                     className="form-control hidden"
                     value={this.state.car_sell_id === 0 ? 'ซ่อมปรับแต่ง' : 'ซ่อมเคลม' }
                     id="car_repair_type"
                     name="car_repair_type"
                     placeholder="hidden"
                     onChange={()=>{}}
              />

            </div>
          </div>

        </div>
        <div className="card-footer text-right">
          <button type="reset"
                   onClick={this.props.onCarRepairSubmit.cancel}
                   className="btn btn-sm btn-danger mr-2">
              <i className="fa fa-refresh"/> ยกเลิก
          </button>
          <button type="submit"
                  className="btn btn-sm btn-primary">
               <i className="fa fa-save"/> บันทึก
          </button>

          <input type="hidden" id="car_id" name="car_id" value={this.state.car.car_id}/>

        </div>
        </form>
      </div>
    )
  }
}

/*const CustomControl = createReactClass({
  displayName: 'CustomControl',

  render() {
    const {
      value,
      placeholder,
      ...rest,
    } = this.props;

    return <Button {...rest}>{value || placeholder}</Button>;
  },
});*/

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CarRepairNewForm);
