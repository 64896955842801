import React, { Component } from 'react';
// import { PORT } from '../../../config/config'

// var port = window.env.PORT; yarn upgrade

const port = window.env.PORT;

class CarEditStatus extends Component{

  render(){
    let car = this.props.car
    return(
      <div className="card card-accent-info">
        <div className="card-header">
          <strong className="text-title">แก้ไขสถานะรถ</strong>
        </div>

        {Number(port) === 9034 ?
            <div className="card-block">
              <div className="row">
                <div className="form-group col-md-12 h5">
                  {car.car_name}
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 h6">
                  สถานะปัจจุบัน : <span className="badge"
                                        style={{backgroundColor: car.car_status_color}} >{car.car_status_name}</span>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#cfcfcf', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 1)
                          }}
                  >
                    {
                      'กำลังมา'
                    }
                  </button>
                </div>
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#f7c407', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 2)
                          }}
                  >
                    {
                      'มาใหม่'
                    }
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#f76060', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 3)
                          }}
                  >

                    {
                      'กำลังแต่ง'
                    }

                  </button>
                </div>
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#1d9ed3', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 6)
                          }}
                  >
                    พร้อมขาย
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#e5703d', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 7)
                          }}
                  >
                    ติดจอง
                  </button>
                </div>

                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#4285f4', width: 230, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 10)
                          }}
                  >
                    อยู่ระหว่างจัดไฟแนนซ์
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#9dc183', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 12)
                          }}
                  >
                    ไฟแนนซ์อนุมัติ
                  </button>
                </div>

                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#FF9966', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 14)
                          }}
                  >
                    รถรอซื้อเข้า
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#FFCC99', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 15)
                          }}
                  >
                    ฝากตรวจแล้ว
                  </button>
                </div>

                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#FFCC66', width: 230, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 16)
                          }}
                  >
                    ฝากตรวจ + รอใบวิศวะ
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#CCCC33', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 17)
                          }}
                  >
                    แจ้งย้าย
                  </button>
                </div>

                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#CC6699', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 18)
                          }}
                  >
                    รอรับเข้า
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#66FF00', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 19)
                          }}
                  >
                    โอนแล้ว
                  </button>
                </div>
              </div>

            </div>

            :

            <div className="card-block">
              <div className="row">
                <div className="form-group col-md-12 h5">
                  {car.car_name}
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 h6">
                  สถานะปัจจุบัน : <span className="badge"
                                        style={{backgroundColor: car.car_status_color}} >{car.car_status_name}</span>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#cfcfcf', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 1)
                          }}
                  >
                    {
                      'กำลังมา'
                    }
                  </button>
                </div>
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#f7c407', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 2)
                          }}
                  >
                    {
                      'มาใหม่'
                    }
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#f76060', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 3)
                          }}
                  >

                    {
                      'กำลังแต่ง'
                    }

                  </button>
                </div>
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#1d9ed3', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 6)
                          }}
                  >
                    พร้อมขาย
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#e5703d', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 7)
                          }}
                  >
                    ติดจอง
                  </button>
                </div>

                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#4285f4', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 10)
                          }}
                  >
                    อยู่ระหว่างจัดไฟแนนซ์
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-6 text-center">
                  <button type="reset"
                          className="btn btn-lg"
                          style={{backgroundColor: '#9dc183', width: 200, height:50}}
                          onClick={()=>{
                            this.props.onHandle.change(car.car_id, 12)
                          }}
                  >
                    ไฟแนนซ์อนุมัติ
                  </button>
                </div>
              </div>

            </div>
        }


          <div className="card-footer text-right">
            <button type="reset"
                    onClick={this.props.onHandle.cancel}
                    className="btn btn-sm btn-danger">
              <i className="fa fa-refresh"></i> ยกเลิก
            </button>
            <button type="submit"
                    className="btn btn-sm btn-primary hidden">
              <i className="fa fa-save"></i> บันทึก
            </button>

            <input type="hidden" id="car_id" name="car_id" value={this.props.car.car_id}/>

          </div>
      </div>
    )
  }
}

export default CarEditStatus
