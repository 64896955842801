// import React, { Component } from 'react'
import Swal from 'sweetalert2'

const timer = 2000

export const AlertWarning = (titleText) => (
  Swal.fire({
    title: titleText,
    icon: 'warning',
    showConfirmButton: false,
    timer: timer
  })
)

export const AlertError = (titleText) => (
  Swal.fire({
    title: titleText,
    icon: 'error',
    showConfirmButton: false,
    timer: timer
  })
)

export const AlertSuccess = (titleText) => (
  Swal.fire({
    title: titleText,
    icon: 'success',
    showConfirmButton: false,
    timer: timer
  })
)

