import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { Redirect, Link } from 'react-router-dom';
import moment from 'moment';
import { PAGE10, BASE_DIR, IMAGE_FULL_URL, IMAGE_NOPHOTO_URL } from '../../../config/config'
import CarLeasingView from '../CarLeasingView';
import { CarUtil } from '../../../utils/carUtil';
import { checkGroupPermission } from '../../../services/grouppermission'
import CarDepositRefund from "../CarDepositRefund/CarDepositRefund";

const port = Number(window.env.PORT);

class CarRow extends Component {
  constructor (props) {
    super(props);
    this.state = {
      'btnClick': '',
      'carSelect': 0,
      car_id: '',
      upload_code: '',
      carcalculate: false,
      carLeasingList: [],
      cars: [],
      car_explain: {},
      page: 0,
      count: 0,
      width: 0,
      height: 0,
      amount_refund : 0
    }

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.renderDeposit = this.renderDeposit.bind(this);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  toQueryString (paramsObject) {
    return Object
      .keys(paramsObject)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
      .join('&')
  }

  handleOnClick (action, car, car_sell) {

    let search3 = this.toQueryString(car)

    this.setState({
      'btnClick': action,
      'carSelect': search3,
      'car_id': car.car_id,
      'upload_code': car.car_upload_code,
    })

    if (action === 'car-edit-status') {
      this.props.onCarStatusEdit(car)
    } else if (action === 'car-calculate') {
      this.props.onCarCalculate(car)
    } else if (action === 'car-share') {
      //alert(car.car_share)
      this.props.onCarShare(car)
    } else if (action === 'car-delete') {
      this.props.onCarDelete(car)
    } else if (action === 'car-leasing'){
      this.props.onCarLeasing(car)
    } else if (action === 'car-edit-sell-price') {
      this.props.onCarEditSellPrice(car)
    } /*else if (action === 'car_deposit_refund'){
      this.props.onCarDepositRefund(car)
    }*/
  }

  componentDidMount() {
    this.setState({
      cars: this.props.cars,
      page: this.props.page,
      count: this.props.count
    });

    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillReceiveProps(nextProps) {

    if(nextProps.carLeasingList.length === 0 && this.props.carLeasingList.length === 0 ) {
      this.setState({carLeasingList: []});
    }else if(nextProps.carLeasingList !== this.props.carLeasingList) { // && this.props.carLeasingList.length === 0
      if (nextProps.carLeasingList.length > 0 ) {
        this.setState({
          carLeasingList: nextProps.carLeasingList
        })
      }else{
        this.setState({carLeasingList: []});
      }
    }

    if(nextProps.cars !== this.props.cars) {
        this.setState({
          cars: nextProps.cars,
          page: nextProps.page,
          count: nextProps.count
        });
    }
  }

  /* shouldComponentUpdate(nextProps, nextState) {
    console.log('shouldComponentUpdate CarRow')
    console.log(nextProps.carLeasingList)
    return this.props.carLeasingList !== nextProps.carLeasingList || nextState.input !== this.state.input
  } */

   /* carExpenseCal(car) {

  } */

  renderDeposit(car, finance_name) {
    if(port !== '9014' || port !== 9014) {
      return ''
    } else {
      return (
        <>
          <br/>
          {
            car.deposit_registration_id === undefined ? '' : <div>
              มัดจำเล่มทะเบียน: { CarUtil.convertDepositRegistration(car.deposit_registration_id) } <br/>
            </div>
          }
          {
            car.receipt_date === undefined ? '': <div>
              วันที่รับเล่ม: { car.receipt_date !==null  && car.receipt_date!=='0000-00-00'? moment(car.receipt_date).locale('th').format('LL') : '-'}  <br/>
            </div>
          }
          {
            car.liciense_status_id === undefined ? '' : <div>
              สถานะเล่ม: {CarUtil.convertLicenseIdToName(car.liciense_status_id)}  <br/>
            </div>
          }
          {
            car.finance_id === undefined ? '' : <div>
              ไฟแนนซ์รอเล่มทะเบียน: {finance_name.length === 1 ?  finance_name[0].bank_name : '-'}
            </div>
          }
        </>
      )
    }
  }

  render () {

    let banks = this.props.banks;
    let target = '';

    if (this.state.btnClick === 'car-repair') {
       target = '/car-repair/new?car_id=' + this.state.car_id
      return <Redirect push to={target}/>
    } else if (this.state.btnClick === 'car-detail') {
      // car-buy-contract
    } else if (this.state.btnClick === 'car-detail') {
      target = '/cars/detail/?car_id=' + this.state.car_id
      target += '&upload_code=' + this.state.upload_code
      // return <Redirect push to={target}/>
      // console.log(target)
      return <Redirect push to={target} target={"_blank"}/>
    } else if (this.state.btnClick === 'car-buy') {
       target = '/sales/buy/?car_id=' + this.state.car_id
      return <Redirect push to={target}/>
    } else if (this.state.btnClick === 'car-book') {
       target = '/cars/book/?car_id=' + this.state.car_id
      return <Redirect push to={target}/>
    } else if (this.state.btnClick === 'car-book-cancel') {
       target = '/cars/book-cancel/?car_id=' + this.state.car_id
      return <Redirect push to={target}/>
    } else if (this.state.btnClick === 'car-finance') {
      target = '/finance-pending/add/?car_id=' + this.state.car_id
      return <Redirect push to={target}/>
    }

    let car_all = this.state.count
    let page = this.state.page
    let start_row = 0

    if(car_all > 0  && page > 0){
      start_row = Number(page-1) * PAGE10
    }

    let tbodyList = this.state.cars.map((car, index) => {

      let order = Number(car_all-start_row)-index // 16
      let car_name = car.car_name;
      let car_date_register = "วันที่จดทะเบียน: " + (car.car_date_regis != null && car.car_date_regis !== '0000-00-00' ?  moment(car.car_date_regis).locale('th').format('LL'): '-')
      let vat_buy = car.vat_buy === undefined ? 0 : (car.vat_buy === '' ? 0 : parseFloat(car.vat_buy))
      let withholding = car.withholding === undefined ? 0 : (car.withholding === '' ? 0 : parseFloat(car.withholding))

      let finance_name = []
      if (car.finance_id !== undefined) {
        finance_name = banks.filter((bank) => {
            return bank.bank_id === car.finance_id
          }
        )
      }

      if(BASE_DIR === 'pisanyontrakan') {
        var car_year_name = car.car_year_name
        // var car_date_regis = moment(car.car_date_regis).format('YYYY');
        // car_date_regis
        // car_name = car.car_brand_name + " " + car.car_sub_model_name + " ปี " + car_date_regis
        // car_name += " "+ car_date_regis; //mo 1 Feb 2020
         car_date_register = "รถปี ค.ศ. "+ car_year_name;
      }

      let image_url = ''
      if (car.car_upload_filename !== null) {
        if (car.car_upload_filename.includes('https')) {
          image_url = car.car_upload_filename
        } else {
          image_url = IMAGE_FULL_URL + car.car_upload_filename
        }
      } else {
        image_url = IMAGE_NOPHOTO_URL
      }
      // console.log(car)
      // TODx: จำนวนวันรถจอด
      let day_stay = '-'
      let car_date_in = moment(car.car_date_in);
      let current_date = moment(new Date());
      day_stay = current_date.diff(car_date_in, 'days');

      let width = this.state.width
      let imgWidth = ''
      if ( width >= 1340) {
        imgWidth = '320px'
      } else if (width >= 1024) {
        imgWidth = '180px'
      } else if (width >= 980) {
        imgWidth = '160px'
      } else {
        imgWidth = '140px'
      }

      let profit = car.car_sale_price - (car.car_cost + car.car_cost_repair + car.car_cost_other + car.car_cost_expenses_other + car.car_cost_commissions + car.car_cost_act + car.car_cost_tax + vat_buy)

      return (
        <tr className="text-center" key={index}>
          <td>{order}</td>
          <td>
            <div className="row">
              <div className="col-md-12 mt-2">
                <img src={image_url}
                     style={{'cursor' : 'pointer', 'width': imgWidth, 'height': 'auto'}}
                      alt="car"/>
              </div>
            </div>
            <div className="row hidden-md">
              <div className="col col-sm-12 col-md-12 col-lg-12 mt-2">
                <button type="button" onClick={() => this.handleOnClick('car-calculate', car)}
                        className="btn btn-success btn-sm btn-block">
                  <i className="icon-calculator"></i> ค่างวด
                </button>
              </div>
              <div className="col col-sm-12 col-md-12 col-lg-12 mt-2">
                <button type="button" onClick={() => this.handleOnClick('car-share', car)}
                        className="btn btn-primary btn-sm btn-block ">
                  <i className="icon-share"></i> แชร์
                </button>
              </div>
            </div>

            <div className="row  text-center pt-2">
              <div className="form-group col-6">
                {/*<label htmlFor="liciense_status_id">สถานะเล่ม</label>*/}
                {
                  port === '9021' && this.props.user_type === 'sale' ? (
                    <label htmlFor="">
                      {CarUtil.convertLicenseIdToName(car.liciense_status_id)}
                    </label>
                  ) : (<select className="form-control"
                                                                                     id="liciense_status_id"
                                                                                     name="liciense_status_id"
                                                                                     value={car.liciense_status_id}
                                                                                     onChange={(e) => this.props.onCarLicienseStatusIdChange(e, car.car_id)}
                  >
                    <option value="0">--ไม่ระบุ--</option>
                    <option value="1">มีเล่มทะเบียน</option>
                    <option value="2">ยังไม่ปิดบัญชี</option>
                    <option value="3">รอเล่มปิดบัญชี</option>
                    <option value="4">มีเล่มอยู่เต็นท์</option>
                    <option value="5">ไม่มีเล่ม</option>
                  </select>)
                }

              </div>

              <div className="form-group col-6">
                {/*<label htmlFor="person_vat_id">สถานะเล่ม</label>*/}
                {
                  port === '9021' && this.props.user_type === 'sale' ?
                    <label htmlFor="person_vat_id">
                      {CarUtil.convertVatPersonToName(car.person_vat_id)}
                    </label> :
                    (
                      <select className="form-control"
                              id="person_vat_id"
                              name="person_vat_id"
                              value={car.person_vat_id}
                              onChange={(e) => this.props.onCarPersonVatIdChange(e, car.car_id)}
                      >
                        <option value="0">--บุคคล/vat--</option>
                        <option value="1">บุคคล</option>
                        <option value="2">vat</option>
                      </select>
                    )
                }

              </div>
            </div>

            {/*TODO : มัดจำเล่ม รอรับเงินคืน*/}
            {
              checkGroupPermission(108, this.props.grouppermissions).viewed ? (
                  <CarDepositRefund
                    car = {car}
                    grouppermissions = {this.props.grouppermissions}
                  />
              ) :
                  null
            }

            <div className="row">
              <div className="col col-sm-12 col-md-12 col-lg-12 mt-2 text-left">
                <textarea
                  id="car_explain"
                  name="car_explain"
                  rows={3}
                  value={car.car_explain}
                  onChange={(e) => this.props.onCarExplainFormEdit(e, car.car_id)}
                  placeholder="หมายเหตุ"
                >
                </textarea>
                <br/>
                <button
                  className={car.car_edit? 'btn btn-sm btn-primary ' : 'hidden'}
                  onClick={()=> this.props.onCarExplainUpdate(car.car_id, car.car_explain )}
                >
                  บันทึก
                </button>
                <div className={car.car_save? 'fadeOut' : 'hidden'}><span className="text-success"> บันทึกเรียบแล้วแล้ว </span></div>
              </div>

              <div className="row">
                <div className="col col-sm-12 col-md-12 col-lg-12 mt-2 ">
                  {
                    port === '9021' && this.props.user_type === 'sale' ? null : (
                      <strong>
                        ซื้อเข้า {moment(car.car_date_in).format('DD-MM-YYYY')}
                        <br/>(จอด {day_stay} วัน)
                        &nbsp;
                        {
                          day_stay > 90 ?
                            <span className="badge blink" style={{backgroundColor: '#f86c6b'}}>
                              จอดเกิน 3 เดือน
                            </span>
                            : null
                        }
                      </strong>
                    )
                  }

                  <div className="col col-sm-12 col-md-12 col-lg-12 mt-2 text-danger">
                    <strong> ป้ายแดง: {car.plate_red === ''? '-' : car.plate_red} </strong>
                  </div>

                  <br/>
                  <strong>แก้ไขล่าสุด: {moment(car.updated_at).format('DD-MM-YYYY HH:mm:ss')}</strong> <br/>

                </div>
              </div>

              <div className="row text-white" style={{opacity: 0.1}}>
                .....................
              </div>
            </div>
          </td>

          <td className="text-center">
            <strong>{car_name}
              &nbsp;&nbsp;
              <span className="badge"
                    style={{backgroundColor: car.car_status_color}}>{car.car_status_name}
            </span>
            </strong>
            <br/>
            {
              car.car_license_plate_new === '' ? null : (
                <>
                  <strong> ทะเบียนรถ : {car.car_license_plate_new}</strong>
                  &nbsp; <strong>จ.{car.province_name_new}</strong>
                </>
              )
            }

            {
              car.car_license_plate_new !== '' && car.car_license_plate_old !== '' && car.car_license_plate_new !== car.car_license_plate_old ? <br/> : null
            }

            {
              car.car_license_plate_new !== car.car_license_plate_old && car.car_license_plate_old !== '' ? (
                  <>
                    <strong> &nbsp;&nbsp; ทะเบียนเดิม : {car.car_license_plate_old} &nbsp;จ.{car.province_name_old}</strong>
                  </>
                )
               : null
            }

            {
              car.car_share === 'show' ? <div>
                <br/>
                <img src={'img/if_world.png'} style={{width: 16}}
                     className={car.car_share === 'show' ? '' : 'hidden'}
                     alt="share status"
                />
              </div> : null
            }
            <br/>
            <strong>{car_date_register} </strong>
            &nbsp;&nbsp;
            {
              car.park_location !== car.branch_name ?
                <span className="badge mt-1"
                      style={{backgroundColor: '#78c05d'}}
                >
                  รถจอด : {car.park_location}
                </span> : null
            }
            <br/>
            {
              this.props.branch.length > 1 ? (
                <span className="badge mt-1"
                      style={{backgroundColor: '#eeb877'}}
                >
                  สาขา : {car.branch_name}
                </span>
              ) : null
            }

            {this.renderDeposit(car, finance_name)}

            <center>
            {/*
             ส้ม eeb877 , เขียว 78c05d
              <CarLeasingView
                car={car}
                carLeasingList={this.state.carLeasingList.length !== 0 && this.state.carLeasingList[0].car_id===car.car_id ? this.state.carLeasingList : []} />
            */}
                <CarLeasingView
                  car={car}
                  carLeasingList={this.state.carLeasingList}
                  user_type = {this.props.user_type}
                 />

              {
                (port === '9021' && this.props.user_type === 'sale') || (port === 9036 && this.props.user_type === 'sale') ? null : (
                  <button type="button"
                          style={{marginTop: 8}}
                          onClick={() => this.handleOnClick('car-leasing', car)}
                          className="btn btn-outline-danger btn-sm btn-block w-50">
                    <i className="icon-diamond"></i> ยอดจัด
                  </button>
                )
              }
            </center>

          </td>
          {/*<td className={this.props.user_type === 'admin' ? 'text-center' : 'hidden'}>
            <NumberFormat
            value={car.car_cost} displayType={'text'} thousandSeparator={true} prefix={'฿'}/>
          </td>*/}
          <td className={this.props.user_type === 'technician' ? 'hidden' : ''} >

              <table className="table2">
              <tbody>
                <tr className={this.props.user_type === 'admin' || (this.props.user_type === 'manager' && port === '9010') ? 'text-primary' : 'hidden'}>
                  <td className="text-left">ทุนซื้อเข้า :</td>
                  <td className="text-left">
                    <NumberFormat
                      value={car.car_cost}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'฿'}
                    />
                  </td>
                </tr>
                <tr className={(this.props.user_type === 'admin' && this.props.sum_vat_buy > 0)  || (this.props.user_type === 'manager' && port === '9010') ? 'text-primary' : 'hidden'}>
                  <td className="text-left">ภาษีซื้อ (Vat ซื้อ) :</td>
                  <td className="text-left">
                    <NumberFormat
                      value={vat_buy}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'฿'}
                    />
                  </td>
                </tr>
                <tr className={this.props.user_type === 'admin' || (this.props.user_type === 'manager' && port === '9010') ? 'text-primary' : 'hidden'}>
                  <td className="text-left">ทุนซ่อม/แต่ง :</td>
                  <td className="text-left">
                    <NumberFormat
                      value={car.car_cost_repair}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'฿'}
                    />
                  </td>
                </tr>
                <tr className={this.props.user_type === 'admin' || (this.props.user_type === 'manager' && port === '9010') ? 'text-primary' : 'hidden'}>
                  <td className="text-left">ทุนอื่นๆ :</td>
                  <td className="text-left">
                    <NumberFormat
                      value={car.car_cost_other + car.car_cost_expenses_other+car.car_cost_commissions + car.car_cost_act + car.car_cost_tax}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'฿'}
                    />
                  </td>
                </tr>

                <tr className={this.props.user_type === 'admin' || (this.props.user_type === 'manager' && port === '9010') ? 'text-primary' : 'hidden'}>
                  <td className="text-left">รวมทุน :</td>
                  <td className="text-left">
                    <NumberFormat
                      value={car.car_cost + car.car_cost_repair + car.car_cost_other + car.car_cost_expenses_other+car.car_cost_commissions + car.car_cost_act + car.car_cost_tax + vat_buy}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'฿'}
                    />
                  </td>
                </tr>

                <tr>
                  <td className="text-left">ราคาขาย :</td>
                  <td className="text-left">
                    <NumberFormat
                      value={car.car_sale_price}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'฿'}
                    />
                  </td>
                </tr>

                <tr className={this.props.user_type === 'admin' || (this.props.user_type === 'manager' && port === '9010') ? 'text-success' : 'hidden'}>
                  <td className="text-left">กำไร :</td>
                  <td className="text-left">
                    <NumberFormat
                      value={profit.toFixed(2)}
                      displayType={'text'}
                      thousandSeparator={true}
                      prefix={'฿'}
                    />
                  </td>
                </tr>

                <tr className={(this.props.user_type === 'admin' && this.props.sum_withholding > 0)  || (this.props.user_type === 'manager' && port === '9010') ? 'text-primary' : 'hidden'}>
                  <td className="text-left text-gray-dark">ภาษีหัก ณ ที่จ่าย (ซื้อเข้า) :</td>
                  <td className="text-left text-gray-dark">
                    <NumberFormat
                        value={withholding}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'฿'}
                    />
                  </td>
                </tr>

              </tbody>
              </table>

          </td>
          {/*
          <CarRowExpense car_id={car.car_id}/>

          <td className={this.props.user_type === 'admin' ? 'text-center' : 'hidden'}>
            <NumberFormat
            value={car.car_net_profit} displayType={'text'} thousandSeparator={true} prefix={'฿'}/>
          </td>
          <td>

          </td>*/}

          <td>

            {/*<button onClick={() => this.handleOnClick('car-detail', car)} type="button"
                    className="btn btn-info btn-sm btn-block"><i className="icon-list">
              &nbsp;รายละเอียดรถ</i>
            </button>*/}

            {
              port === '9021' && this.props.user_type === 'sale' ? (
                <>
                  <button onClick={() => {}} type="button"
                          className={this.props.user_type === 'technician' ? 'hidden' : 'btn btn-info btn-sm btn-block'}
                  >
                    <Link to={'/cars/detail/?car_id='+car.car_id+'&upload_code='+car.car_upload_code}
                          target='_blank'
                          style={{color: 'white'}}
                    >
                      <i className="icon-list">
                        &nbsp;รายละเอียดรถ</i>
                    </Link>
                  </button>


                  <button onClick={() => this.handleOnClick('car-book', car)} type="button"
                          className={this.props.user_type === 'technician' ? 'hidden' : 'btn btn-info btn-sm btn-block'}
                  >
                    <i className="icon-star">
                      &nbsp;จองรถคันนี้</i>
                  </button>

                  <button onClick={() => this.handleOnClick('car-buy', car)} type="button"
                          className={this.props.user_type === 'technician' ? 'hidden' : 'btn btn-success btn-sm btn-block'}
                  ><i className="icon-trophy">
                    &nbsp;ขายรถคันนี้</i>
                  </button>

                </>
              ) : (
                <>

                  {
                    checkGroupPermission(18, this.props.grouppermissions).viewed ? (
                      <button onClick={() => {}} type="button"
                              className={'btn btn-info btn-sm btn-block'}
                      >
                        <Link to={'/cars/detail/?car_id='+car.car_id+'&upload_code='+car.car_upload_code}
                              target='_blank'
                              style={{color: 'white'}}
                        >
                          <i className="icon-list">
                            &nbsp;รายละเอียดรถ</i>
                        </Link>
                      </button>
                    ) : null
                  }

                  {
                    checkGroupPermission(26, this.props.grouppermissions).created ? (
                      <button onClick={() => {}} type="button"
                              className={'btn btn-secondary btn-sm btn-block'}
                             /* onMouseOver={(e) => {
                                e.target.style.background = 'gray';
                              }}
                              onMouseOut={(e) => {
                                e.target.style.background = '';
                              }}*/
                      >
                        <Link to={'/cars/buy-contract/?car_id='+car.car_id}
                              target='_blank'

                        >
                          <i className="icon-paper-plane">
                            &nbsp;สัญญาซื้อรถเข้า</i>
                        </Link>
                      </button>
                    ) : null
                  }

                  {
                    checkGroupPermission(23, this.props.grouppermissions).created ? (
                      <button onClick={() => this.handleOnClick('car-repair', car)}
                              type="button"
                              className="btn btn-danger btn-sm btn-block">
                        <i className="icon-wrench">
                          &nbsp;  ปรับแต่ง/ซ่อม
                        </i>
                      </button>
                    ) : null
                  }


                  {
                    checkGroupPermission(101, this.props.grouppermissions).viewed ? (
                      <button
                        onClick={() => this.handleOnClick('car-edit-status', car)}
                        type="button"
                        className={this.props.user_type === 'technician' ? 'hidden' : 'btn btn-warning btn-sm btn-block'}
                      >
                        <i className="icon-note">
                          &nbsp;แก้ไขสถานะ
                        </i>
                      </button>
                    ) : null
                  }

                  {
                    checkGroupPermission(102, this.props.grouppermissions).modified ? (
                      <button
                        onClick={() => this.handleOnClick('car-edit-sell-price', car)}
                        type="button"
                        className='btn btn-danger btn-sm btn-block'
                      >
                        <i className="icon-note">
                          &nbsp;แก้ไขราคาขาย
                        </i>
                      </button>
                    ): null
                  }

                  {
                    checkGroupPermission(103, this.props.grouppermissions).viewed ? (
                      <button onClick={() => this.handleOnClick('car-book', car)} type="button"
                              className={this.props.user_type === 'technician' ? 'hidden' : 'btn btn-info btn-sm btn-block'}
                      >
                        <i className="icon-star">
                          &nbsp;จองรถคันนี้</i>
                      </button>
                    ) : null
                  }

                  {
                    checkGroupPermission(105, this.props.grouppermissions).viewed ?
                        (
                      <button onClick={() => this.handleOnClick('car-finance', car)} type="button"
                              className={this.props.user_type === 'technician' ? 'hidden' : 'btn btn-primary btn-sm btn-block'}
                      >
                        <i className="icon-layers">
                          &nbsp;จัดไฟแนนซ์รถคันนี้</i>
                      </button>

                    ) : null
                  }

                  {
                    checkGroupPermission(104, this.props.grouppermissions).viewed ? (
                    <button onClick={() => this.handleOnClick('car-buy', car)} type="button"
                            className={this.props.user_type === 'technician' ? 'hidden' : 'btn btn-success btn-sm btn-block'}
                    ><i className="icon-trophy">
                      &nbsp;ขายรถคันนี้</i>
                    </button>
                    ) : null
                  }

                  {
                    checkGroupPermission(1, this.props.grouppermissions).deleted ? (
                      <button onClick={() => this.handleOnClick('car-delete', car)} type="button"
                              className={'btn btn-danger btn-sm btn-block'}

                      ><i className="icon-trash">
                        &nbsp;ลบรถคันนี้</i>
                      </button>
                    ) : null
                  }

                </>
              )
            }





          </td>
        </tr>
      )
    })

    var tbEmpty = (
      <tr><td colSpan={6} className="text-danger text-center">ไม่พบข้อมูลรถ</td></tr>
    )

   var tbBody = this.state.cars.length===0 ? tbEmpty : tbodyList

    return (
      <tbody>
      {tbBody}
      </tbody>
    )
  }
}


/*const styles = {
   fadeOut: {
     color: '#FFFFFF',
     textAlign: 'center',
     webkitTransition: 'opacity 3s ease-in-out',
     opacity: 1
   }
}*/

export default CarRow;

