import React, { Component } from 'react';
import axios from 'axios';
import { APIURL, IMAGE_NOPHOTO_URL, IMAGE_UPLOAD_ERROR } from '../../config/config';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import docUtil from '../../utils/docUtil';
import moment from 'moment';
// import BranchListRow from '../Branch/BranchListRow'
// import BranchForm from '../Branch/BranchForm'
// import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
// import disableButtons from 'module:sweetalert2.Swal.disableButtons'

class CompanySetting extends Component{

  constructor (props) {
    super(props)
    this.state = {
      user: {},
      company_setting: {},
      company_id: '',
      company_type: '',
      company_name: '',
      company_address: '',
      company_tel: '',
      juristic_number: '',
      line_token: '',
      is_vat: 0,
      vat: 0,
      package: '',
      due_price: '',
      due_expired: '',

      logo_image: IMAGE_NOPHOTO_URL,
      logo_key: '',
      background_image: IMAGE_NOPHOTO_URL,
      background_key: '',

      updated_at: '',
      user_created: '',
      user_updated: ''
    }

    this.loadData = this.loadData.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);

  }

  componentDidMount () {
    this.loadData()
  }

  componentWillMount() {
    this.setState({
      user: JSON.parse(this.props.user),
    })
  }

  loadData () {
    axios.get(APIURL + '/company_setting/1')
      .then(res => {
          // console.log(res)
          let data = res.data
          // console.log(data)
          let company_setting = data.company_setting
          if (company_setting === null) {
            this.setState({
              company_id: 0,
              logo_image: IMAGE_NOPHOTO_URL,
              background_image: IMAGE_NOPHOTO_URL,
            })
          } else {
            let due_expired = moment(company_setting.due_expired).locale('th').format('LL')
            // console.log('company_setting.is_vat=', company_setting.is_vat)
            // console.log(company_setting);
            this.setState({
              company_id: company_setting.company_id,
              company_type: company_setting.company_type,
              company_name: company_setting.company_name,
              company_address: company_setting.company_address,
              company_tel: company_setting.company_tel,
              logo_image: company_setting.logo_image === null || company_setting.logo_image === '' ? IMAGE_NOPHOTO_URL : company_setting.logo_image,
              logo_key: company_setting.logo_key === null ? '' : company_setting.logo_key,
              background_image: company_setting.background_image === null || company_setting.background_image === '' ? IMAGE_NOPHOTO_URL : company_setting.background_image,
              background_key: company_setting.background_key === null ? '' : company_setting.background_key,
              juristic_number: company_setting.juristic_number,
              package: company_setting.package,
              due_expired: due_expired,
              is_vat: parseInt(company_setting.is_vat, 10),
              line_token: company_setting.line_token === null || company_setting.line_token === '' ? '' : docUtil.replaceStar(company_setting.line_token)
            })
          }
        // this.setState({lists: res.data, load_data: false, branch_id: 0, danger: false})
      }).catch(error => {
        console.log('error:', error)
        // this.setState({lists: [], load_data: true, branch_id: 0, danger: false})
    })
  }

  onSubmit(e) {
    e.preventDefault()
    // console.log(e)
    let company_id = this.state.company_id

  }

  onChange(event) {
    //alert (event.target.name);
    let xname = event.target.name
    let value = event.target.value

    this.setState({
      [xname]: value
    })
  }

  handleUploadFile (event){
    const company_id = this.state.company_id
    const input_file_name = event.target.name
    // let slip_key = input_file_name === 'slip_image1' ? this.state.slip_key1 : this.state.slip_key2
    let name_key = ''
    let title = ''

    const that = this;

    if (input_file_name === 'logo_image'){
      title = 'โลโก้'
      name_key = this.state.logo_key === null ? '' : this.state.logo_key
    } else if (input_file_name === 'background_image') {
      title = 'พื้นหลัง'
      name_key = this.state.background_key === null ? '' : this.state.background_key
    }

    // input_file_name= slip_image2
    const data = new FormData();
    data.append('uploads', event.target.files[0], event.target.files[0].name)
    data.append('company_id', company_id)
    data.append('name_key', name_key)
    data.append('name_image', input_file_name)

    Swal.fire({
      title: 'ต้องการเพิ่มภาพ/เปลี่ยนภาพ '+title+' ใช่หรือไม่? ',
      icon: 'warning',
      showConfirmButton: true,
      showCancelButton: true,
    }).then((result) => {
      if (result.value) {

        if (input_file_name === 'logo_image'){
          this.refs.img_logo_image.src='img/loading.gif'
          this.refs.img_logo_image.height=32
        } else if (input_file_name === 'background_image') {
          this.refs.img_background_image.src='img/loading.gif'
          this.refs.img_background_image.height=32
        }

        axios.put(APIURL+'/company_setting/image/'+company_id, data).then(res => {
          const resData = res.data;
          const filename = resData.image;
          const image_key = resData.image_key;
          let image_url = ''

          if(resData.image === undefined) {
            image_url = IMAGE_UPLOAD_ERROR
          } else if (resData.image === 'uploaderror') {
            image_url = IMAGE_UPLOAD_ERROR
          } else {
            image_url = filename
          }

          if( input_file_name === 'logo_image'){
            that.setState({
              logo_image: image_url,
              logo_key: image_key
            });
            this.loadData()
          } else if (input_file_name === 'background_image') {
            that.setState({
              background_image: image_url,
              background_key: image_key
            });
            this.loadData()
          }
        });
      }
    })

  }

  render () {

    return (
      <div className="card">

        <div className="card-block">
          <div className="row">

            <div className="col-sm-8">

              <form onSubmit={this.onSubmit}>

               <div className="card card-accent-info" >
                <div className="card-header">
                  <strong className="text-title">ข้อมูลทั่วไป</strong>
                </div>
                <div className="card-block">

                  <div className="form-group">
                    <label htmlFor="branch_type">ประเภท </label>
                    <select
                      className="form-control"
                      name="company_type"
                      value={this.state.company_type}
                      onChange={this.onChange}
                    >
                      <option value='นิติบุคคล'>นิติบุคคล</option>
                      <option value='บุคคลธรรมดา'>บุคคลธรรมดา</option>
                    </select>
                  </div>


                  <div className="form-group">
                    <label htmlFor="is_vat">จดภาษีมูลค่าเพิ่ม (VAT) </label>
                    <br/>
                    <label className="radio-inline ml-3" htmlFor="is_vat1">
                      <input type="radio" id="is_vat1" name="is_vat" value="0"
                             checked={this.state.is_vat === 0}
                             onChange={(e) => {
                               let val = e.target.value
                               this.setState({
                                 is_vat: parseInt(val)
                               })
                             }}
                      /> ไม่จดภาษีมูลค่าเพิ่ม
                    </label>
                    <label className="radio-inline ml-4"  htmlFor="is_vat2">
                      <input type="radio" id="is_vat2" name="is_vat" value="1"
                             checked={this.state.is_vat === 1}
                             onChange={(e) => {
                               let val = e.target.value
                               this.setState({
                                 is_vat: parseInt(val)
                               })
                             }}
                      /> จดภาษีมูลค่าเพิ่ม
                    </label>

                  </div>

                  <div className="form-group">
                    <label htmlFor="company_name">ชื่อบริษัท/บุคคล </label>
                    <input name="company_name"
                           value={this.state.company_name}
                           type="text" className="form-control"
                           placeholder="กรอกชื่อบริษัท/บุคคล"
                           onChange={this.onChange}
                           maxLength={150}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="company_address">ชื่ออยู่ </label>
                    <input name="company_address"
                           value={this.state.company_address}
                           type="text" className="form-control"
                           placeholder="กรอกที่อยู่"
                           onChange={this.onChange}
                           maxLength={150}
                    />

                  </div>

                  <div className="form-group">
                    <label htmlFor="juristic_number">เลขประจําตัวผู้เสียภําษี</label>
                    <input name="juristic_number"
                           value={this.state.juristic_number}
                           type="text" className="form-control"
                           placeholder="กรอกเลขประจําตัวผู้เสียภําษี"
                           onChange={this.onChange}
                           maxLength={13}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="line_token">แจ้งเตือนทางไลน์(Line Notify Token) &nbsp;
                      <a href="https://www.evernote.com/shard/s418/sh/ee94aa68-c29f-481e-860d-693093d17725/70910406dc3e9287d3e6967b331fe73d"
                       target="_blank"
                      >
                        การตั้งค่า
                      </a>
                    </label>
                    <input name="line_token"
                           value={this.state.line_token}
                           type="text" className="form-control"
                           placeholder="Line Notify Token"
                           onChange={this.onChange}
                           maxLength={150}
                    />
                  </div>


                  <div className="form-group">
                    <label htmlFor="line_token">แพ็คเกจ</label>
                    <input name="line_token"
                           value={this.state.package}
                           type="text" className="form-control"
                           placeholder="Line Notify Token"
                           onChange={(e) => {

                           }}
                           maxLength={150}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="line_token">วันหมดอายุ</label>
                    <input name="line_token"
                           value={this.state.due_expired}
                           type="text" className="form-control"
                           placeholder="Line Notify Token"
                           onChange={(e) => {

                           }}
                           maxLength={150}
                    />
                  </div>

                </div>

                <div className="card-footer text-right">

                  {/*<button type="submit"
                          className={"btn btn-sm btn-primary"}
                          style={styles.button}
                  >
                    <i className={"fa fa-save"}/> บันทึก
                  </button>*/}

                </div>

              </div>

              </form>

            </div>


            <div className="col-sm-4">
              <div className="card card-accent-success">
                <div className="card-header card-customer">
                  <strong className="text-title">ตั้งค่ารูปภาพ</strong>
                </div>


                  <div className="card-block">

                    <div className="form-group">
                      <label htmlFor="logo_image">โลโก้ </label>

                      <div style={styles.fileInputWrapper} >

                        <button style={styles.btnFileInput}>
                          <img src={this.state.logo_image}
                               style={styles.btnFileInputImage}
                               ref="img_logo_image"
                               alt="โลโก้"
                          />
                        </button>
                        <input
                          style={styles.fileInput}
                          type="file"
                          name="logo_image"
                          onChange={this.handleUploadFile}
                        />
                      </div>
                    </div>


                    <div className="form-group">
                      <label htmlFor="background_image">พื้นหลัง </label>

                      <div style={styles.fileInputWrapper} >

                        <button style={styles.btnFileInput}>
                          <img src={this.state.background_image}
                               style={styles.btnFileInputImage}
                               ref="img_background_image"
                               alt="พื้นหลัง"
                          />
                        </button>
                        <input
                          style={styles.fileInput}
                          type="file"
                          name="background_image"
                          onChange={this.handleUploadFile}
                        />
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="background_image">***กดที่ภาพเพื่อเปลี่ยนภาพใหม่ </label>
                    </div>

                  </div>
              </div>
            </div>
          </div>


        </div>

      </div>)
  }

}


const styles = {
  bottomLine: {
    borderBottomColor: '#ccc',
    borderBottomStyle: 'solid',
    borderWidth: 0.5,
  },
  fileInputWrapper: {
    position: 'relative',
    overflow: 'hidden',
    height: 180,
    width: 200,
  },
  btnFileInput: {
    height: 180,
    width: 200,
    display: 'inline-block',
    backgroundColor: "#D2DDE2",
    backgroundImage: "linear-gradient(to bottom, #D2DDE2, #FCF8FF)",
    backgroundRepeat: "repeat-x",
    color: "#ffffff",
    textShadow: "0 -1px 0 rgba(0, 0, 0, 0.25)"
  },
  btnFileInputImage:{
    position: 'absolute',
    top: 0,
    left: 0,
    height: 180,
    width: 200,
  },
  fileInput: {
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: 0,
    height: 180,
    width: 200,
    cursor: 'pointer'
  },
  button: {
    borderRadius:6,
    height: 36,
  },
  inputSearch: {
    borderRadius:5,
  },
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  token: state.auth.token,
  isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(CompanySetting)
