import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as moment from 'moment';
import 'moment/locale/th';
import NumberFormat from 'react-number-format';
import { IMAGE_FULL_URL, IMAGE_NOPHOTO_URL } from '../../../config/config';
import PreviewImage from '../../../views/PreviewImage/';
import {  Modal, ModalBody } from 'reactstrap';

class ReceiptsListRow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            preview_image_url: '',
            preview_image_select: false
        }
        this.togglePreviewImage = this.togglePreviewImage.bind(this)
    }

    onPreviewSelected(url){
        this.setState({
            preview_image_url: url,
            preview_image_select: true
        });
    }

    togglePreviewImage() {
        this.setState({
            preview_image_select: !this.state.preview_image_select
        });
    }

    render(){
        let receiptsList = this.props.receipts_list.map((receipts,index)=>{
            return (
                <tr key={index}>
                    <td className="text-center">{index+1}</td>
                    <td className="text-center">{moment(receipts.receipts_date).locale('th').format('LL')}</td>
                    <td className="text-center">{receipts.branch_name}</td>
                    <td className="text-center">{receipts.receipts_name}</td>
                    <td className="text-center">
                        <img
                          src={receipts.receipts_image_file==='nophoto.png'? IMAGE_NOPHOTO_URL : IMAGE_FULL_URL + receipts.receipts_image_file}
                            style={{'cursor' : 'pointer', 'width': '120px', 'height': 'auto'}}
                            alt="เอกสาร"
                            onClick={()=>this.onPreviewSelected(IMAGE_FULL_URL + receipts.receipts_image_file)}
                        />
                    </td>
                    <td className="text-center">
                        <NumberFormat value={receipts.receipts_amount}
                                      displayType={'text'}
                                      thousandSeparator={true}
                                      prefix={'฿'}/>
                    </td>
                    <td className="text-center">{receipts.fullname}
                        <br/>
                        {moment(receipts.updated_at).locale('th').format('DD-MM-YYYY HH:mm')}
                    </td>
                    <td className="text-center">
                       <button
                         className="btn btn-sm btn-danger"
                         onClick={()=>this.props.onDeleteHandle(receipts)}
                       >
                         <i className="fa fa-remove" /> ลบ
                       </button>
                    </td>
                </tr>
            )
        });

        return (
            <div className="card-block">
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th className="text-center">#</th>
                        <th className="text-center">วันที่รับเงิน</th>
                        <th className="text-center">สาขา</th>
                        <th className="text-center">รายละเอียด</th>
                        <th className="text-center">ไฟล์เอกสาร</th>
                        <th className="text-center">ยอดรายรับ</th>
                        <th className="text-center">ผู้บันทึก</th>
                        <th className="text-center">ลบ</th>
                    </tr>
                    </thead>
                    <tbody>
                    {receiptsList}
                    </tbody>
                </table>

                <Modal isOpen={this.state.preview_image_select} toggle={this.togglePreviewImage} className={'modal-lg '}>
                    <ModalBody>
                        <PreviewImage
                            imageUrl={this.state.preview_image_url}
                        />
                    </ModalBody>
                </Modal>
                {/*<ul className="pagination">
                    <li className="page-item"><a className="page-link" href="#">Prev</a></li>
                    <li className="page-item active">
                        <a className="page-link" href="#">1</a>
                    </li>
                    <li className="page-item"><a className="page-link" href="#">2</a></li>
                    <li className="page-item"><a className="page-link" href="#">3</a></li>
                    <li className="page-item"><a className="page-link" href="#">4</a></li>
                    <li className="page-item"><a className="page-link" href="#">Next</a></li>
                </ul>*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(ReceiptsListRow)
