import React, {Component} from 'react'
import {Button} from 'reactstrap'
import moment from 'moment'
import DatePicker from 'react-datepicker'
import axios from 'axios'
import NumberFormat from 'react-number-format';
import { APIURL } from '../../config/config'

class SummaryStockOut extends Component {
  constructor (props){
    super(props)
    this.state = {
      date_start: new Date(),
      date_end: new Date(),
      stockOut: []
    }

    this.handleDateStart = this.handleDateStart.bind(this)
    this.handleDateEnd   = this.handleDateEnd.bind(this)
    this.loadSummaryStockOut   = this.loadSummaryStockOut.bind(this)
  }

  componentDidMount() {

    //if(this.props.viewType==='daily') {
      this.loadSummaryStockOut();
    //}

  }
  //
  componentWillReceiveProps(nextProps){
    if(nextProps.reloadSummaryStockOut !== this.props.reloadSummaryStockOut){
      if(nextProps.reloadSummaryStockOut){
        this.loadSummaryStockOut();
      }
    }
  }


  handleDateStart(date){
    this.setState({
      date_start: date
    })
  }

  handleDateEnd(date){
    this.setState({
      date_end: date
    })
  }

  loadSummaryStockOut() {

    if(this.state.date_start > this.state.date_end){
      alert('วันที่เริ่มต้น มากกว่า วันที่สิ้นสุด กรุณาตรวจสอบ')
      return
    }

    let date_start =  moment(this.state.date_start).format('YYYY-MM-DD')
    let date_end   =  moment(this.state.date_end).format('YYYY-MM-DD')
    let url = APIURL+'/stock_out/report/'+date_start+'/'+date_end
    axios.get(url).then(res => {
      if(res.status === 200){
        this.setState({
          stockOut: res.data
        },()=>{
          if(this.props.reloadSummaryStockOut){
            this.props.onReloadSummaryStockOutFinish();
          }
        });
      }
    })

  }

  render() {


    let stockOutList = this.state.stockOut.map((sparePart, index) => {

      if(sparePart.quantity_out === 0) return null

      return (
        <tr key={index+1}>
          <td>{index+1}</td>
          <td>{sparePart.name}</td>
          <td className="text-center">
            <NumberFormat
              displayType={'text'}
              thousandSeparator={true}
              value={sparePart.price}/>
          </td>
          <td className="text-center">{sparePart.quantity_out}</td>
          <td className="text-center">{sparePart.unit}</td>
        </tr>
      )

    })

    return (
      <div className="row">
        <div className="col-lg-12">
          <div className="card card-accent-warning">
            <div className="card-header card-sparepart">
              <strong className="text-title">สรุปการเบิกอะไหล่</strong>
            </div>
            <div className="card-block">
              <div className={this.props.viewType==='daily' ? 'row  mb-2' : 'hidden'}>
                <div className="col-12">
                  <h4> สรุปการเบิกอะไหล่วันที่ {moment().locale('th').format('LL')} </h4>
                </div>
              </div>
              <div className={this.props.viewType==='range' ? 'row  mb-4' : 'hidden'}>
                  <div className="col-2 text-right pt-1">
                     วันที่เริ่ม
                  </div>
                  <div className="col-2">
                    <DatePicker
                      selected={this.state.date_start}
                      onChange={this.handleDateStart}
                      dateFormat="yyyy-MM-dd"
                      type="text"
                      className="form-control"
                      placeholder=""
                    />
                  </div>

                  <div className="col-2 text-right pt-1">
                    <span className="" > วันที่สิ้นสุด </span>
                  </div>
                  <div className="col-2">
                      <DatePicker
                        selected={this.state.date_end}
                        onChange={this.handleDateEnd}
                        dateFormat="yyyy-MM-dd"
                        type="text"
                        className="form-control"
                        placeholder=""
                      />
                  </div>
                <div className="col-1 mb-2">
                  <Button color="success" size="sm" onClick={()=>this.loadSummaryStockOut()}>
                    <i className="icon-magnifier"/>&nbsp; ตกลง
                  </Button>
                </div>
              </div>

              <div className={this.props.viewType==='range' ? 'row  mb-2' : 'hidden'}>
                <div className={moment(this.state.date_start).isSame(moment(this.state.date_end), 'date')? "hidden": 'col-12' }>
                  <h4> สรุปการเบิกอะไหล่วันที่ {moment(this.state.date_start).locale('th').format('LL')}  ถึง {moment(this.state.date_end).locale('th').format('LL')}</h4>
                </div>
                <div className={moment(this.state.date_start).isSame(moment(this.state.date_end), 'date')? "col-12": 'hidden' }>
                  <h4> สรุปการเบิกอะไหล่วันที่ {moment(this.state.date_start).locale('th').format('LL')} </h4>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <table className="table table-striped">
                    <thead>
                    <tr>
                      <td>#</td>
                      <td>ชื่ออะไหล่รถ</td>
                      <td className="text-center">ราคา</td>
                      <td className="text-center">จำนวนเบิก</td>
                      <td className="text-center">หน่วย</td>
                    </tr>
                    </thead>
                    <tbody>
                    {stockOutList}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default SummaryStockOut;