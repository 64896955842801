import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { Route, Switch, HashRouter } from 'react-router-dom' //HashRouter
import { persistStore} from 'redux-persist'
// import { ConnectedRouter } from 'connected-react-router'
import configureStore, { history } from './store/configure'
import axios from 'axios'
import Full from './containers/Full/'
import Login from './components/Login/'
import Page404 from './views/Pages/Page404/'
import Page500 from './views/Pages/Page500/'
import {loginUserSuccess} from './actions';

const store = configureStore(window.__INITIAL_STATE__);
// const history = createBrowserHistory()

let token = localStorage.getItem('token');
let user = localStorage.getItem('user');
let logo_image = localStorage.getItem('logo_image');
let type = localStorage.getItem('type');


if (token !== null) {
   axios.defaults.headers.common['Authorization'] = token;
   store.dispatch(loginUserSuccess(token,user, logo_image, type));
}

persistStore(store);


ReactDOM.render((
    <HashRouter history={history}>
     <Provider store={store}>
        <Switch history={history}>
        <Route exact path="/login" name="Login Page" component={Login}/>
          <Route exact path="/404" name="Page 404" component={Page404}/>
          <Route exact path="/500" name="Page 500" component={Page500}/>
          {/*<Route exact path="/car" name="Car" component={PageCar}/>*/}
          <Route path="/" name="Full" component={Full}/>
        </Switch>
      </Provider>
    </HashRouter>
), document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
